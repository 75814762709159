/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type AcceptQuoteForRefundRequest = {
  /** Can be yes or no */
  accept_quote: Scalars['String'];
  booking_reference: Scalars['String'];
  comment: Scalars['String'];
  passengers: Array<RefundPassenger>;
  ptr_id: Scalars['Int'];
};

/** Account level on Shoppt. */
export type AccountLevel = {
  __typename?: 'AccountLevel';
  /** The attached badge */
  badge?: Maybe<Badge>;
  /** When the account level was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The min point */
  min_points: Scalars['Float'];
  /** Account level nick name */
  nick_name: Scalars['String'];
  /** The level offer */
  offer: Scalars['String'];
  /** Account level title */
  title: Scalars['String'];
  /** When the account level was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export enum AccountType {
  Business = 'BUSINESS',
  Client = 'CLIENT'
}

export type AdCtaRules = {
  __typename?: 'AdCTARules';
  cta?: Maybe<Scalars['String']>;
  landing_page_url?: Maybe<Scalars['String']>;
  polls?: Maybe<AdPolls>;
};

export type AdCtaRulesInput = {
  cta?: InputMaybe<Scalars['String']>;
  landing_page_url?: InputMaybe<Scalars['String']>;
  polls?: InputMaybe<AdPollsInput>;
};

/** Ad Demography */
export type AdDemography = {
  __typename?: 'AdDemography';
  /** Unique primary key. */
  id?: Maybe<Scalars['Int']>;
  /** The title of the ad demography */
  title: Scalars['String'];
  /** Unique UUID */
  uuid?: Maybe<Scalars['String']>;
};

/** Ad Insight */
export type AdInsight = {
  __typename?: 'AdInsight';
  /** Ad performance metrics */
  ad: AdPerformance;
  /** Engagement metrics */
  engagements: EngagementMetrics;
  /** Last updated date */
  last_updated: Scalars['String'];
  /** Reach metrics */
  reach: ReachMetrics;
};

export type AdMediaMetadata = {
  __typename?: 'AdMediaMetadata';
  image_url?: Maybe<Scalars['String']>;
  media_type?: Maybe<Scalars['String']>;
  shoplist_id?: Maybe<Scalars['String']>;
  shoppable_type?: Maybe<Scalars['String']>;
  video_base_url?: Maybe<Scalars['String']>;
};

export type AdMediaMetadataInput = {
  media_type?: InputMaybe<Scalars['String']>;
  shoplist_id?: InputMaybe<Scalars['String']>;
  shoppable_type?: InputMaybe<Scalars['String']>;
};

/** Ad Performance */
export type AdPerformance = {
  __typename?: 'AdPerformance';
  /** Total amount spent on the ad */
  amount_spent: Scalars['Float'];
  /** Audience metrics */
  audience: AudienceMetrics;
};

export type AdPollOption = {
  __typename?: 'AdPollOption';
  image_url?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  value: Scalars['String'];
};

export type AdPollOptionInput = {
  image?: InputMaybe<Scalars['Upload']>;
  slug: Scalars['String'];
  value: Scalars['String'];
};

export type AdPolls = {
  __typename?: 'AdPolls';
  answer_type: Scalars['String'];
  option_type: Scalars['String'];
  options: Array<AdPollOption>;
  question: Scalars['String'];
};

export type AdPollsInput = {
  answer_type: Scalars['String'];
  option_type: Scalars['String'];
  options: Array<AdPollOptionInput>;
  question: Scalars['String'];
};

export type AdTargetAudienceRule = {
  __typename?: 'AdTargetAudienceRule';
  age_ranges: Array<Scalars['Int']>;
  demographies: Array<Scalars['Int']>;
  gender: Array<Scalars['String']>;
  locations: Array<Scalars['Int']>;
};

export type AdTargetAudienceRuleInput = {
  age_ranges: Array<Scalars['Int']>;
  demographies: Array<Scalars['Int']>;
  gender: Array<Scalars['String']>;
  locations: Array<Scalars['Int']>;
};

/** Advert on Shoppt. */
export type Advert = {
  __typename?: 'Advert';
  /** The advert insights */
  ad_insights: AdInsight;
  /** The advert set ID */
  adset?: Maybe<AdvertSet>;
  /** The advert media */
  advert_media?: Maybe<AdvertMedia>;
  /** The advert media ID */
  advert_media_id?: Maybe<Scalars['Int']>;
  /** When the advert was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The media */
  media?: Maybe<AdvertMedia>;
  /** The advert performance */
  performance?: Maybe<EntityPerformance>;
  /** The poll summary */
  poll_summary?: Maybe<Array<PollSummary>>;
  /** The advert status */
  status?: Maybe<Scalars['String']>;
  /** The title of the advert */
  title?: Maybe<Scalars['String']>;
  /** The advert type */
  type?: Maybe<Scalars['String']>;
  /** When the advert was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user */
  user?: Maybe<User>;
  /** The advert user ID */
  user_id?: Maybe<Scalars['Int']>;
  /** The user poll vote */
  user_poll_vote?: Maybe<AdvertPoll>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Advert media */
export type AdvertMedia = {
  __typename?: 'AdvertMedia';
  /** When the advert media was created. */
  created_at: Scalars['DateTime'];
  /** The cta_rules */
  cta_rules: AdCtaRules;
  /** Unique primary key. */
  id: Scalars['Int'];
  /** The media */
  media?: Maybe<MediaContent>;
  /** The metadata */
  metadata: AdMediaMetadata;
  /** The attached product */
  product?: Maybe<ShoplistProduct>;
  /** The attached shoplist */
  shoplist?: Maybe<Collection>;
  /** The Source URL */
  source_url: Scalars['String'];
  /** When the advert media was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of Advert items. */
export type AdvertPaginator = {
  __typename?: 'AdvertPaginator';
  /** A list of Advert items. */
  data: Array<Advert>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** New poll vote */
export type AdvertPoll = {
  __typename?: 'AdvertPoll';
  /** The advert uuid */
  advert_uuid: Scalars['String'];
  /** The answer */
  answer: Scalars['String'];
  /** When the poll vote was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['Int'];
  /** When the poll vote was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user id */
  user_id: Scalars['Int'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Advert set */
export type AdvertSet = {
  __typename?: 'AdvertSet';
  /** The ad set budget */
  ad_budget: Scalars['Float'];
  /** The ad end time */
  ad_daytime_end: Scalars['String'];
  /** The ad start time */
  ad_daytime_start: Scalars['String'];
  /** The ad end date */
  ad_end_date: Scalars['String'];
  /** The ad start date */
  ad_start_date: Scalars['String'];
  /** The ad bidding strategy */
  bidding_strategy: Scalars['String'];
  /** When the advert set was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['Int'];
  /** The ad maximum cost */
  maximum_cost: Scalars['Float'];
  /** The add running type */
  run_until_paused: Scalars['Boolean'];
  /** The ad status */
  status: Scalars['String'];
  /** The target audience rules */
  target_audience_rules: AdTargetAudienceRule;
  /** The title */
  title: Scalars['String'];
  /** When the advert set was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Age Range Distribution */
export type AgeRangeDistribution = {
  __typename?: 'AgeRangeDistribution';
  /** Count of users in this age range */
  count: Scalars['Int'];
  /** Age range title */
  title: Scalars['String'];
};

export type AirItineraryPricingInfo = {
  __typename?: 'AirItineraryPricingInfo';
  DivideInPartyIndicator: Scalars['Boolean'];
  FareSourceCode: Scalars['String'];
  FareType: Scalars['String'];
  IsRefundable: Scalars['String'];
  ItinTotalFare: ItinTotalFare;
  PTC_FareBreakdowns: Array<SinglePtcFareBreakdown>;
};

export type AirVoidCharges = {
  __typename?: 'AirVoidCharges';
  AdminCharges: Scalars['Float'];
  Currency: Scalars['String'];
  IsVoidable: Scalars['String'];
  PassengerType: Scalars['String'];
  VoidingFee: Scalars['Int'];
  VoidingWindow?: Maybe<Scalars['String']>;
};

/** SingleAirline */
export type Airline = {
  __typename?: 'Airline';
  IATACode: Scalars['String'];
  Logo: Scalars['String'];
  LogoFull: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
};

/** Airports */
export type Airports = {
  __typename?: 'Airports';
  /** The city_name */
  city_name: Scalars['String'];
  /** iata_city_code */
  iata_city_code?: Maybe<Scalars['String']>;
  /** iata_code */
  iata_code: Scalars['String'];
  /** iata_country_code */
  iata_country_code?: Maybe<Scalars['String']>;
  /** icao_code */
  icao_code?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** latitude */
  latitude?: Maybe<Scalars['String']>;
  /** longitude */
  longitude?: Maybe<Scalars['String']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** time_zone */
  time_zone?: Maybe<Scalars['String']>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Shpt app contents */
export type AppContent = {
  __typename?: 'AppContent';
  /** The app type. Can be client, business, admin */
  app_type: Scalars['String'];
  /** The app version */
  app_version: Scalars['String'];
  /** The faqs page content */
  faqs_page?: Maybe<Scalars['String']>;
  /** The home page content */
  home_page?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The privacy policy */
  privacy_policy?: Maybe<Scalars['String']>;
  /** The snapping terms */
  snapping_terms?: Maybe<Scalars['String']>;
  /** The terms of service */
  terms_and_condition?: Maybe<Scalars['String']>;
  /** The usage policy */
  usage_policy?: Maybe<Scalars['String']>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** AppliedRefundResponse */
export type AppliedRefundResponse = {
  __typename?: 'AppliedRefundResponse';
  BookingStatus: Scalars['String'];
  CreatedBy: Scalars['String'];
  CreditNoteNumber: Scalars['String'];
  CreditNoteStatus: Scalars['String'];
  Currency: Scalars['String'];
  MFRef: Scalars['String'];
  PTRId: Scalars['Int'];
  PTRStatus: Scalars['String'];
  PTRType: Scalars['String'];
  ProcessingMethod: Scalars['String'];
  Resolution: Scalars['String'];
  TotalRefundAmount: Scalars['String'];
  pTRPaxDetails: Array<EachPtrPaxDetails>;
};

/** Audience Metrics */
export type AudienceMetrics = {
  __typename?: 'AudienceMetrics';
  /** Age range distribution */
  age_ranges: Array<AgeRangeDistribution>;
  /** Gender distribution */
  gender: GenderDistribution;
  /** Location distribution */
  locations: Array<LocationDistribution>;
};

/** App auth response interface */
export type AuthResponse = {
  __typename?: 'AuthResponse';
  /** The JWT token */
  token: Scalars['String'];
  /** The authenticated user */
  user: User;
};

export type AutoInsuranceRequest = {
  chassis_number: Scalars['String'];
  engine_number: Scalars['String'];
  product_id: Scalars['String'];
  registration_number: Scalars['String'];
  vehicle_category: Scalars['String'];
  vehicle_color: Scalars['String'];
  vehicle_cost: Scalars['String'];
  vehicle_make: Scalars['String'];
  vehicle_model: Scalars['String'];
  year_of_manufacture: Scalars['String'];
};

/** Badge on Shoppt. */
export type Badge = {
  __typename?: 'Badge';
  /** When the badge was created. */
  created_at: Scalars['DateTime'];
  /** Badge description */
  description?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Badge image url */
  photo_url: Scalars['String'];
  /** Badge title */
  title: Scalars['String'];
  /** When the badge was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Save bank metadata */
export type BankMetaData = {
  __typename?: 'BankMetaData';
  /** Account full name */
  accountName?: Maybe<Scalars['String']>;
  /** Account first name */
  firstName?: Maybe<Scalars['String']>;
  /** Account last name */
  lastName?: Maybe<Scalars['String']>;
  /** Account name */
  name?: Maybe<Scalars['String']>;
};

/** Payment saved beneficiaries */
export type Beneficiary = {
  __typename?: 'Beneficiary';
  /** When the beneficiary was created. */
  created_at: Scalars['DateTime'];
  /** Unique customer code */
  customer_code: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The beneficiary metadata */
  metadata: Scalars['String'];
  /** Product code */
  product_code: Scalars['String'];
  /** When the beneficiary was last updated. */
  updated_at: Scalars['DateTime'];
  /** The owner of the beneficiary */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type BeneficiaryMetadata = {
  __typename?: 'BeneficiaryMetadata';
  amount?: Maybe<Scalars['String']>;
  beneficiary_account_no?: Maybe<Scalars['String']>;
  beneficiary_bank_code?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  beneficiary_type?: Maybe<Scalars['String']>;
  narration?: Maybe<Scalars['String']>;
  shoppoint_user_uuid?: Maybe<Scalars['String']>;
  shoppoint_username?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type BookFlightRequest = {
  dailing_country_code: Scalars['String'];
  data: Scalars['String'];
  email: Scalars['String'];
  fare_source_code: Scalars['String'];
  passengers: Array<SingleFlightPassenger>;
  phone_area_code: Scalars['String'];
  phone_number: Scalars['String'];
  post_code?: InputMaybe<Scalars['String']>;
};

/** BookedFlightResponse */
export type BookedFlightResponse = {
  __typename?: 'BookedFlightResponse';
  ClientUTCOffset: Scalars['Float'];
  ConversationId?: Maybe<Scalars['String']>;
  IsPriceChange: Scalars['String'];
  IsScheduleChange: Scalars['String'];
  Status: Scalars['String'];
  Success: Scalars['Boolean'];
  TktTimeLimit: Scalars['String'];
  UniqueID: Scalars['String'];
};

export type BookingNotes = {
  __typename?: 'BookingNotes';
  BookingRef?: Maybe<Scalars['Int']>;
  CreatedByName?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['String']>;
  NoteDetails?: Maybe<Scalars['String']>;
};

/** The business profile of a user on the application */
export type Business = {
  __typename?: 'Business';
  /** Business account type */
  account_type?: Maybe<Scalars['String']>;
  /** Business address */
  address?: Maybe<Scalars['String']>;
  /** Business category */
  business_category?: Maybe<Scalars['String']>;
  /** Business name */
  business_name?: Maybe<Scalars['String']>;
  /** Social links */
  business_social_links?: Maybe<SocialLinksData>;
  /** The business tag */
  business_tag?: Maybe<Scalars['String']>;
  /** The business user id */
  business_user_id: Scalars['Int'];
  /** Connected accounts */
  connected_accounts?: Maybe<Array<Scalars['String']>>;
  /** When the business profile was created. */
  created_at: Scalars['DateTime'];
  /** About the business */
  description?: Maybe<Scalars['String']>;
  /** All verification document uploaded by the business */
  document?: Maybe<Array<Scalars['String']>>;
  /** Business follower count */
  followers: Scalars['Int'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Is brand */
  is_brand: Scalars['Boolean'];
  /** Business following status */
  is_following: Scalars['Boolean'];
  /** Manager info */
  manager_info?: Maybe<ManagerInfoData>;
  /** Business phone number */
  phone_number?: Maybe<Scalars['String']>;
  /** Business logo */
  photo_url?: Maybe<Scalars['String']>;
  /** The profile of the user */
  profile: Profile;
  /** When the business profile was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
  /** Business verification stage. Can be document_upload, transaction_pin_setup, completed */
  verification_stage?: Maybe<Scalars['String']>;
  /** Business verification status. Can be in_progress, declined, completed */
  verification_status: Scalars['String'];
};

/** Business Admin */
export type BusinessAdmin = {
  __typename?: 'BusinessAdmin';
  /** Attached business */
  business: Business;
  /** When the business admin was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The invitation_accepted_at */
  invitation_accepted_at?: Maybe<Scalars['String']>;
  /** permissions */
  permissions: StorePermissions;
  /** The status */
  status: Scalars['String'];
  /** When the business admin was last updated. */
  updated_at: Scalars['DateTime'];
  /** Attached user */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of BusinessAdmin items. */
export type BusinessAdminPaginator = {
  __typename?: 'BusinessAdminPaginator';
  /** A list of BusinessAdmin items. */
  data: Array<BusinessAdmin>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Business Dashboard Summary */
export type BusinessDashboardSummary = {
  __typename?: 'BusinessDashboardSummary';
  /** Engagement change from last week */
  engagement_change_from_last_week: ChangeFromLastWeek;
  /** Impression change from last week */
  impression_change_from_last_week: ChangeFromLastWeek;
  /** This week earnings */
  this_week_earnings: Scalars['Float'];
  /** Total earnings */
  total_earnings: Scalars['Float'];
  /** Total engagements */
  total_engagements: Scalars['Int'];
  /** Total impressions */
  total_impressions: Scalars['Int'];
  /** Total pending orders */
  total_pending_orders: Scalars['Int'];
  /** Total products */
  total_products: Scalars['Int'];
  /** Total shoplists */
  total_shoplists: Scalars['Int'];
};

/** A paginated list of Business items. */
export type BusinessPaginator = {
  __typename?: 'BusinessPaginator';
  /** A list of Business items. */
  data: Array<Business>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Saved card metadata */
export type CardMetaData = {
  __typename?: 'CardMetaData';
  /** The name of the bank that issued the card */
  bank?: Maybe<Scalars['String']>;
  /** The card brand. Can be visa, mastercard e.t.c (all card supported by paystack) */
  brand?: Maybe<Scalars['String']>;
  /** The type of card. Can be visa, mastercard e.t.c (all card supported by paystack) */
  card_type?: Maybe<Scalars['String']>;
  /** The card country code. E.g NG */
  country_code?: Maybe<Scalars['String']>;
  /** The month the card will expire */
  exp_month?: Maybe<Scalars['String']>;
  /** The year the card will expire */
  exp_year?: Maybe<Scalars['String']>;
  /** First six digit of the card */
  first_6digits?: Maybe<Scalars['String']>;
  /** The last 4 digit of the card */
  last4?: Maybe<Scalars['String']>;
};

/** Cart item on Shoppt. */
export type CartItem = {
  __typename?: 'CartItem';
  /** When the cart item was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The product attached to the cart item */
  product: Product;
  /** The cart item status. Can be pending or purchased */
  status: Scalars['String'];
  /** When the cart item was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user attached to the cart item */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Cash point gain */
export type CashPointGain = {
  __typename?: 'CashPointGain';
  /** The point category */
  category?: Maybe<Scalars['String']>;
  /** The point extra ID */
  extra_id?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Point */
  points: Scalars['Float'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type CashPointGainEvent = {
  __typename?: 'CashPointGainEvent';
  /** Event slug  */
  category: Scalars['String'];
  /** Extra ID */
  extra_id?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Point */
  points: Scalars['Float'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Merchant Category on Shoppt. */
export type Category = {
  __typename?: 'Category';
  /** Category affiliate commission percentage */
  affiliate_commission_percentage?: Maybe<Scalars['String']>;
  /** When the collection was created. */
  created_at: Scalars['DateTime'];
  /** Category description */
  description: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Category merchant commission percentage */
  merchant_commission_percentage?: Maybe<Scalars['String']>;
  /** Category name */
  name: Scalars['String'];
  /** Category description */
  parent_category?: Maybe<Category>;
  /** Category parent id */
  parent_category_id?: Maybe<Scalars['String']>;
  /** Category reward commission percentage */
  reward_commission_percentage?: Maybe<Scalars['String']>;
  /** Category subscription commissions */
  subscription_commissions?: Maybe<Array<SubscriptionCommission>>;
  /** When the collection was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of Category items. */
export type CategoryPaginator = {
  __typename?: 'CategoryPaginator';
  /** A list of Category items. */
  data: Array<Category>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Challenges on Shoppt. */
export type Challenge = {
  __typename?: 'Challenge';
  /** The challenge active instance */
  active_instance?: Maybe<ChallengeInstance>;
  /** The attached badge */
  badge?: Maybe<Badge>;
  /** When the challenge was created. */
  created_at: Scalars['DateTime'];
  /** The challenge description */
  description?: Maybe<Scalars['String']>;
  /** The challenge frequency. Can be one-time, daily, weekly, monthly, yearly */
  frequency: Scalars['String'];
  /** The challenge game type. Can be transactional or word_twist */
  game_type: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The challenge photo url */
  photo_url?: Maybe<Scalars['String']>;
  /** The attached rule */
  rule?: Maybe<Rule>;
  /** The challenge subtitle */
  sub_title: Scalars['String'];
  /** The challenge target point, mainly for public ones */
  target_point: Scalars['String'];
  /** The challenge title */
  title: Scalars['String'];
  /** The challenge type. Can be public, private */
  type: Scalars['String'];
  /** When the challenge was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** An instance of a challenge */
export type ChallengeInstance = {
  __typename?: 'ChallengeInstance';
  /** The attached challenge */
  challenge: Challenge;
  /** When the challenge instance was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The auth user participant. It is null if the user is not participating */
  participant?: Maybe<ChallengeParticipant>;
  /** The instance status. Can be pending, in-progress, completed */
  status: Scalars['String'];
  /** The instance title */
  title: Scalars['String'];
  /** Twisted word for word twist challenge */
  twisted_word?: Maybe<Scalars['String']>;
  /** When the challenge instance was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A challenge participant on Shoppt. */
export type ChallengeParticipant = {
  __typename?: 'ChallengeParticipant';
  /** The attached challenge instance */
  challenge_instance: ChallengeInstance;
  /** When the challenge participant was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Point accumulated so far */
  points: Scalars['String'];
  /** The game twisted word */
  twisted_word?: Maybe<Scalars['String']>;
  /** When the challenge participant was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Change From Last Week */
export type ChangeFromLastWeek = {
  __typename?: 'ChangeFromLastWeek';
  /** Direction */
  direction: Scalars['String'];
  /** Percentage */
  percentage: Scalars['Float'];
};

/** Checkout data */
export type CheckoutData = {
  __typename?: 'CheckoutData';
  /** The package amount */
  package_amount: Scalars['String'];
  /** The package weight */
  package_weight: Scalars['String'];
  /** The pickup time */
  pickup_time?: Maybe<Scalars['String']>;
  /** Shipping from information */
  ship_from: ShippingInfo;
  /** Shipping to information */
  ship_to: ShippingInfo;
};

/** Merchant Collections on Shoppt. */
export type Collection = {
  __typename?: 'Collection';
  /** The base_image_url */
  base_image_url?: Maybe<Scalars['String']>;
  /** The collection business owner */
  business?: Maybe<Business>;
  /** The ID of the merchant that owns the collection */
  business_profile_id?: Maybe<Scalars['Int']>;
  /** The collection collections */
  collections: Array<Collection>;
  /** When the collection was created. */
  created_at: Scalars['DateTime'];
  /** Collection description */
  description?: Maybe<Scalars['String']>;
  /** The collection dimension size */
  dimension_size?: Maybe<PackageDimension>;
  /** When the collection was created. */
  expires_at?: Maybe<Scalars['DateTime']>;
  /** All favorites for the collection */
  favorites_count: Scalars['Int'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The media */
  media?: Maybe<MediaContent>;
  /** The media type */
  media_type?: Maybe<Scalars['String']>;
  /** Collection name */
  name: Scalars['String'];
  /** The collection products */
  products: Array<ShoplistProduct>;
  /** The collection reward percentage */
  reward_percentage?: Maybe<Scalars['String']>;
  /** The shipping category */
  shipping_category?: Maybe<ShippingCategory>;
  /** The collection status */
  status?: Maybe<Scalars['String']>;
  /** The ID of the sub merchant that owns the collection */
  sub_business_id?: Maybe<Scalars['Int']>;
  /** The type */
  type?: Maybe<Scalars['String']>;
  /** When the collection was last updated. */
  updated_at: Scalars['DateTime'];
  /** Is the collection favorited by the user */
  user_is_favorite: Scalars['Boolean'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type CollectionOrAdvertOrProduct = Advert | Collection | ShoplistProduct;

/** A paginated list of Collection items. */
export type CollectionPaginator = {
  __typename?: 'CollectionPaginator';
  /** A list of Collection items. */
  data: Array<Collection>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CommercePurchaseInput = {
  cart_id?: InputMaybe<Scalars['String']>;
  products_by_business: Array<CommercePurchasePerBusiness>;
  shoplist_owner_business_id: Scalars['String'];
  user_delivery_location_id: Scalars['String'];
};

export type CommercePurchasePerBusiness = {
  business_id: Scalars['String'];
  delivery_location_id: Scalars['String'];
  products: Array<CommercePurchaseProduct>;
  shipping_info: ShippingInfoInput;
};

export type CommercePurchaseProduct = {
  product_id: Scalars['String'];
  quantity: Scalars['Int'];
  shoplist_id?: InputMaybe<Scalars['String']>;
  variant_id?: InputMaybe<Scalars['String']>;
};

/** All commercial banks on Shoppt. */
export type CommercialBank = {
  __typename?: 'CommercialBank';
  /** The bank code */
  code: Scalars['String'];
  /** The bank logo */
  logo?: Maybe<Scalars['String']>;
  /** The bank name */
  name: Scalars['String'];
};

/** Single content */
export type Content = {
  __typename?: 'Content';
  data?: Maybe<CollectionOrAdvertOrProduct>;
  id: Scalars['Int'];
  type: Scalars['String'];
};

export enum ContentType {
  BannerAds = 'BANNER_ADS',
  BusinessContents = 'BUSINESS_CONTENTS',
  Explore = 'EXPLORE',
  Favorite = 'FAVORITE',
  Limited = 'LIMITED',
  RecommendedForYou = 'RECOMMENDED_FOR_YOU',
  SponsoredShoplist = 'SPONSORED_SHOPLIST',
  WhatsNew = 'WHATS_NEW'
}

/** A Conversation */
export type Conversation = {
  __typename?: 'Conversation';
  /** message sent */
  Message?: Maybe<Message>;
  /** When the conversation was created. */
  created_at: Scalars['DateTime'];
  /** sender user id */
  from_user_id: Scalars['Int'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** reciever user id */
  to_user_id: Scalars['Int'];
  /** When the conversation was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Courier information */
export type Courier = {
  __typename?: 'Courier';
  /** The courier ID */
  courier_id: Scalars['String'];
  /** The courier image URL */
  courier_image: Scalars['String'];
  /** The courier name */
  courier_name: Scalars['String'];
  /** The currency */
  currency: Scalars['String'];
  /** The delivery ETA */
  delivery_eta?: Maybe<Scalars['String']>;
  /** The delivery ETA time */
  delivery_eta_time?: Maybe<Scalars['DateTime']>;
  /** Discount information */
  discount: Discount;
  /** The dropoff station */
  dropoff_station?: Maybe<Scalars['String']>;
  /** Additional information */
  info?: Maybe<Array<Scalars['String']>>;
  /** Insurance information */
  insurance: Insurance;
  /** The pickup ETA */
  pickup_eta?: Maybe<Scalars['String']>;
  /** The pickup ETA time */
  pickup_eta_time?: Maybe<Scalars['DateTime']>;
  /** The pickup station */
  pickup_station?: Maybe<Scalars['String']>;
  /** The courier ratings */
  ratings: Scalars['Float'];
  /** The service code */
  service_code: Scalars['String'];
  /** The service type */
  service_type: Scalars['String'];
  /** The total cost */
  total: Scalars['Float'];
  /** The tracking level */
  tracking_level: Scalars['Int'];
  /** The VAT amount */
  vat: Scalars['Float'];
  /** Whether a waybill is available */
  waybill: Scalars['Boolean'];
};

export type CreateProductPricingInput = {
  amount: Scalars['String'];
  supported_area_id: Scalars['Int'];
};

/** Stat response */
export type DataDate = {
  __typename?: 'DataDate';
  data: Scalars['Float'];
  date: Scalars['String'];
};

/** Delivery locations on Shoppt. */
export type DeliveryLocation = {
  __typename?: 'DeliveryLocation';
  /** The address */
  address?: Maybe<Scalars['String']>;
  /** The address_code */
  address_code: Scalars['String'];
  /** The attached business admin */
  business_admin?: Maybe<BusinessAdmin>;
  /** The business_profile_id */
  business_profile_id?: Maybe<Scalars['Int']>;
  /** When the delivery location was created. */
  created_at: Scalars['DateTime'];
  /** Is default */
  default?: Maybe<Scalars['Boolean']>;
  /** The fixed_cost */
  fixed_cost?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The lat */
  lat?: Maybe<Scalars['String']>;
  /** The lng */
  lng?: Maybe<Scalars['String']>;
  /** The phone */
  phone?: Maybe<Scalars['String']>;
  /** The status */
  status?: Maybe<Scalars['String']>;
  /** The supported area */
  supported_area?: Maybe<SupportedArea>;
  /** The title */
  title?: Maybe<Scalars['String']>;
  /** When the delivery location was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user email */
  user_email?: Maybe<Scalars['String']>;
  /** The user_id */
  user_id?: Maybe<Scalars['Int']>;
  /** The user name */
  user_name?: Maybe<Scalars['String']>;
  /** Unique UUID */
  uuid?: Maybe<Scalars['String']>;
};

/** A paginated list of DeliveryLocation items. */
export type DeliveryLocationPaginator = {
  __typename?: 'DeliveryLocationPaginator';
  /** A list of DeliveryLocation items. */
  data: Array<DeliveryLocation>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Discount information */
export type Discount = {
  __typename?: 'Discount';
  /** The discounted amount */
  discounted: Scalars['Float'];
  /** The discount percentage */
  percentage: Scalars['Float'];
  /** The discount symbol */
  symbol: Scalars['String'];
};

/** Dynamic funding accounts */
export type DynamicFundingAccount = {
  __typename?: 'DynamicFundingAccount';
  /** The account number */
  account_number: Scalars['String'];
  /** The amount to fund with */
  amount: Scalars['Float'];
  /** The charges */
  charges: Scalars['Float'];
  /** When the payment request was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The account reference */
  reference: Scalars['String'];
  /** The resolve status */
  resolved: Scalars['Boolean'];
  /** When the payment request was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type EachBenefit = {
  __typename?: 'EachBenefit';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type EachBookedItineraries = {
  __typename?: 'EachBookedItineraries';
  ItineraryInfo: ItineraryInfo;
  Type: Scalars['String'];
};

export type EachETickets = {
  __typename?: 'EachETickets';
  ETicketNumber: Scalars['String'];
  ETicketType: Scalars['String'];
  ItemRPH: Scalars['Int'];
};

export type EachExtraService = {
  __typename?: 'EachExtraService';
  Behavior: Scalars['String'];
  CheckInType: Scalars['String'];
  Description: Scalars['String'];
  IsMandatory: Scalars['String'];
  NameNumber: Scalars['Int'];
  ServiceCost: SingleTaxBreakUp;
  ServiceId: Scalars['String'];
  Type: Scalars['String'];
};

export type EachPtrPaxDetails = {
  __typename?: 'EachPTRPaxDetails';
  FirstName: Scalars['String'];
  Id: Scalars['Int'];
  IsActive: Scalars['Boolean'];
  LastName: Scalars['String'];
  PTRId: Scalars['Int'];
  PassengerType: Scalars['String'];
  PaxId: Scalars['Int'];
  TicketNumber: Scalars['String'];
  TicketStatus: Scalars['Int'];
  Title: Scalars['String'];
};

export type EachPassengerInfos = {
  __typename?: 'EachPassengerInfos';
  EachETickets?: Maybe<Array<EachETickets>>;
  Passenger?: Maybe<SinglePassengerDetails>;
};

export type EachReservationItem = {
  __typename?: 'EachReservationItem';
  ATAinfoRef?: Maybe<Scalars['String']>;
  AirEquipmentType: Scalars['String'];
  AirlinePNR: Scalars['String'];
  ArrivalAirportLocationCode: Scalars['String'];
  ArrivalDateTime: Scalars['String'];
  ArrivalTerminal: Scalars['String'];
  Baggage?: Maybe<Scalars['String']>;
  CabinClass: Scalars['String'];
  CabinClassType: Scalars['String'];
  DepartureAirportLocationCode: Scalars['String'];
  DepartureDateTime: Scalars['String'];
  DepartureTerminal?: Maybe<Scalars['String']>;
  FareFamily: Scalars['String'];
  FlightNumber: Scalars['String'];
  FlightStatus: Scalars['String'];
  IsReturn: Scalars['Boolean'];
  ItemRPH: Scalars['Int'];
  JourneyDuration: Scalars['String'];
  MarketingAirlineCode: Scalars['String'];
  NumberInParty: Scalars['Int'];
  OperatingAirlineCode: Scalars['String'];
  ResBookDesigCode: Scalars['String'];
  StopQuantity: Scalars['Int'];
};

export type EachTripDetailsPtc_FareBreakdowns = {
  __typename?: 'EachTripDetailsPTC_FareBreakdowns';
  BaggageInfo: Array<Scalars['String']>;
  CabinBaggageInfo: Array<Scalars['String']>;
  IsPenaltyDetailsAvailable: Scalars['Boolean'];
  PassengerTypeQuantity?: Maybe<PassengerTypeQuantity>;
  TripDetailsPassengerFare?: Maybe<TripDetailsPassengerFare>;
};

/** Engagement Metrics */
export type EngagementMetrics = {
  __typename?: 'EngagementMetrics';
  /** Number of clicks */
  clicks: Scalars['Int'];
  /** Number of likes */
  likes: Scalars['Int'];
  /** Number of completed polls */
  polls_completed: Scalars['Int'];
  /** Number of purchases */
  purchases: Scalars['Int'];
  /** Number of saves */
  saves: Scalars['Int'];
  /** Number of shares */
  shares: Scalars['Int'];
  /** Total number of engagements */
  total: Scalars['Int'];
  /** Number of website visits */
  website_visits: Scalars['Int'];
};

/** EnrolledCard */
export type EnrolledCard = {
  __typename?: 'EnrolledCard';
  /** When the enrolled card was created. */
  created_at: Scalars['DateTime'];
  /** Card expiry date */
  expiry_date?: Maybe<Scalars['String']>;
  /** Card first 6 digits */
  first_6_digits?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Card active state */
  is_active: Scalars['Boolean'];
  /** Card issuer. E.g mastercard, visa */
  issuer: Scalars['String'];
  /** Card last 4 digits */
  last_4_digits?: Maybe<Scalars['String']>;
  /** Card name */
  name: Scalars['String'];
  /** When the enrolled card was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** EnrolledCard Transactions */
export type EnrolledCardTransaction = {
  __typename?: 'EnrolledCardTransaction';
  /** The amount */
  amount: Scalars['Float'];
  /** The transaction category */
  category: Scalars['String'];
  /** The transaction city */
  city: Scalars['String'];
  /** The transaction country */
  country: Scalars['String'];
  /** When the enrolled card transaction was created. */
  created_at: Scalars['DateTime'];
  /** The currency */
  currency: Scalars['Float'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The card last_4_digits */
  last_4_digits?: Maybe<Scalars['String']>;
  /** The transaction reference */
  reference: Scalars['String'];
  /** The transaction datetime */
  transaction_date_time: Scalars['String'];
  /** When the enrolled card transaction was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of EnrolledCardTransaction items. */
export type EnrolledCardTransactionPaginator = {
  __typename?: 'EnrolledCardTransactionPaginator';
  /** A list of EnrolledCardTransaction items. */
  data: Array<EnrolledCardTransaction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** The entity performance */
export type EntityPerformance = {
  __typename?: 'EntityPerformance';
  /** The clicks */
  clicks: Scalars['Int'];
  /** The conversions */
  conversions: Scalars['Int'];
  /** Unique primary key. */
  id: Scalars['Int'];
  /** The impressions */
  impressions: Scalars['Int'];
  /** When the entity performance was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** ExchangeRate */
export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  /** When the exchange rate was created. */
  created_at: Scalars['DateTime'];
  /** The from currency */
  from: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The to currency */
  to: Scalars['String'];
  /** The unit cost */
  unit_cost: Scalars['Float'];
  /** When the exchange rate was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type ExtraServices = {
  __typename?: 'ExtraServices';
  Services?: Maybe<Array<EachExtraService>>;
};

/** A Favorite */
export type Favorite = {
  __typename?: 'Favorite';
  /** User favorite content. It can be like, unlike, null */
  content?: Maybe<Scalars['String']>;
  /** When the favorite was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The favorite service id */
  service_id: Scalars['String'];
  /** The favorite service model */
  service_model: Scalars['String'];
  /** The favorite service name */
  service_name: Scalars['String'];
  /** When the favorite was last updated. */
  updated_at: Scalars['DateTime'];
  /** The favorite service name */
  user_id: Scalars['Int'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export enum FavoriteType {
  Like = 'LIKE',
  Unlike = 'UNLIKE'
}

export enum FilterPeriods {
  AllTime = 'ALL_TIME',
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** FlightBooking */
export type FlightBooking = {
  __typename?: 'FlightBooking';
  /** amount */
  amount: Scalars['Float'];
  /** applied_refund_prt_id */
  applied_refund_prt_id?: Maybe<Scalars['String']>;
  /** currency */
  currency: Scalars['String'];
  /** extra_data */
  extra_data: Scalars['String'];
  /** Booking refund status */
  has_refund: Scalars['Boolean'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Price changed status */
  price_changed: Scalars['Boolean'];
  /** quote_for_refund_ptr_id */
  quote_for_refund_ptr_id?: Maybe<Scalars['String']>;
  /** reference */
  reference: Scalars['String'];
  /** schedule_change_ptr_id */
  schedule_change_ptr_id?: Maybe<Scalars['String']>;
  /** Flight schedule change status */
  schedule_changed: Scalars['Boolean'];
  /** Flight booking status */
  status: Scalars['String'];
  /** time_limit */
  time_limit: Scalars['String'];
  /** Total refund */
  total_refund: Scalars['Float'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of FlightBooking items. */
export type FlightBookingPaginator = {
  __typename?: 'FlightBookingPaginator';
  /** A list of FlightBooking items. */
  data: Array<FlightBooking>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type FlightFareInfo = {
  __typename?: 'FlightFareInfo';
  Currency?: Maybe<Scalars['String']>;
  FareType?: Maybe<Scalars['String']>;
  PassengerFare?: Maybe<Array<SinglPassengerFare>>;
};

export type FlightOption = {
  /** The departure time */
  departure_time: Scalars['String'];
  /** The destination airport code */
  destination_airport: Scalars['String'];
  /** The origin airport code */
  origin_airport: Scalars['String'];
};

export type FlightOptionUpdate = {
  AirlineCode: Scalars['String'];
  CityPair: Scalars['String'];
  DepartureTime: Scalars['String'];
  TravelDate: Scalars['String'];
  flight_no: Scalars['String'];
};

export type FlightPenaltyInfo = {
  __typename?: 'FlightPenaltyInfo';
  Penaltydetails?: Maybe<Array<SinglePenaltydetails>>;
};

/** FlightRevalidationDetails */
export type FlightRevalidationDetails = {
  __typename?: 'FlightRevalidationDetails';
  AirItineraryPricingInfo: AirItineraryPricingInfo;
  Airline: Airline;
  DirectionInd: Scalars['String'];
  FareRules?: Maybe<SingleFlightFareRules>;
  IsPassportMandatory: Scalars['Boolean'];
  OriginDestinationOptions: Array<SingleOriginDestinationOption>;
  PaxNameCharacterLimit: Scalars['Int'];
  RequiredFieldsToBook?: Maybe<Array<Scalars['String']>>;
  SequenceNumber: Scalars['Int'];
  TicketType: Scalars['String'];
  ValidatingAirlineCode: Scalars['String'];
};

export type FlightSegmentDetails = {
  __typename?: 'FlightSegmentDetails';
  ArrivalAirportLocationCode: Scalars['String'];
  ArrivalDateTime: Scalars['String'];
  DepartureAirportLocationCode: Scalars['String'];
  DepartureDateTime: Scalars['String'];
  Equipment?: Maybe<Scalars['String']>;
  JourneyDuration: Scalars['Float'];
  MarketingCarriercode: Scalars['String'];
  MarketingFlightNumber: Scalars['String'];
  OperatingCarrierCode: Scalars['String'];
  OperatingFlightNumber: Scalars['String'];
  StopQuantityInfos?: Maybe<Array<Scalars['String']>>;
  stops: Scalars['Int'];
};

export type FlightSegmentInfo = {
  __typename?: 'FlightSegmentInfo';
  CabinClassCode?: Maybe<Scalars['String']>;
  CabinClassType?: Maybe<Scalars['String']>;
  FareFamily?: Maybe<Scalars['String']>;
  RBD: Scalars['String'];
  SeatsRemaining?: Maybe<Scalars['String']>;
};

export type FlightSegmentInfoOptions = {
  __typename?: 'FlightSegmentInfoOptions';
  Type: Scalars['String'];
  Value: Scalars['String'];
};

export enum FollowAction {
  Follow = 'FOLLOW',
  Unfollow = 'UNFOLLOW'
}

export type GadgetClaimMeta = {
  __typename?: 'GadgetClaimMeta';
  account_name: Scalars['String'];
  account_number: Scalars['String'];
  address: Scalars['String'];
  back_image: Scalars['String'];
  bank_code: Scalars['String'];
  bank_name: Scalars['String'];
  cost_of_repair: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
  front_image: Scalars['String'];
  incident_type: Scalars['String'];
  payment_receipt: Scalars['String'];
  police_report?: Maybe<Scalars['String']>;
  policy_number: Scalars['String'];
  repair_receipt: Scalars['String'];
  side_image: Scalars['String'];
  status_time_line: Array<NameTimeStamp>;
  time: Scalars['String'];
};

export type GadgetInsuranceRequest = {
  date_of_purchase: Scalars['String'];
  device_about_image_url: Scalars['String'];
  device_color: Scalars['String'];
  device_make: Scalars['String'];
  device_model: Scalars['String'];
  device_serial_number: Scalars['String'];
  device_type: Scalars['String'];
  device_value: Scalars['String'];
  imei_one?: InputMaybe<Scalars['String']>;
  imei_two?: InputMaybe<Scalars['String']>;
  model_number: Scalars['String'];
  occupation: Scalars['String'];
  product_id: Scalars['String'];
};

/** User game profile */
export type GameProfile = {
  __typename?: 'GameProfile';
  /** Account level attached */
  account_level: AccountLevel;
  /** All badges */
  badges: Array<Badge>;
  /** All participated challenges */
  challenges: Array<ChallengeInstance>;
  /** When the game profile was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The next account level */
  next_level?: Maybe<AccountLevel>;
  /** All offer gotten on Shoppt. */
  offers: Array<Offer>;
  /** All pending badges */
  pending_badges: Array<Badge>;
  /** The user point summary */
  points_summary: PointsSummary;
  /** All point won on Shoppt. */
  total_points: Scalars['String'];
  /** When the game profile was last updated. */
  updated_at: Scalars['DateTime'];
  /** User attached */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Gender Distribution */
export type GenderDistribution = {
  __typename?: 'GenderDistribution';
  /** Number of female users */
  female: Scalars['Int'];
  /** Number of male users */
  male: Scalars['Int'];
};

export type GoodsInTransitInsuranceRequest = {
  business_email: Scalars['String'];
  business_name: Scalars['String'];
  business_phone_number: Scalars['String'];
  gross_annual_limit: Scalars['String'];
  limit_per_trip: Scalars['String'];
  number_of_annual_trips: Scalars['String'];
  operation_type: Scalars['String'];
  product_id: Scalars['String'];
  type_of_goods: Scalars['String'];
};

/** HMOHospital */
export type HmoHospital = {
  __typename?: 'HMOHospital';
  address: Scalars['String'];
  id: Scalars['String'];
  location: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['Int'];
};

export type HealthBeneficiary = {
  date_of_birth: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  gender: Scalars['String'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
};

export type HealthInsuranceRequest = {
  beneficiaries?: InputMaybe<Array<HealthBeneficiary>>;
  number_of_beneficiaries?: InputMaybe<Scalars['Int']>;
  payment_plan: Scalars['String'];
  product_id: Scalars['String'];
  /** Can be malaria or general */
  type: Scalars['String'];
};

export type HomeInsuranceRequest = {
  description: Scalars['String'];
  home_items: Array<InsuranceHomeItem>;
  insurance_start_date: Scalars['String'];
  is_full_year: Scalars['Boolean'];
  pre_ownership: Scalars['String'];
  product_id: Scalars['String'];
  tenancy: Scalars['String'];
};

/** Instagram. */
export type InstagramMedia = {
  __typename?: 'InstagramMedia';
  /** Is media already imported */
  isImported?: Maybe<Scalars['Boolean']>;
  /** The Media id */
  media_id?: Maybe<Scalars['String']>;
  /** The media thumbnail */
  media_thumbnail?: Maybe<Scalars['String']>;
  /** The Media title */
  media_title?: Maybe<Scalars['String']>;
  /** The Media url */
  media_url?: Maybe<Scalars['String']>;
  /** The Media type */
  type?: Maybe<Scalars['String']>;
};

/** Instagram. */
export type InstagramMediaData = {
  __typename?: 'InstagramMediaData';
  /** The Media list */
  list?: Maybe<Array<Maybe<InstagramMedia>>>;
  /** The Media paging */
  paging?: Maybe<InstagramMediaPaging>;
};

/** Instagram. */
export type InstagramMediaPaging = {
  __typename?: 'InstagramMediaPaging';
  /** The Media id */
  cursors?: Maybe<InstagramMediaPagingCursor>;
  /** The Media next */
  next?: Maybe<Scalars['String']>;
};

/** Instagram. */
export type InstagramMediaPagingCursor = {
  __typename?: 'InstagramMediaPagingCursor';
  /** The cursor after */
  after?: Maybe<Scalars['String']>;
  /** The cursor before */
  before?: Maybe<Scalars['String']>;
};

/** Insurance information */
export type Insurance = {
  __typename?: 'Insurance';
  /** The insurance code */
  code: Scalars['String'];
  /** The insurance fee */
  fee: Scalars['Float'];
};

export type InsuranceCategory = {
  __typename?: 'InsuranceCategory';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** InsuranceClaim */
export type InsuranceClaim = {
  __typename?: 'InsuranceClaim';
  app_mode: Scalars['String'];
  betterment_deduction_amount: Scalars['String'];
  claim_amount: Scalars['String'];
  claim_description: Scalars['String'];
  claim_invoice_url?: Maybe<Scalars['String']>;
  claim_owner: Scalars['String'];
  claim_owner_email: Scalars['String'];
  claim_owner_first_name: Scalars['String'];
  claim_owner_last_name: Scalars['String'];
  claim_owner_phone_number: Scalars['String'];
  claim_status: Scalars['String'];
  claim_type: Scalars['String'];
  created_at: Scalars['String'];
  customer_id: Scalars['String'];
  decline_reason: Scalars['String'];
  estimate_amount: Scalars['String'];
  excess_deduction_amount: Scalars['String'];
  gadget_claim_meta?: Maybe<GadgetClaimMeta>;
  id: Scalars['String'];
  incident_date?: Maybe<Scalars['String']>;
  offer_amount: Scalars['String'];
  offer_form_url?: Maybe<Scalars['String']>;
  policy_id: Scalars['String'];
  updated_at: Scalars['String'];
  vehicle_claim_meta?: Maybe<Scalars['String']>;
};

export type InsuranceHomeItem = {
  amount: Scalars['String'];
  name: Scalars['String'];
};

/** InsurancePolicy */
export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  /** The customer ID from the provider */
  customer_id: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The insurance type. More like its category */
  insurance_type: Scalars['String'];
  /** The policy name */
  policy_name: Scalars['String'];
  /** Policy purchase history */
  policy_purchase_history: Array<PolicyPurchaseHistory>;
  /** The attached user */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** InsuranceProduct */
export type InsuranceProduct = {
  __typename?: 'InsuranceProduct';
  active: Scalars['Boolean'];
  business_how_it_works?: Maybe<Scalars['String']>;
  business_how_to_claim?: Maybe<Scalars['String']>;
  certificateable: Scalars['Boolean'];
  claimable: Scalars['Boolean'];
  cover_period?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  full_benefits_html?: Maybe<Scalars['String']>;
  full_benefits_json?: Maybe<Array<EachBenefit>>;
  how_it_works?: Maybe<Scalars['String']>;
  how_to_claim?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inspectable: Scalars['Boolean'];
  is_dynamic_pricing: Scalars['Boolean'];
  key_benefits?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  price: Scalars['String'];
  productCategory: InsuranceCategory;
  product_category_id: Scalars['String'];
  provider: InsuranceProvider;
  provider_id: Scalars['String'];
  renewable: Scalars['Boolean'];
  route_name: Scalars['String'];
};

export type InsuranceProfile = {
  __typename?: 'InsuranceProfile';
  address?: Maybe<Scalars['String']>;
  date_of_birth: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  gender: Scalars['String'];
  id_image?: Maybe<Scalars['String']>;
  id_number?: Maybe<Scalars['String']>;
  identification_name?: Maybe<Scalars['String']>;
  identification_url?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  lga?: Maybe<Scalars['String']>;
  phone_number: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type InsuranceProvider = {
  __typename?: 'InsuranceProvider';
  company_name: Scalars['String'];
  id: Scalars['String'];
};

export type ItinTotalFare = {
  __typename?: 'ItinTotalFare';
  ActualFare?: Maybe<SingleTaxBreakUp>;
  BaseFare?: Maybe<SingleTaxBreakUp>;
  EquivFare?: Maybe<SingleTaxBreakUp>;
  TotalFare?: Maybe<SingleTaxBreakUp>;
  TotalTax?: Maybe<SingleTaxBreakUp>;
};

export type ItineraryInfo = {
  __typename?: 'ItineraryInfo';
  ReservationItems: Array<EachReservationItem>;
};

/** Leaderboard user */
export type LeaderboardUser = {
  __typename?: 'LeaderboardUser';
  index: Scalars['Int'];
  points: Scalars['Float'];
  user: User;
};

export type Location = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** Location Distribution */
export type LocationDistribution = {
  __typename?: 'LocationDistribution';
  /** Count of users in this location */
  count: Scalars['Int'];
  /** Location title */
  title: Scalars['String'];
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum ManageCollectionAction {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type ManagerInfo = {
  first_name: Scalars['String'];
  id_number: Scalars['String'];
  id_type: Scalars['String'];
  last_name: Scalars['String'];
};

export type ManagerInfoData = {
  __typename?: 'ManagerInfoData';
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

/** Media on Shoppt. */
export type MediaContent = {
  __typename?: 'MediaContent';
  /** When the product was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Media type */
  media_type?: Maybe<Scalars['String']>;
  /** Media url */
  media_url?: Maybe<Scalars['String']>;
  /** Media progress percentage */
  percentage?: Maybe<Scalars['Float']>;
  /** When the product was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of MediaContent items. */
export type MediaContentPaginator = {
  __typename?: 'MediaContentPaginator';
  /** A list of MediaContent items. */
  data: Array<MediaContent>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Merchant category */
export type MerchantCategory = {
  __typename?: 'MerchantCategory';
  name: Scalars['String'];
  slug: Scalars['String'];
  sub_categories: Array<MerchantSubCategory>;
};

/** Merchant sub category */
export type MerchantSubCategory = {
  __typename?: 'MerchantSubCategory';
  label: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  soon: Scalars['Boolean'];
  uuid: Scalars['String'];
};

/** A Message */
export type Message = {
  __typename?: 'Message';
  /** The Conversation service model */
  content: Scalars['String'];
  /** User Conversation content type. It can be text, image, video, audio */
  content_type: Scalars['String'];
  /** When the conversation was created. */
  created_at: Scalars['DateTime'];
  /** sender user id */
  from_user_id: Scalars['Int'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The Conversation service id */
  service_id: Scalars['String'];
  /** The Conversation service model */
  service_model: Scalars['String'];
  /** The Conversation service name */
  service_name: Scalars['String'];
  /** reciever user id */
  to_user_id: Scalars['Int'];
  /** When the conversation was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export enum MessageType {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Text = 'TEXT',
  Video = 'VIDEO'
}

/** Metric on Shoppt. */
export type Metric = {
  __typename?: 'Metric';
  /** The entity type */
  entity_type: Scalars['String'];
  /** The entity metadata */
  event_metadata?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id?: Maybe<Scalars['Int']>;
  /** The ID of the user that owns the metric */
  user_id: Scalars['Int'];
  /** Unique UUID */
  uuid?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept business admin invitation */
  AcceptBusinessInvitation: Scalars['Boolean'];
  /** Accept quote for refund */
  AcceptQuoteForRefund: ScheduleChangeResponse;
  /** Accept schedule change */
  AcceptSchedulechange: ScheduleChangeResponse;
  /** Add or Remove Collection */
  AddRemoveCollectionProduct: Collection;
  /** Add user bank account */
  AddUserBank: UserBank;
  /** Add user card */
  AddUserCard: UserCard;
  /** Add user to waitlist */
  AddUserToWaitlist: Waitlist;
  /** Apply for refund */
  ApplyForRefund: ScheduleChangeResponse;
  /** Cancel flight booking */
  CancelBooking: FlightBooking;
  /** Check if media account is connected */
  CheckIfMediaAccountIsConnected?: Maybe<Scalars['Boolean']>;
  /** Check if product is available in an area */
  CheckIfProductIsAvailableInArea: Array<ProductDeliveryLocationResponse>;
  /** Check if username already exist */
  CheckIfUsernameExist: Scalars['Boolean'];
  /** Check money transfer status */
  CheckTransferStatus: Scalars['Boolean'];
  /** Check client user verification */
  CheckUserVerification?: Maybe<User>;
  /** Claim point earning for month */
  ClaimPointGained: Scalars['Boolean'];
  /** Claim reward */
  ClaimReward?: Maybe<PointEarned>;
  /** Connect shopify store */
  ConnectShopifyStore: Scalars['Boolean'];
  /** Create ads Campaign */
  CreateAdvert: Advert;
  /** Create delivery areas for business */
  CreateBusinessDeliveryAreas: Scalars['Boolean'];
  /** Create or update advert poll */
  CreateOrUpdateAdvertPoll: Scalars['Boolean'];
  /** Create payment request */
  CreatePaymentRequest: PaymentRequest;
  /** Create Shoplist Collection */
  CreateShoplistCollection: Collection;
  /** Create Shoplist product */
  CreateShoplistProduct: ShoplistProduct;
  /** Create Shoplist product Variant */
  CreateShoplistProductPricing: Pricing;
  /** Create Shoplist Product Reward */
  CreateShoplistProductReward: ShoplistReward;
  /** Create Shoplist product Variant */
  CreateShoplistProductVariant: ProductVariant;
  /** Create a short code */
  CreateShortCode: Scalars['String'];
  /** Create user delivery location */
  CreateUserDeliveryLocation: Scalars['Boolean'];
  /** Create User Shoplist cart */
  CreateUserShoplistCart: ShoplistCart;
  /** Delete a Collection */
  DeleteCollection?: Maybe<Scalars['Boolean']>;
  /** Delete Message */
  DeleteCollectionMessage?: Maybe<Scalars['Boolean']>;
  /** Delete a delivery location */
  DeleteDeliveryLocation: Scalars['Boolean'];
  /** Delete Shoplist  Order */
  DeleteShoplistOrder?: Maybe<Scalars['Boolean']>;
  /** Delete Product Reward */
  DeleteShoplistProductReward?: Maybe<Scalars['Boolean']>;
  /** Delete auth user */
  DeleteUser?: Maybe<Scalars['Boolean']>;
  /** Delete user saved card */
  DeleteUserCard: Scalars['Boolean'];
  /** Delete user cart */
  DeleteUserShoplistCart?: Maybe<Scalars['Boolean']>;
  /** Enroll User Card */
  EnrolledUserCard: EnrolledCard;
  /** Follow or unfollow Business */
  FollowUnfollowBusiness: Scalars['Boolean'];
  /** Fund user wallet */
  FundWallet: Wallet;
  /** Generate funding account */
  GenerateFundingAccount: DynamicFundingAccount;
  /** Generate wallet account */
  GenerateWalletAccount: Scalars['Boolean'];
  /** Import CSV Products */
  ImportCSVProducts: Scalars['Boolean'];
  /** Import instagram media */
  ImportInstagramMedia?: Maybe<Scalars['Boolean']>;
  /** Import Shopify Product CSV */
  ImportShopifyProductFromCSV: Scalars['Boolean'];
  /** Import Shopify Products */
  ImportShopifyProducts: Scalars['Boolean'];
  /** Import WooCommerce Products */
  ImportWooCommerceProducts: Scalars['Boolean'];
  /** Initiate product payment */
  InitiateProductPayment: PointEarned;
  /** Initiate transfer to bank account */
  InitiateTransferToBank: PointEarned;
  /** Initiate transfer to shoppoint user */
  InitiateTransferToShoppointUser: PointEarned;
  /** Invite a business admin */
  InviteBusinessAdmin: Scalars['Boolean'];
  /** Join a challenge */
  JoinChallenge: Scalars['Boolean'];
  /** Like or unlike Business */
  LikeUnlikeBusiness: Scalars['Boolean'];
  /** Like or unlike Collection */
  LikeUnlikeCollection: Scalars['Boolean'];
  /** Like or unlike Product */
  LikeUnlikeProduct: Scalars['Boolean'];
  /** Make Commerce Purchase */
  MakeCommercePurchase: Scalars['Boolean'];
  /** Mark notification as read */
  MarkNotificationAsRead: Scalars['Boolean'];
  /** Order ticket */
  OrderFlightTicket: Scalars['Boolean'];
  /** personalize user account */
  PersonalizeAccount: AuthResponse;
  /** Process scanned receipt */
  ProcessScannedReceipt: PointEarned;
  ReportScannedReceipt: Scalars['Boolean'];
  /** Request for a quote of refund */
  RequestQuoteforrefund: ScheduleChangeResponse;
  /** Request shipment rate */
  RequestShipmentRate: Array<ShippingRateResponse>;
  /** Resend user phone OTP */
  ResendPhoneOTP: Scalars['Boolean'];
  /** Resend verify user email */
  ResendVerifyEmail: Scalars['Boolean'];
  /** Resolve bank account */
  ResolveBankAccount: ResolveBankAccountResponse;
  /** Resolve funding account */
  ResolveFundingAccount: Scalars['Boolean'];
  /** Revalidate flight */
  RevalidateFlight: FlightRevalidationDetails;
  /** Save user phone number */
  SaveAuthPhoneNumber: Scalars['Boolean'];
  /** Save badge acknowledged */
  SaveBadgeAcknowledged: Scalars['Boolean'];
  /** Save metric */
  SaveMetric: Scalars['Boolean'];
  /** Save Payment Transaction */
  SavePaymentTransaction: Scalars['Boolean'];
  /** Save user activity */
  SaveUserActivity: Scalars['Boolean'];
  /** Save user push notification device */
  SaveUserPushNotificationDevice: Scalars['Boolean'];
  /** Save user liveness check */
  SaveUserVerification: Scalars['Boolean'];
  /** Scan receipt */
  ScanReciept: ScannedReceiptResponse;
  /** start Collection Message */
  SendCollectionMessage: Message;
  SendGiftCard: Scalars['Boolean'];
  /** Send reset password email */
  SendResetPasswordEmail: User;
  /** Set default bank */
  SetDefaultBank: Scalars['Boolean'];
  /** Set user transaction pin */
  SetTransactionPin: Profile;
  /** Sign in a user */
  SignIn: AuthResponse;
  /** Logout authenticated user */
  SignOut: Scalars['Boolean'];
  /** Sign up a user */
  SignUp: User;
  /** subnit a game entry */
  SubmitChallengeEntry?: Maybe<PointEarned>;
  /** Update business admin */
  UpdateBusinessAdmin: Scalars['Boolean'];
  /** Update business profile */
  UpdateBusinessProfile: Business;
  /** Update delivery location */
  UpdateDeliveryLocation: Scalars['Boolean'];
  /** Update user password */
  UpdatePassword: Scalars['Boolean'];
  /** Update payment request */
  UpdatePaymentRequest?: Maybe<PointEarned>;
  /** Update processing time */
  UpdateProcessingTime: Scalars['Int'];
  /** Update user profile */
  UpdateProfile: Profile;
  /** Update Snapped Receipt */
  UpdateScannedReceipt: ScannedReceipt;
  /** Update Shoplist Collection */
  UpdateShoplistCollection: Collection;
  /** Update Shoplist Order */
  UpdateShoplistOrder: ShoplistReward;
  /** Update Shoplist Order Status */
  UpdateShoplistOrderStatus?: Maybe<Scalars['Boolean']>;
  /** Update Shoplist product */
  UpdateShoplistProduct: ShoplistProduct;
  /** Update Shoplist Product Reward */
  UpdateShoplistProductReward: ShoplistReward;
  /** Update Shoplist product Variant */
  UpdateShoplistProductVariant: ProductVariant;
  /** Update user subscription */
  UpdateUserSubscription: Profile;
  /** Update flight booking */
  Updateflightoptions: ScheduleChangeResponse;
  /** Upload File */
  UploadFile: Scalars['String'];
  /** Verify customer */
  ValidatePaymentCustomer: Scalars['String'];
  /** Verify user email OTP */
  VerifyEmailOTP: User;
  /** Verify user phone OTP */
  VerifyPhoneOTP: User;
};


export type MutationAcceptBusinessInvitationArgs = {
  invitation_code: Scalars['String'];
};


export type MutationAcceptQuoteForRefundArgs = {
  input: AcceptQuoteForRefundRequest;
};


export type MutationAcceptSchedulechangeArgs = {
  booking_reference: Scalars['String'];
};


export type MutationAddRemoveCollectionProductArgs = {
  action: ManageCollectionAction;
  collection_id: Scalars['Int'];
  products?: InputMaybe<Array<Scalars['Int']>>;
  type: Scalars['String'];
};


export type MutationAddUserBankArgs = {
  account_no: Scalars['String'];
  account_type: Scalars['String'];
  bank_code: Scalars['String'];
  sub_business_id?: InputMaybe<Scalars['Int']>;
};


export type MutationAddUserCardArgs = {
  reference: Scalars['String'];
};


export type MutationAddUserToWaitlistArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export type MutationApplyForRefundArgs = {
  booking_reference: Scalars['String'];
};


export type MutationCancelBookingArgs = {
  booking_reference: Scalars['String'];
};


export type MutationCheckIfMediaAccountIsConnectedArgs = {
  account_type: Scalars['String'];
  business_user_id: Scalars['Int'];
};


export type MutationCheckIfProductIsAvailableInAreaArgs = {
  product_ids: Array<Scalars['Int']>;
  user_location: LocationInput;
};


export type MutationCheckIfUsernameExistArgs = {
  username: Scalars['String'];
};


export type MutationCheckTransferStatusArgs = {
  account_type: Scalars['String'];
  existing_transactions: Scalars['Int'];
};


export type MutationCheckUserVerificationArgs = {
  email: Scalars['String'];
};


export type MutationClaimPointGainedArgs = {
  month: Scalars['String'];
};


export type MutationClaimRewardArgs = {
  point_earned_uuid: Scalars['String'];
};


export type MutationConnectShopifyStoreArgs = {
  shopify_store_url: Scalars['String'];
};


export type MutationCreateAdvertArgs = {
  ad_budget: Scalars['String'];
  ad_daytime_end: Scalars['Int'];
  ad_daytime_start: Scalars['Int'];
  ad_end_date: Scalars['String'];
  ad_start_date: Scalars['String'];
  bidding_strategy: Scalars['String'];
  cta_rules: AdCtaRulesInput;
  maximum_cost: Scalars['String'];
  metadata: AdMediaMetadataInput;
  objectives: Objectives;
  promotion_title: Scalars['String'];
  run_until_paused: Scalars['Boolean'];
  source_media?: InputMaybe<Scalars['Upload']>;
  source_media_type: Scalars['String'];
  target_audience_rules: AdTargetAudienceRuleInput;
  type: Scalars['String'];
};


export type MutationCreateBusinessDeliveryAreasArgs = {
  address: Scalars['String'];
  business_admin_id: Scalars['Int'];
  phone: Scalars['String'];
  title: Scalars['String'];
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};


export type MutationCreateOrUpdateAdvertPollArgs = {
  advert_uuid: Scalars['String'];
  answer: Scalars['String'];
};


export type MutationCreatePaymentRequestArgs = {
  amount: Scalars['String'];
  narration: Scalars['String'];
  receiver_user_uuid: Scalars['String'];
};


export type MutationCreateShoplistCollectionArgs = {
  base_image?: InputMaybe<Scalars['Upload']>;
  base_image_url?: InputMaybe<Scalars['String']>;
  base_video_url?: InputMaybe<Scalars['String']>;
  collection_media?: InputMaybe<Scalars['Upload']>;
  description?: InputMaybe<Scalars['String']>;
  dimension_size?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['String']>;
  media_type?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  products?: InputMaybe<Array<Scalars['ID']>>;
  reward_percentage?: InputMaybe<Scalars['String']>;
  shipping_category?: InputMaybe<Scalars['String']>;
  sub_business_id?: InputMaybe<Scalars['Int']>;
  type: UpdateShoplistCollectionType;
};


export type MutationCreateShoplistProductArgs = {
  affiliate_commission: Scalars['String'];
  affiliateable: Scalars['Boolean'];
  brand_id?: InputMaybe<Scalars['Int']>;
  category_id: Scalars['Int'];
  deliverable: Scalars['Boolean'];
  delivery_areas: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dimension_size?: InputMaybe<Scalars['String']>;
  medias: Array<ProductMediaInput>;
  name: Scalars['String'];
  pricing?: InputMaybe<Array<InputMaybe<CreateProductPricingInput>>>;
  primary_image?: InputMaybe<Scalars['Upload']>;
  regular_price: Scalars['String'];
  reward_settings: Array<ProductRewardSettings>;
  shipping_category?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stock_quantity: Scalars['Int'];
  subcategory_id: Scalars['Int'];
  variants: Array<VariantInput>;
  weight: Scalars['String'];
};


export type MutationCreateShoplistProductPricingArgs = {
  amount: Scalars['String'];
  product_id: Scalars['Int'];
  supported_area_id: Scalars['Int'];
};


export type MutationCreateShoplistProductRewardArgs = {
  percentage?: InputMaybe<Scalars['String']>;
  product_id: Scalars['Int'];
  quantity?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<RewardType>;
};


export type MutationCreateShoplistProductVariantArgs = {
  image_url?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  price: Scalars['String'];
  product_id: Scalars['Int'];
  sku?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['String']>;
};


export type MutationCreateShortCodeArgs = {
  content: Scalars['String'];
};


export type MutationCreateUserDeliveryLocationArgs = {
  address: Scalars['String'];
  default: Scalars['Boolean'];
  phone: Scalars['String'];
  title: Scalars['String'];
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};


export type MutationCreateUserShoplistCartArgs = {
  collection_id?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteCollectionArgs = {
  collection_id: Scalars['Int'];
};


export type MutationDeleteCollectionMessageArgs = {
  message_id: Scalars['Int'];
};


export type MutationDeleteDeliveryLocationArgs = {
  delivery_location_id: Scalars['String'];
};


export type MutationDeleteShoplistOrderArgs = {
  reward_id: Scalars['Int'];
};


export type MutationDeleteShoplistProductRewardArgs = {
  reward_id: Scalars['Int'];
};


export type MutationDeleteUserCardArgs = {
  card_uuid: Scalars['String'];
};


export type MutationDeleteUserShoplistCartArgs = {
  cart_id: Scalars['Int'];
};


export type MutationEnrolledUserCardArgs = {
  issuer: Scalars['String'];
  name: Scalars['String'];
  provider_reference: Scalars['String'];
};


export type MutationFollowUnfollowBusinessArgs = {
  action?: InputMaybe<FollowAction>;
  business_uuid?: InputMaybe<Scalars['String']>;
};


export type MutationFundWalletArgs = {
  amount: Scalars['Float'];
  card_uuid?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
};


export type MutationGenerateFundingAccountArgs = {
  amount: Scalars['String'];
};


export type MutationGenerateWalletAccountArgs = {
  business_name?: InputMaybe<Scalars['String']>;
  wallet_uuid: Scalars['String'];
};


export type MutationImportCsvProductsArgs = {
  product_csv: Scalars['Upload'];
};


export type MutationImportInstagramMediaArgs = {
  media_id: Scalars['String'];
  media_title: Scalars['String'];
  media_url: Scalars['String'];
};


export type MutationImportShopifyProductFromCsvArgs = {
  product_csv: Scalars['Upload'];
};


export type MutationImportShopifyProductsArgs = {
  product_ids: Array<Scalars['String']>;
};


export type MutationImportWooCommerceProductsArgs = {
  product_ids: Array<Scalars['Int']>;
};


export type MutationInitiateProductPaymentArgs = {
  account_type: Scalars['String'];
  card_uuid?: InputMaybe<Scalars['String']>;
  products: Array<Products>;
  save_beneficiary?: InputMaybe<Scalars['Boolean']>;
  use_point: Scalars['Boolean'];
};


export type MutationInitiateTransferToBankArgs = {
  account_type: Scalars['String'];
  amount: Scalars['String'];
  beneficiary_account_no: Scalars['String'];
  beneficiary_bank_code: Scalars['String'];
  beneficiary_name: Scalars['String'];
  card_uuid?: InputMaybe<Scalars['String']>;
  name_enquiry_session_id: Scalars['String'];
  narration: Scalars['String'];
  save_beneficiary?: InputMaybe<Scalars['Boolean']>;
  use_point: Scalars['Boolean'];
};


export type MutationInitiateTransferToShoppointUserArgs = {
  account_type: Scalars['String'];
  amount: Scalars['String'];
  card_uuid?: InputMaybe<Scalars['String']>;
  is_business?: InputMaybe<Scalars['Boolean']>;
  narration?: InputMaybe<Scalars['String']>;
  receiver_user_uuid: Scalars['String'];
  save_beneficiary?: InputMaybe<Scalars['Boolean']>;
  use_point: Scalars['Boolean'];
};


export type MutationInviteBusinessAdminArgs = {
  email: Scalars['String'];
  permissions: StorePermissionsInput;
};


export type MutationJoinChallengeArgs = {
  challenge_id: Scalars['String'];
};


export type MutationLikeUnlikeBusinessArgs = {
  business_uuid?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<FavoriteType>;
};


export type MutationLikeUnlikeCollectionArgs = {
  collection_uuid?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<FavoriteType>;
};


export type MutationLikeUnlikeProductArgs = {
  content?: InputMaybe<FavoriteType>;
  product_uuid?: InputMaybe<Scalars['String']>;
};


export type MutationMakeCommercePurchaseArgs = {
  input: CommercePurchaseInput;
};


export type MutationMarkNotificationAsReadArgs = {
  notification_uuid: Scalars['String'];
};


export type MutationOrderFlightTicketArgs = {
  booking_reference: Scalars['String'];
};


export type MutationPersonalizeAccountArgs = {
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationProcessScannedReceiptArgs = {
  account_type: Scalars['String'];
  is_force_continue?: InputMaybe<Scalars['Boolean']>;
  scanned_receipt_uuid: Scalars['String'];
};


export type MutationReportScannedReceiptArgs = {
  report_type: Scalars['String'];
  scanned_receipt_uuid: Scalars['String'];
};


export type MutationRequestQuoteforrefundArgs = {
  booking_reference: Scalars['String'];
  comment: Scalars['String'];
};


export type MutationRequestShipmentRateArgs = {
  input: ShippingRateInput;
};


export type MutationResendPhoneOtpArgs = {
  phone_number: Scalars['String'];
  use_voice?: InputMaybe<Scalars['Boolean']>;
  user_uuid: Scalars['String'];
};


export type MutationResendVerifyEmailArgs = {
  user_uuid: Scalars['String'];
};


export type MutationResolveBankAccountArgs = {
  account_no: Scalars['String'];
  bank_code: Scalars['String'];
  wallet_uuid: Scalars['String'];
};


export type MutationResolveFundingAccountArgs = {
  dynamic_funding_account_uuid: Scalars['String'];
};


export type MutationRevalidateFlightArgs = {
  fare_source_code: Scalars['String'];
};


export type MutationSaveAuthPhoneNumberArgs = {
  phone_number: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationSaveBadgeAcknowledgedArgs = {
  badge_id: Scalars['Int'];
};


export type MutationSaveMetricArgs = {
  entity_type: Scalars['String'];
  entity_uuid: Scalars['String'];
  event_metadata?: InputMaybe<Scalars['String']>;
  event_type: Scalars['String'];
};


export type MutationSavePaymentTransactionArgs = {
  amount: Scalars['String'];
  narration?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  reference: Scalars['String'];
  type: Scalars['String'];
  wallet_uuid: Scalars['String'];
};


export type MutationSaveUserActivityArgs = {
  event: Scalars['String'];
  extra_uuid?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  stage_type: Scalars['String'];
  task_reference?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  user_id?: InputMaybe<Scalars['String']>;
  user_uuid?: InputMaybe<Scalars['String']>;
};


export type MutationSaveUserPushNotificationDeviceArgs = {
  device_token: Scalars['String'];
  device_type: Scalars['String'];
};


export type MutationSaveUserVerificationArgs = {
  bvn?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id_number: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  main_id_type?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Scalars['String']>;
  selfie_image: Scalars['Upload'];
};


export type MutationScanRecieptArgs = {
  image: Scalars['Upload'];
};


export type MutationSendCollectionMessageArgs = {
  collection_uuid: Scalars['String'];
  content: Scalars['String'];
  content_type: MessageType;
  to_user_id: Scalars['Int'];
};


export type MutationSendGiftCardArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  transaction_uuid: Scalars['String'];
};


export type MutationSendResetPasswordEmailArgs = {
  use_voice?: InputMaybe<Scalars['Boolean']>;
  user_uuid: Scalars['String'];
};


export type MutationSetDefaultBankArgs = {
  bank_uuid: Scalars['String'];
};


export type MutationSetTransactionPinArgs = {
  new_pin: Scalars['String'];
  old_pin?: InputMaybe<Scalars['String']>;
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  sso_id?: InputMaybe<Scalars['String']>;
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  is_sso: Scalars['Boolean'];
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
  sso_id?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitChallengeEntryArgs = {
  account_type: AccountType;
  challenge_uuid: Scalars['String'];
  guess_word: Scalars['String'];
};


export type MutationUpdateBusinessAdminArgs = {
  business_admin_uuid: Scalars['String'];
  permissions?: InputMaybe<StorePermissionsInput>;
  status?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateBusinessProfileArgs = {
  account_type?: InputMaybe<Scalars['String']>;
  business_category?: InputMaybe<Scalars['String']>;
  business_document?: InputMaybe<Scalars['Upload']>;
  business_email?: InputMaybe<Scalars['String']>;
  business_logo?: InputMaybe<Scalars['Upload']>;
  business_name?: InputMaybe<Scalars['String']>;
  business_social_links?: InputMaybe<SocialLinks>;
  business_tag?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  manager_info?: InputMaybe<ManagerInfo>;
  phone_number?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDeliveryLocationArgs = {
  address?: InputMaybe<Scalars['String']>;
  business_admin_id?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['Boolean']>;
  delivery_location_id: Scalars['String'];
  is_business: Scalars['Boolean'];
  lat?: InputMaybe<Scalars['String']>;
  lng?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePasswordArgs = {
  old_password: Scalars['String'];
  password: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationUpdatePaymentRequestArgs = {
  account_type: Scalars['String'];
  card_uuid?: InputMaybe<Scalars['String']>;
  payment_request_uuid: Scalars['String'];
  status: Scalars['String'];
  use_point: Scalars['Boolean'];
};


export type MutationUpdateProcessingTimeArgs = {
  point_earned_uuid: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  base_location?: InputMaybe<Location>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  profile_image?: InputMaybe<Scalars['Upload']>;
  savings_target_amount?: InputMaybe<Scalars['String']>;
  snapping_terms_accepted?: InputMaybe<Scalars['Boolean']>;
  spend_group?: InputMaybe<Scalars['String']>;
  usage_policy_accepted?: InputMaybe<Scalars['Boolean']>;
  user_interests?: InputMaybe<Array<Scalars['String']>>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateScannedReceiptArgs = {
  amount: Scalars['String'];
  main_category: Scalars['String'];
  merchant_name: Scalars['String'];
  scanned_receipt_uuid: Scalars['String'];
};


export type MutationUpdateShoplistCollectionArgs = {
  base_image?: InputMaybe<Scalars['Upload']>;
  base_image_url?: InputMaybe<Scalars['String']>;
  base_video_url?: InputMaybe<Scalars['String']>;
  collection_id: Scalars['Int'];
  collection_media?: InputMaybe<Scalars['Upload']>;
  description?: InputMaybe<Scalars['String']>;
  dimension_size?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['String']>;
  media_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<Scalars['ID']>>;
  reward_percentage?: InputMaybe<Scalars['String']>;
  shipping_category?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  sub_business_id?: InputMaybe<Scalars['Int']>;
  type: UpdateShoplistCollectionType;
};


export type MutationUpdateShoplistOrderArgs = {
  percentage?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  reward_id: Scalars['Int'];
  type?: InputMaybe<RewardType>;
};


export type MutationUpdateShoplistOrderStatusArgs = {
  order_id: Scalars['Int'];
  status: OrderStatus;
};


export type MutationUpdateShoplistProductArgs = {
  affiliate_commission?: InputMaybe<Scalars['String']>;
  affiliateable?: InputMaybe<Scalars['Boolean']>;
  attributes?: InputMaybe<Array<VariantSelectedOptions>>;
  brand_id?: InputMaybe<Scalars['Int']>;
  category_id?: InputMaybe<Scalars['Int']>;
  deliverable?: InputMaybe<Scalars['Boolean']>;
  delivery_areas?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  dimension_size?: InputMaybe<Scalars['String']>;
  medias?: InputMaybe<Array<ProductMediaInput>>;
  name?: InputMaybe<Scalars['String']>;
  pricing?: InputMaybe<Array<InputMaybe<UpdateProductPricingInput>>>;
  primary_image?: InputMaybe<Scalars['Upload']>;
  product_id: Scalars['Int'];
  regular_price?: InputMaybe<Scalars['String']>;
  shipping_category?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stock_quantity?: InputMaybe<Scalars['Int']>;
  subcategory_id?: InputMaybe<Scalars['Int']>;
  variants?: InputMaybe<Array<VariantInput>>;
  weight?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateShoplistProductRewardArgs = {
  percentage?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  reward_id: Scalars['Int'];
  type?: InputMaybe<RewardType>;
};


export type MutationUpdateShoplistProductVariantArgs = {
  image_url?: InputMaybe<Scalars['Upload']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['Int']>;
  variant_id: Scalars['Int'];
  weight?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserSubscriptionArgs = {
  subscription_plan_uuid: Scalars['String'];
};


export type MutationUpdateflightoptionsArgs = {
  input: UpdateflightoptionsRequest;
};


export type MutationUploadFileArgs = {
  attachment: Scalars['Upload'];
  type?: InputMaybe<Scalars['String']>;
};


export type MutationValidatePaymentCustomerArgs = {
  customer_id: Scalars['String'];
  payment_code: Scalars['String'];
};


export type MutationVerifyEmailOtpArgs = {
  email: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationVerifyPhoneOtpArgs = {
  otp: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type NameTimeStamp = {
  __typename?: 'NameTimeStamp';
  name: Scalars['String'];
  time_stamp: Scalars['String'];
};

/** Notification on Shoppt. */
export type Notification = {
  __typename?: 'Notification';
  /** The notification title */
  body: Scalars['String'];
  /** When the notification was created. */
  created_at: Scalars['DateTime'];
  /** The url the notification should go to when clicked */
  extra_url?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The data attached to the notification */
  model_type?: Maybe<Scalars['String']>;
  /** The ID of the attached notification data */
  model_type_id?: Maybe<Scalars['String']>;
  /** The notification title */
  title: Scalars['String'];
  /** The notification type */
  type: Scalars['String'];
  /** Unread status */
  unread: Scalars['Boolean'];
  /** When the notification was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user that is attached to the notification */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of Notification items. */
export type NotificationPaginator = {
  __typename?: 'NotificationPaginator';
  /** A list of Notification items. */
  data: Array<Notification>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum Objectives {
  DealPurchases = 'DEAL_PURCHASES',
  ImageViews = 'IMAGE_VIEWS',
  Traffic = 'TRAFFIC',
  VideoViews = 'VIDEO_VIEWS'
}

/** Offer on Shoppt. */
export type Offer = {
  __typename?: 'Offer';
  /** The attached badge */
  badge?: Maybe<Badge>;
  /** When the offer was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The occurence type of the offer. Can be one-time, recurrent */
  occurence_type: Scalars['String'];
  /** Offer short description */
  short_description?: Maybe<Scalars['String']>;
  /** Offer status. Can be active, inactive */
  status: Scalars['String'];
  /** The offer title */
  title: Scalars['String'];
  /** The type of offer. Can be bonus, discount, reward */
  type: Scalars['String'];
  /** When the offer was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type OperatingAirline = {
  __typename?: 'OperatingAirline';
  Code: Scalars['String'];
  Equipment?: Maybe<Scalars['String']>;
  FlightNumber?: Maybe<Scalars['String']>;
};

/** Orders on Shoppt. */
export type Order = {
  __typename?: 'Order';
  /** The business that sold the order */
  business?: Maybe<Business>;
  /** The business delivery location */
  business_delivery?: Maybe<DeliveryLocation>;
  /** The cashback of the order */
  cashback?: Maybe<Scalars['Float']>;
  /** The collection */
  collection?: Maybe<Collection>;
  /** The commission of the order */
  commission?: Maybe<Scalars['Float']>;
  /** When the order was created. */
  created_at: Scalars['DateTime'];
  /** The business delivery fee of the order */
  delivery_fee?: Maybe<Scalars['Float']>;
  /** The discount of the order */
  discount?: Maybe<Scalars['Float']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The order product */
  items: Array<OrderItem>;
  /** payment status of order. */
  payment_status: Scalars['String'];
  /** The shipping info */
  shipping_info?: Maybe<ShippingInfoOrder>;
  /** The business that sold the order */
  sold_by?: Maybe<Business>;
  /** The order status. */
  status: Scalars['String'];
  /** Total amount of order. */
  total_amount: Scalars['Float'];
  /** The total cashback of the order */
  total_cashback?: Maybe<Scalars['Float']>;
  /** The user delivery fee of the order */
  total_delivery_fee?: Maybe<Scalars['Float']>;
  /** The total discount of the order */
  total_discount?: Maybe<Scalars['Float']>;
  /** The total payment processing fee of the order */
  total_payment_processing_fee?: Maybe<Scalars['Float']>;
  /** The total platform fee of the order */
  total_platform_fee?: Maybe<Scalars['Float']>;
  /** When the order was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user delivery location */
  user_delivery?: Maybe<DeliveryLocation>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Order items on Shoppt. */
export type OrderItem = {
  __typename?: 'OrderItem';
  /** When the order item was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The product */
  product: ShoplistProduct;
  /** The quantity */
  quantity: Scalars['Int'];
  /** The total price */
  total_price: Scalars['Float'];
  /** The unit price */
  unit_price: Scalars['Float'];
  /** When the order item was last updated. */
  updated_at: Scalars['DateTime'];
  /** The variant */
  variant?: Maybe<ProductVariant>;
};

/** A paginated list of Order items. */
export type OrderPaginator = {
  __typename?: 'OrderPaginator';
  /** A list of Order items. */
  data: Array<Order>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Packed = 'PACKED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Returned = 'RETURNED',
  Shipped = 'SHIPPED'
}

/** Other Info */
export type OtherInfo = {
  __typename?: 'OtherInfo';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Ptc_FareBreakdowns = {
  __typename?: 'PTC_FareBreakdowns';
  BaggageInfo?: Maybe<Array<Scalars['String']>>;
  CabinBaggageInfo?: Maybe<Array<Scalars['String']>>;
  FareBasisCodes?: Maybe<Array<Scalars['String']>>;
};

/** Product package dimension */
export type PackageDimension = {
  __typename?: 'PackageDimension';
  /** The description_image_url */
  description_image_url: Scalars['String'];
  /** The height */
  height: Scalars['Float'];
  /** The length */
  length: Scalars['Float'];
  /** The max_weight */
  max_weight: Scalars['Float'];
  /** The name */
  name: Scalars['String'];
  /** The width */
  width: Scalars['Float'];
};

/** PaddedVendor */
export type PaddedVendor = {
  __typename?: 'PaddedVendor';
  logo: Scalars['String'];
  merchant_name: Scalars['String'];
  name: Scalars['String'];
  product_uuid?: Maybe<Scalars['String']>;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type PassengerOption = {
  /** The passenger type code */
  code: Scalars['String'];
  /** The quantity of the passenger type */
  quantity: Scalars['String'];
};

export type PassengerTypeQuantity = {
  __typename?: 'PassengerTypeQuantity';
  Code: Scalars['String'];
  Quantity: Scalars['Int'];
};

export type PaxName = {
  __typename?: 'PaxName';
  PassengerFirstName: Scalars['String'];
  PassengerLastName: Scalars['String'];
  PassengerTitle: Scalars['String'];
};

/** Payment beneficiary on Shoppt. */
export type PaymentBeneficiary = {
  __typename?: 'PaymentBeneficiary';
  /** When the payment request was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The metadata */
  metadata: BeneficiaryMetadata;
  /** The attached shpt user */
  shpt_user?: Maybe<User>;
  /** The beneficiary type */
  type: Scalars['String'];
  /** When the payment request was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Payment request on Shoppt. */
export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  /** The request amount */
  amount: Scalars['Float'];
  /** When the payment request was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The request naration */
  narration: Scalars['String'];
  /** The receiver attached user */
  receiver_user?: Maybe<User>;
  /** The request status. Can be 'waiting','approved','declined', 'canceled' */
  status: Scalars['String'];
  /** When the payment request was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

export enum PaymentStatus {
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID'
}

/** Point category on Shoppt. */
export type PointCategory = {
  __typename?: 'PointCategory';
  /** The cap amount */
  cap_amount: Scalars['Float'];
  /** The cost of a point */
  cash_per_point: Scalars['Float'];
  /** When the point category was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The attached product category */
  product_category: ProductCategory;
  /** The attached subscription plan */
  subscription_plan: SubscriptionPlan;
  /** When the point category was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type PointEarned = {
  __typename?: 'PointEarned';
  /** The claim status of the earning */
  claimed: Scalars['Boolean'];
  /** When the point earning was created. */
  created_at: Scalars['DateTime'];
  /** The earning description */
  description: Scalars['String'];
  /** The entity uuid */
  entity_uuid?: Maybe<Scalars['String']>;
  /** The earning event slug */
  event_slug: Scalars['String'];
  /** The expiry date and time */
  expire_at: Scalars['String'];
  /** The game point amount involved */
  game_points: Scalars['Float'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The money amount involved */
  money_amount: Scalars['Float'];
  /** The attached order */
  order?: Maybe<Order>;
  /** The point amount involved */
  points: Scalars['Float'];
  /** The processed date and time */
  processed_at: Scalars['String'];
  /** The processing status of the earning */
  processing_status: Scalars['String'];
  /** The attached sale */
  sale?: Maybe<Sale>;
  /** The attached receipt */
  scanned_receipt?: Maybe<ScannedReceipt>;
  /** The attached transaction */
  transaction?: Maybe<Transaction>;
  /** When the point earning was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A paginated list of PointEarned items. */
export type PointEarnedPaginator = {
  __typename?: 'PointEarnedPaginator';
  /** A list of PointEarned items. */
  data: Array<PointEarned>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Point transaction on Shoppt. */
export type PointTransaction = {
  __typename?: 'PointTransaction';
  /** The transaction amount */
  amount: Scalars['Float'];
  /** The ID that entity that generated the transaction */
  charge_id?: Maybe<Scalars['String']>;
  /** The type of entity that generated the transaction */
  chargeable_type?: Maybe<Scalars['String']>;
  /** When the transaction was created. */
  created_at: Scalars['DateTime'];
  /** The transaction description or narration */
  description?: Maybe<Scalars['String']>;
  /** The type of transaction. Can be debit or credit */
  dr_or_cr: Scalars['String'];
  /** Extra custom data attached to the transaction */
  extra_data?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The point balance before the transaction */
  point_balance: Scalars['Float'];
  /** The transaction unique reference */
  reference?: Maybe<Scalars['String']>;
  /** The transaction status. Can be success, failed, pending */
  status: Scalars['String'];
  /** When the transaction was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
  /** The attached wallet */
  wallet: Wallet;
};

/** A paginated list of PointTransaction items. */
export type PointTransactionPaginator = {
  __typename?: 'PointTransactionPaginator';
  /** A list of PointTransaction items. */
  data: Array<PointTransaction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** The user points summary */
export type PointsSummary = {
  __typename?: 'PointsSummary';
  next_redemption_date: Scalars['String'];
  points_claimed: Scalars['Float'];
  points_to_redeem: Scalars['String'];
};

export type PolicyPurchaseHistory = {
  __typename?: 'PolicyPurchaseHistory';
  activation_date?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  policy_id: Scalars['String'];
  price: Scalars['Float'];
  purchased_on: Scalars['String'];
  start_date?: Maybe<Scalars['String']>;
};

/** Poll summary */
export type PollSummary = {
  __typename?: 'PollSummary';
  count: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
  slug: Scalars['String'];
  value: Scalars['String'];
};

/** Pricing on Shoppt. */
export type Pricing = {
  __typename?: 'Pricing';
  /** The pricing amount */
  amount: Scalars['String'];
  /** When the pricing was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The pricing product id */
  product_id: Scalars['String'];
  /** The pricing supported areas id */
  supported_areas_id: Scalars['String'];
  /** When the pricing was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Products on Shoppt. */
export type Product = {
  __typename?: 'Product';
  /** The cost for each point gain from product sales */
  cash_per_point: Scalars['Float'];
  /** Product code */
  code: Scalars['String'];
  /** When the product was created. */
  created_at: Scalars['DateTime'];
  /** The worth of the attached deal */
  deal_amount: Scalars['String'];
  /** The type of deal attached to a product. Can be point_gain, discount */
  deal_type: Scalars['String'];
  /** Product description */
  description: Scalars['String'];
  /** Description Meta */
  description_meta?: Maybe<Scalars['String']>;
  /** The product gallery images */
  gallery_images?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The product image */
  image_url?: Maybe<Scalars['String']>;
  /** The ID of the merchant that owns the product */
  merchant_id?: Maybe<Scalars['Int']>;
  /** The name of the merchant that owns the product */
  merchant_name?: Maybe<Scalars['String']>;
  /** The unique ID or code given to the product by the merchant */
  merchant_product_code?: Maybe<Scalars['String']>;
  /** The product metadata */
  metadata?: Maybe<Scalars['String']>;
  /** Product name */
  name: Scalars['String'];
  /** The point to gain if bought complete with cash */
  point_gain: Scalars['Float'];
  /** The product price */
  price: Scalars['String'];
  /** Product category */
  product_category: ProductCategory;
  /** purchase_data */
  purchase_data?: Maybe<Scalars['String']>;
  /** The product status. can be active or inactive */
  status: Scalars['String'];
  /** When the product was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
  /** Product vendor */
  vendor?: Maybe<Vendor>;
};

/** Product categories on shoppt. */
export type ProductCategory = {
  __typename?: 'ProductCategory';
  /** When the category was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Category image */
  image_url?: Maybe<Scalars['String']>;
  /** Category name */
  name: Scalars['String'];
  /** Category slug */
  slug: Scalars['String'];
  /** When the category was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Product delivery location */
export type ProductDeliveryLocation = {
  __typename?: 'ProductDeliveryLocation';
  /** The delivery location */
  delivery_location?: Maybe<DeliveryLocation>;
  /** The delivery_locations_id */
  delivery_locations_id: Scalars['Int'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The product_id */
  product_id: Scalars['Int'];
};

export type ProductDeliveryLocationResponse = {
  __typename?: 'ProductDeliveryLocationResponse';
  cost: Scalars['String'];
  delivery_location_id: Scalars['Int'];
  product_id: Scalars['Int'];
};

export type ProductMediaInput = {
  attachment?: InputMaybe<Scalars['Upload']>;
  uuid: Scalars['String'];
};

/** A paginated list of Product items. */
export type ProductPaginator = {
  __typename?: 'ProductPaginator';
  /** A list of Product items. */
  data: Array<Product>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ProductRewardSettings = {
  percentage: Scalars['String'];
  quantity: Scalars['String'];
  type: RewardType;
};

/** User Variant Shoppt. */
export type ProductVariant = {
  __typename?: 'ProductVariant';
  /** The base image url */
  base_image_url?: Maybe<Scalars['String']>;
  /** When the reward was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The vairiant image */
  image_url?: Maybe<Scalars['String']>;
  /** The variant stock quantity */
  inventory_quantity: Scalars['Int'];
  /** The availability status */
  is_available?: Maybe<Scalars['Boolean']>;
  /** The name */
  name?: Maybe<Scalars['String']>;
  /** The price */
  price?: Maybe<Scalars['String']>;
  /** The variant product */
  product?: Maybe<ShoplistProduct>;
  /** The ID of the product */
  product_id: Scalars['Int'];
  /** The variant selected options */
  selected_options?: Maybe<Array<ProductVariantOption>>;
  /** The sku */
  sku?: Maybe<Scalars['String']>;
  /** When the reward was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique uuid. */
  uuid: Scalars['String'];
  /** The weight */
  weight?: Maybe<Scalars['String']>;
};

/** Product Variant Options */
export type ProductVariantOption = {
  __typename?: 'ProductVariantOption';
  /** The option name */
  name: Scalars['String'];
  /** The option value */
  value: Scalars['String'];
};

export type Products = {
  amount?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  monthsPaidFor?: InputMaybe<Scalars['String']>;
  phone_no?: InputMaybe<Scalars['String']>;
  purchase_data?: InputMaybe<Scalars['String']>;
};

/** The profile of a user on the application */
export type Profile = {
  __typename?: 'Profile';
  /** User account level */
  account_tier_level: Scalars['Int'];
  /** User address */
  address?: Maybe<Scalars['String']>;
  /** Business data */
  business?: Maybe<Business>;
  /** User city */
  city?: Maybe<Scalars['String']>;
  /** The point claimed by user */
  claimed_earning_periods?: Maybe<Scalars['String']>;
  /** User country */
  country?: Maybe<Scalars['String']>;
  /** When the profile was created. */
  created_at: Scalars['DateTime'];
  /** User date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** User gender */
  gender?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Insurance profile */
  insurance_profile?: Maybe<InsuranceProfile>;
  /** User last seen date */
  last_seen?: Maybe<Scalars['DateTime']>;
  /** User profile picture url */
  photo_url?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  /** User authority pin status */
  pin_is_set: Scalars['Boolean'];
  /** User region */
  region: Region;
  /** User savings target amount */
  savings_target_amount?: Maybe<Scalars['String']>;
  /** The snapping policy status */
  snapping_terms_accepted: Scalars['Boolean'];
  /** User spend group */
  spend_group?: Maybe<Scalars['String']>;
  /** User subscription plan ID */
  subscription_plan: SubscriptionPlan;
  /** User account type. It can be client, admin, super_admin */
  type: Scalars['String'];
  /** When the profile was last updated. */
  updated_at: Scalars['DateTime'];
  /** The usage policy status */
  usage_policy_accepted: Scalars['Boolean'];
  /** The user that owns this profile */
  user: User;
  /** User account verification status */
  user_verified: Scalars['Boolean'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** User device push notification setup on Shoppt. */
export type PushNotification = {
  __typename?: 'PushNotification';
  /** When the push notification was created. */
  created_at: Scalars['DateTime'];
  /** The unique push notification device token */
  device_token: Scalars['String'];
  /** The user device type. It can be web, ios, android */
  device_type: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** When the push notification was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Get client app contents */
  AppContent: AppContent;
  /** Get charges access code for card payment */
  ChargeAccessCode: Scalars['String'];
  /** Get badges to be acknowledged */
  CheckGameStatus: Array<Scalars['Int']>;
  /** Get all commercial banks */
  CommercialBanks: Array<CommercialBank>;
  /** Get a single flight booking */
  FlightBooking?: Maybe<FlightBooking>;
  /** Get all account levels */
  GetAccountLevels: Array<AccountLevel>;
  /** Get ad demographies */
  GetAdDemographies: Array<AdDemography>;
  /** Get many advert */
  GetAdverts?: Maybe<AdvertPaginator>;
  /** Get products */
  GetAllProducts?: Maybe<ProductPaginator>;
  /** Get applied fund request */
  GetAppliedRefundRequest: AppliedRefundResponse;
  /** Get the current authenticated user */
  GetAuthUser?: Maybe<User>;
  /** Get Brands by Category */
  GetBrandsByCategory?: Maybe<BusinessPaginator>;
  GetBusinessAdmin?: Maybe<BusinessAdminPaginator>;
  /** Get merchant dashboard summary */
  GetBusinessDashboardSummary: BusinessDashboardSummary;
  /** Get business orders */
  GetBusinessOrders?: Maybe<OrderPaginator>;
  /** Get business profile */
  GetBusinessProfile?: Maybe<Business>;
  /** Get user cart */
  GetCart: Array<ShoplistCart>;
  /** Get categories */
  GetCategories: Array<Category>;
  /** Get categories with products */
  GetCategoriesWithProducts: Array<Category>;
  /** Get single challenge */
  GetChallenge?: Maybe<Challenge>;
  /** Get challenge leaderboard */
  GetChallengeLeaderboard: Array<LeaderboardUser>;
  /** Get all challenges */
  GetChallenges: Array<Challenge>;
  /** Get Collection Conversation  */
  GetCollectionConversations: Array<Conversation>;
  /** Get Collection Message */
  GetCollectionMessages: Array<Message>;
  /** Get Collections by Category */
  GetCollectionsByCategory?: Maybe<CollectionPaginator>;
  /** Get color list */
  GetColorLists: Array<Scalars['String']>;
  /** Get countries */
  GetCountries: Array<Scalars['String']>;
  /** Get customer insurance claims */
  GetCustomerClaims: Array<InsuranceClaim>;
  /** Get delivery locations */
  GetDeliveryLocation?: Maybe<DeliveryLocationPaginator>;
  GetEarningsStatistics: Scalars['String'];
  /** Get User Enrolled Card Transactions */
  GetEnrolledCardTransations?: Maybe<EnrolledCardTransactionPaginator>;
  /** Get user expenses analysis */
  GetExpensesAnalysis: Array<DataDate>;
  /** Get Favorite Collections  */
  GetFavoriteCollections: Array<Favorite>;
  /** Get fare rules */
  GetFlightFareRules: Array<SingleFlightFareRules>;
  /** Get gender */
  GetGenders: Array<Scalars['String']>;
  /** Get leaderboard user. Filter type can be day, week or month */
  GetGlobalLeaderBoard: Array<LeaderboardUser>;
  /** Get HMO Hospitals */
  GetHMOHospitals: Array<HmoHospital>;
  /** Get identification */
  GetIdentificationTypes: Array<Scalars['String']>;
  /** Get image thumbnail */
  GetImageThumbnail: Scalars['String'];
  GetInstagramAuthUrl: Scalars['String'];
  /** Get all insurance products */
  GetInsuranceProducts: Array<InsuranceProduct>;
  /** Get latest exchange rates */
  GetLatestExchangeRates: Array<ExchangeRate>;
  /** Get local areas */
  GetLocalGovAreas: Array<Scalars['String']>;
  /** Get manufacture years */
  GetManufactureYears: Array<Scalars['String']>;
  /** Get mastercard token */
  GetMastercardAuthToken: Scalars['String'];
  /** Get media */
  GetMedia?: Maybe<MediaContentPaginator>;
  GetNotifications?: Maybe<NotificationPaginator>;
  /** Get user Order */
  GetOrders: Array<Order>;
  /** Get package dimensions */
  GetPackageDimension: Array<PackageDimension>;
  /** Get user payment beneficiaries */
  GetPaymentBeneficiaries: Array<PaymentBeneficiary>;
  /** Get user point gained */
  GetPointEarned?: Maybe<PointEarnedPaginator>;
  /** Get Point Transactions By Wallet Id */
  GetPointTransactionsByWalletId?: Maybe<PointTransactionPaginator>;
  /** Get shoppoint product */
  GetProduct?: Maybe<Product>;
  /** Get product beneficiaries */
  GetProductBeneficiaries: Array<Beneficiary>;
  /** Get product categories */
  GetProductCategories: Array<ProductCategory>;
  /** Get product recommendation */
  GetProductRecommendation: RecommendationResponse;
  /** Get Shoppoint products */
  GetProducts?: Maybe<ProductPaginator>;
  /** Get Products by Category */
  GetProductsByCategory?: Maybe<ShoplistProductPaginator>;
  /** Get quote for refund */
  GetQuoteforrefund: Quoteforrefund;
  /** All regions */
  GetRegions: Array<Region>;
  /** Get single scanned receipt */
  GetScannedReceipt?: Maybe<ScannedReceipt>;
  /** Get shipping category */
  GetShippingCategory: Array<ShippingCategory>;
  /** Get Shopify collections */
  GetShopifyCollections: ShopifyCollectionConnection;
  /** Get Shopify products */
  GetShopifyProducts: ShopifyProductConnection;
  /** Get Shoplist Brands */
  GetShoplistBrands?: Maybe<BusinessPaginator>;
  /** Get Single Shoplist collection */
  GetShoplistCollection?: Maybe<Collection>;
  /** Get Shoplist Collections */
  GetShoplistCollections?: Maybe<CollectionPaginator>;
  /** Get Single Shoplist product */
  GetShoplistProduct?: Maybe<ShoplistProduct>;
  /** Get Shoplist Product Categories */
  GetShoplistProductCategories?: Maybe<CategoryPaginator>;
  /** Get Shoplist products */
  GetShoplistProducts?: Maybe<ShoplistProductPaginator>;
  /** Get Shoplist Rewards */
  GetShoplistRewards: Array<ShoplistReward>;
  /** Get Similar Products */
  GetSimilarProducts: Array<ShoplistProduct>;
  /** Get single advert */
  GetSingleAdvert?: Maybe<Advert>;
  /** Get single insurance product */
  GetSingleInsuranceProducts?: Maybe<InsuranceProduct>;
  /** Get spending categories */
  GetSpendingCategories: Array<SpendingCategory>;
  /** Get all subscription plans on shoppoint */
  GetSubscriptionPlans: Array<SubscriptionPlan>;
  /** Get support areas */
  GetSupportedAreas: Array<SupportedArea>;
  /** Get title */
  GetTitles: Array<Scalars['String']>;
  /** Get Transactions By Wallet Id */
  GetTransactionsByWalletId?: Maybe<TransactionPaginator>;
  /** Get trip details */
  GetTripDetails: TripDetails;
  /** Get unclaimed point earned count */
  GetUnClaimedPointEarnedCount: Scalars['Int'];
  /** Get unread notifications count */
  GetUnreadNotificationsCount: Scalars['Int'];
  /** Get User Cart */
  GetUserCart: Collection;
  /** Get user contents */
  GetUserContents: UserContent;
  /** Get User Enrolled Cards */
  GetUserEnrolledCards: Array<EnrolledCard>;
  /** Get user flight bookings */
  GetUserFlightBookings?: Maybe<FlightBookingPaginator>;
  /** Get user game profile */
  GetUserGameProfile?: Maybe<GameProfile>;
  GetUserInstagramMedia: InstagramMediaData;
  /** Get user insurance policy by type */
  GetUserInsurancePolicy: Array<InsurancePolicy>;
  /** Get auth user payment requests */
  GetUserPaymentRequests: Array<PaymentRequest>;
  /** Get the current authenticated user profile */
  GetUserProfile?: Maybe<Profile>;
  /** Get User Wallet */
  GetUserWallet?: Maybe<Wallet>;
  /** Get vehicle brands */
  GetVehicleBrands: Array<Scalars['String']>;
  /** Get vehicle models */
  GetVehicleModel: Array<Scalars['String']>;
  /** Get vehicle type */
  GetVehicleTypes: Array<Scalars['String']>;
  /** Get single vendor */
  GetVendor?: Maybe<Vendor>;
  /** Get Woocommerce auth url */
  GetWoocommerceAuthUrl: Scalars['String'];
  /** Get WooCommerce products */
  GetWoocommerceProducts: WoocommerceResponse;
  /** Get merchant categories */
  MerchantByCategories: Array<MerchantCategory>;
  /** Get a single point earned */
  PointEarned?: Maybe<PointEarned>;
  /** Get auth user payment requests */
  PointTransaction?: Maybe<PointTransaction>;
  /** Search airport */
  SearchAirports: Array<Airports>;
  /** Search for brands */
  SearchBrands?: Maybe<BusinessPaginator>;
  /** Search for collections */
  SearchCollections?: Maybe<CollectionPaginator>;
  /** Search flight */
  SearchFlight: Array<SingleFlight>;
  /** Search Shoppoint products */
  SearchProducts?: Maybe<ProductPaginator>;
  /** Search for products */
  SearchShoplistProducts?: Maybe<ShoplistProductPaginator>;
  /** Search shoopoint users */
  SearchUser: Array<User>;
  /** Get single transaction details */
  Transaction?: Maybe<Transaction>;
  /** Get trending products */
  TrendingProducts: Array<Product>;
};


export type QueryAppContentArgs = {
  app_type?: InputMaybe<Scalars['String']>;
};


export type QueryFlightBookingArgs = {
  reference: Scalars['String'];
};


export type QueryGetAdvertsArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryGetAdvertsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetAdvertsWhereWhereConditions>;
};


export type QueryGetAllProductsArgs = {
  first: Scalars['Int'];
  hasProductCategory?: InputMaybe<QueryGetAllProductsHasProductCategoryWhereHasConditions>;
  orderBy?: InputMaybe<Array<QueryGetAllProductsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetAllProductsWhereWhereConditions>;
};


export type QueryGetAppliedRefundRequestArgs = {
  booking_reference: Scalars['String'];
};


export type QueryGetBrandsByCategoryArgs = {
  category_id: Scalars['Int'];
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBusinessAdminArgs = {
  first: Scalars['Int'];
  hasBusiness?: InputMaybe<QueryGetBusinessAdminHasBusinessWhereHasConditions>;
  orderBy?: InputMaybe<Array<QueryGetBusinessAdminOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetBusinessAdminWhereWhereConditions>;
};


export type QueryGetBusinessOrdersArgs = {
  business_profile_id: Scalars['Int'];
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryGetBusinessOrdersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetBusinessOrdersWhereWhereConditions>;
};


export type QueryGetBusinessProfileArgs = {
  uuid: Scalars['String'];
};


export type QueryGetChallengeArgs = {
  uuid: Scalars['String'];
};


export type QueryGetChallengeLeaderboardArgs = {
  challenge_uuid: Scalars['String'];
};


export type QueryGetCollectionConversationsArgs = {
  collection_uuid: Scalars['String'];
};


export type QueryGetCollectionMessagesArgs = {
  collection_uuid: Scalars['String'];
};


export type QueryGetCollectionsByCategoryArgs = {
  category_id: Scalars['Int'];
  filter?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCustomerClaimsArgs = {
  customer_id: Scalars['String'];
};


export type QueryGetDeliveryLocationArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryGetDeliveryLocationOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetDeliveryLocationWhereWhereConditions>;
};


export type QueryGetEnrolledCardTransationsArgs = {
  enrolled_card_id: Scalars['Int'];
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetExpensesAnalysisArgs = {
  back_count: Scalars['Int'];
  end_date: Scalars['String'];
  filter_type: Scalars['String'];
  period: FilterPeriods;
  start_date: Scalars['String'];
  sub_type?: InputMaybe<Scalars['String']>;
};


export type QueryGetFavoriteCollectionsArgs = {
  collection_uuid: Scalars['String'];
};


export type QueryGetFlightFareRulesArgs = {
  booking_reference?: InputMaybe<Scalars['String']>;
  fare_source_code?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export type QueryGetGlobalLeaderBoardArgs = {
  filter_type: Scalars['String'];
};


export type QueryGetImageThumbnailArgs = {
  image_url: Scalars['String'];
};


export type QueryGetLatestExchangeRatesArgs = {
  base_currency: Scalars['String'];
};


export type QueryGetMediaArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetMediaWhereWhereConditions>;
};


export type QueryGetNotificationsArgs = {
  first: Scalars['Int'];
  hasUser?: InputMaybe<QueryGetNotificationsHasUserWhereHasConditions>;
  orderBy?: InputMaybe<Array<QueryGetNotificationsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetNotificationsWhereWhereConditions>;
};


export type QueryGetPointEarnedArgs = {
  first: Scalars['Int'];
  hasUser?: InputMaybe<QueryGetPointEarnedHasUserWhereHasConditions>;
  orderBy?: InputMaybe<Array<QueryGetPointEarnedOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetPointEarnedWhereWhereConditions>;
};


export type QueryGetPointTransactionsByWalletIdArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryGetPointTransactionsByWalletIdOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  wallet_id: Scalars['Int'];
};


export type QueryGetProductArgs = {
  uuid: Scalars['String'];
};


export type QueryGetProductBeneficiariesArgs = {
  product_category_uuid: Scalars['String'];
};


export type QueryGetProductRecommendationArgs = {
  category: Scalars['String'];
  sub_category?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  first: Scalars['Int'];
  hasProductCategory?: InputMaybe<QueryGetProductsHasProductCategoryWhereHasConditions>;
  orderBy?: InputMaybe<Array<QueryGetProductsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<QueryGetProductsWhereWhereConditions>;
};


export type QueryGetProductsByCategoryArgs = {
  category_id: Scalars['Int'];
  filter?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetQuoteforrefundArgs = {
  quote_id: Scalars['String'];
};


export type QueryGetScannedReceiptArgs = {
  uuid: Scalars['String'];
};


export type QueryGetShopifyCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGetShopifyProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGetShoplistBrandsArgs = {
  first: Scalars['Int'];
  is_brand?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetShoplistBrandsWhereWhereConditions>;
};


export type QueryGetShoplistCollectionArgs = {
  uuid: Scalars['String'];
};


export type QueryGetShoplistCollectionsArgs = {
  first: Scalars['Int'];
  hasBusiness?: InputMaybe<QueryGetShoplistCollectionsHasBusinessWhereHasConditions>;
  hasProfile?: InputMaybe<QueryGetShoplistCollectionsHasProfileWhereHasConditions>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<QueryGetShoplistCollectionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetShoplistCollectionsWhereWhereConditions>;
};


export type QueryGetShoplistProductArgs = {
  uuid: Scalars['String'];
};


export type QueryGetShoplistProductCategoriesArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryGetShoplistProductCategoriesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetShoplistProductCategoriesWhereWhereConditions>;
};


export type QueryGetShoplistProductsArgs = {
  affiliateable?: InputMaybe<Scalars['Boolean']>;
  first: Scalars['Int'];
  hasCategory?: InputMaybe<QueryGetShoplistProductsHasCategoryWhereHasConditions>;
  orderBy?: InputMaybe<Array<QueryGetShoplistProductsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryGetShoplistProductsWhereWhereConditions>;
};


export type QueryGetSimilarProductsArgs = {
  product_uuid: Scalars['String'];
};


export type QueryGetSingleAdvertArgs = {
  uuid: Scalars['String'];
};


export type QueryGetSingleInsuranceProductsArgs = {
  product_id: Scalars['String'];
};


export type QueryGetTransactionsByWalletIdArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryGetTransactionsByWalletIdOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  wallet_id: Scalars['Int'];
};


export type QueryGetTripDetailsArgs = {
  booking_reference: Scalars['String'];
};


export type QueryGetUserContentsArgs = {
  business_uuid?: InputMaybe<Scalars['String']>;
  content_type: ContentType;
  enforced_content_type?: InputMaybe<Scalars['String']>;
  enforced_content_uuid?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  per_page: Scalars['Int'];
  with_product?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetUserEnrolledCardsArgs = {
  user_id: Scalars['Int'];
};


export type QueryGetUserFlightBookingsArgs = {
  first: Scalars['Int'];
  hasUser?: InputMaybe<QueryGetUserFlightBookingsHasUserWhereHasConditions>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUserInstagramMediaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserInsurancePolicyArgs = {
  insurance_type: Scalars['String'];
};


export type QueryGetUserWalletArgs = {
  type: Scalars['String'];
};


export type QueryGetVehicleBrandsArgs = {
  manufacture_year: Scalars['String'];
};


export type QueryGetVehicleModelArgs = {
  brand: Scalars['String'];
  manufacture_year: Scalars['String'];
};


export type QueryGetVendorArgs = {
  merchant_name: Scalars['String'];
};


export type QueryGetWoocommerceAuthUrlArgs = {
  store_url: Scalars['String'];
};


export type QueryGetWoocommerceProductsArgs = {
  page?: InputMaybe<Scalars['String']>;
  per_page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryPointEarnedArgs = {
  uuid: Scalars['String'];
};


export type QueryPointTransactionArgs = {
  uuid: Scalars['String'];
};


export type QuerySearchAirportsArgs = {
  query: Scalars['String'];
};


export type QuerySearchBrandsArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySearchCollectionsArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySearchFlightArgs = {
  input: SearchFlightRequest;
};


export type QuerySearchProductsArgs = {
  first: Scalars['Int'];
  hasProductCategory?: InputMaybe<QuerySearchProductsHasProductCategoryWhereHasConditions>;
  orderBy?: InputMaybe<Array<QuerySearchProductsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<QuerySearchProductsWhereWhereConditions>;
};


export type QuerySearchShoplistProductsArgs = {
  first: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type QuerySearchUserArgs = {
  query: Scalars['String'];
};


export type QueryTransactionArgs = {
  uuid: Scalars['String'];
};

/** Allowed column names for Query.GetAdverts.orderBy. */
export enum QueryGetAdvertsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetAdverts.orderBy. */
export type QueryGetAdvertsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetAdvertsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetAdverts.where. */
export enum QueryGetAdvertsWhereColumn {
  Title = 'TITLE',
  UserId = 'USER_ID',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetAdverts`. */
export type QueryGetAdvertsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetAdvertsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetAdvertsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetAdvertsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetAdvertsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetAdverts`. */
export type QueryGetAdvertsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetAdvertsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetAllProducts.hasProductCategory. */
export enum QueryGetAllProductsHasProductCategoryColumn {
  Name = 'NAME',
  Slug = 'SLUG',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasProductCategory` argument on the query `GetAllProducts`. */
export type QueryGetAllProductsHasProductCategoryWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetAllProductsHasProductCategoryWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetAllProductsHasProductCategoryWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetAllProductsHasProductCategoryWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetAllProductsHasProductCategoryColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasProductCategory` argument on the query `GetAllProducts`. */
export type QueryGetAllProductsHasProductCategoryWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetAllProductsHasProductCategoryWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetAllProducts.orderBy. */
export enum QueryGetAllProductsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetAllProducts.orderBy. */
export type QueryGetAllProductsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetAllProductsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetAllProducts.where. */
export enum QueryGetAllProductsWhereColumn {
  Code = 'CODE',
  MerchantName = 'MERCHANT_NAME',
  Name = 'NAME',
  Source = 'SOURCE',
  Status = 'STATUS'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetAllProducts`. */
export type QueryGetAllProductsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetAllProductsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetAllProductsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetAllProductsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetAllProductsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetAllProducts`. */
export type QueryGetAllProductsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetAllProductsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetBusinessAdmin.hasBusiness. */
export enum QueryGetBusinessAdminHasBusinessColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasBusiness` argument on the query `GetBusinessAdmin`. */
export type QueryGetBusinessAdminHasBusinessWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetBusinessAdminHasBusinessWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetBusinessAdminHasBusinessWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetBusinessAdminHasBusinessWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetBusinessAdminHasBusinessColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasBusiness` argument on the query `GetBusinessAdmin`. */
export type QueryGetBusinessAdminHasBusinessWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetBusinessAdminHasBusinessWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetBusinessAdmin.orderBy. */
export enum QueryGetBusinessAdminOrderByColumn {
  CreatedAt = 'CREATED_AT'
}

/** Order by clause for Query.GetBusinessAdmin.orderBy. */
export type QueryGetBusinessAdminOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetBusinessAdminOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetBusinessAdmin.where. */
export enum QueryGetBusinessAdminWhereColumn {
  Id = 'ID',
  InvitationAcceptedAt = 'INVITATION_ACCEPTED_AT'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetBusinessAdmin`. */
export type QueryGetBusinessAdminWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetBusinessAdminWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetBusinessAdminWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetBusinessAdminWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetBusinessAdminWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetBusinessAdmin`. */
export type QueryGetBusinessAdminWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetBusinessAdminWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetBusinessOrders.orderBy. */
export enum QueryGetBusinessOrdersOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetBusinessOrders.orderBy. */
export type QueryGetBusinessOrdersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetBusinessOrdersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetBusinessOrders.where. */
export enum QueryGetBusinessOrdersWhereColumn {
  PaymentStatus = 'PAYMENT_STATUS',
  Status = 'STATUS'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetBusinessOrders`. */
export type QueryGetBusinessOrdersWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetBusinessOrdersWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetBusinessOrdersWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetBusinessOrdersWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetBusinessOrdersWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetBusinessOrders`. */
export type QueryGetBusinessOrdersWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetBusinessOrdersWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetDeliveryLocation.orderBy. */
export enum QueryGetDeliveryLocationOrderByColumn {
  CreatedAt = 'CREATED_AT'
}

/** Order by clause for Query.GetDeliveryLocation.orderBy. */
export type QueryGetDeliveryLocationOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetDeliveryLocationOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetDeliveryLocation.where. */
export enum QueryGetDeliveryLocationWhereColumn {
  BusinessProfileId = 'BUSINESS_PROFILE_ID',
  UserId = 'USER_ID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetDeliveryLocation`. */
export type QueryGetDeliveryLocationWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetDeliveryLocationWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetDeliveryLocationWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetDeliveryLocationWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetDeliveryLocationWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetDeliveryLocation`. */
export type QueryGetDeliveryLocationWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetDeliveryLocationWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetMedia.where. */
export enum QueryGetMediaWhereColumn {
  MediaId = 'MEDIA_ID',
  MediaMicroservice = 'MEDIA_MICROSERVICE',
  MediaService = 'MEDIA_SERVICE',
  Type = 'TYPE',
  UserId = 'USER_ID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetMedia`. */
export type QueryGetMediaWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetMediaWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetMediaWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetMediaWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetMediaWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetMedia`. */
export type QueryGetMediaWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetMediaWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetNotifications.hasUser. */
export enum QueryGetNotificationsHasUserColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasUser` argument on the query `GetNotifications`. */
export type QueryGetNotificationsHasUserWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetNotificationsHasUserWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetNotificationsHasUserWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetNotificationsHasUserWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetNotificationsHasUserColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasUser` argument on the query `GetNotifications`. */
export type QueryGetNotificationsHasUserWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetNotificationsHasUserWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetNotifications.orderBy. */
export enum QueryGetNotificationsOrderByColumn {
  CreatedAt = 'CREATED_AT'
}

/** Order by clause for Query.GetNotifications.orderBy. */
export type QueryGetNotificationsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetNotificationsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetNotifications.where. */
export enum QueryGetNotificationsWhereColumn {
  UserId = 'USER_ID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetNotifications`. */
export type QueryGetNotificationsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetNotificationsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetNotificationsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetNotificationsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetNotificationsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetNotifications`. */
export type QueryGetNotificationsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetNotificationsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetPointEarned.hasUser. */
export enum QueryGetPointEarnedHasUserColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasUser` argument on the query `GetPointEarned`. */
export type QueryGetPointEarnedHasUserWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetPointEarnedHasUserWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetPointEarnedHasUserWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetPointEarnedHasUserWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetPointEarnedHasUserColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasUser` argument on the query `GetPointEarned`. */
export type QueryGetPointEarnedHasUserWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetPointEarnedHasUserWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetPointEarned.orderBy. */
export enum QueryGetPointEarnedOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetPointEarned.orderBy. */
export type QueryGetPointEarnedOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetPointEarnedOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetPointEarned.where. */
export enum QueryGetPointEarnedWhereColumn {
  CreatedAt = 'CREATED_AT',
  EventSlug = 'EVENT_SLUG',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetPointEarned`. */
export type QueryGetPointEarnedWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetPointEarnedWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetPointEarnedWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetPointEarnedWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetPointEarnedWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetPointEarned`. */
export type QueryGetPointEarnedWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetPointEarnedWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetPointTransactionsByWalletId.orderBy. */
export enum QueryGetPointTransactionsByWalletIdOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetPointTransactionsByWalletId.orderBy. */
export type QueryGetPointTransactionsByWalletIdOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetPointTransactionsByWalletIdOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetProducts.hasProductCategory. */
export enum QueryGetProductsHasProductCategoryColumn {
  Name = 'NAME',
  Slug = 'SLUG',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasProductCategory` argument on the query `GetProducts`. */
export type QueryGetProductsHasProductCategoryWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetProductsHasProductCategoryWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetProductsHasProductCategoryWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetProductsHasProductCategoryWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetProductsHasProductCategoryColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasProductCategory` argument on the query `GetProducts`. */
export type QueryGetProductsHasProductCategoryWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetProductsHasProductCategoryWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetProducts.orderBy. */
export enum QueryGetProductsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetProducts.orderBy. */
export type QueryGetProductsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetProductsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetProducts.where. */
export enum QueryGetProductsWhereColumn {
  Code = 'CODE',
  Name = 'NAME',
  Status = 'STATUS'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetProducts`. */
export type QueryGetProductsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetProductsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetProductsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetProductsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetProductsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetProducts`. */
export type QueryGetProductsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetProductsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistBrands.where. */
export enum QueryGetShoplistBrandsWhereColumn {
  BusinessName = 'BUSINESS_NAME',
  BusinessTag = 'BUSINESS_TAG',
  IsBrand = 'IS_BRAND'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetShoplistBrands`. */
export type QueryGetShoplistBrandsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistBrandsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistBrandsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistBrandsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistBrandsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetShoplistBrands`. */
export type QueryGetShoplistBrandsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistBrandsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistCollections.hasBusiness. */
export enum QueryGetShoplistCollectionsHasBusinessColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasBusiness` argument on the query `GetShoplistCollections`. */
export type QueryGetShoplistCollectionsHasBusinessWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistCollectionsHasBusinessWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistCollectionsHasBusinessWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistCollectionsHasBusinessWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistCollectionsHasBusinessColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasBusiness` argument on the query `GetShoplistCollections`. */
export type QueryGetShoplistCollectionsHasBusinessWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistCollectionsHasBusinessWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistCollections.hasProfile. */
export enum QueryGetShoplistCollectionsHasProfileColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasProfile` argument on the query `GetShoplistCollections`. */
export type QueryGetShoplistCollectionsHasProfileWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistCollectionsHasProfileWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistCollectionsHasProfileWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistCollectionsHasProfileWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistCollectionsHasProfileColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasProfile` argument on the query `GetShoplistCollections`. */
export type QueryGetShoplistCollectionsHasProfileWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistCollectionsHasProfileWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistCollections.orderBy. */
export enum QueryGetShoplistCollectionsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetShoplistCollections.orderBy. */
export type QueryGetShoplistCollectionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetShoplistCollectionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetShoplistCollections.where. */
export enum QueryGetShoplistCollectionsWhereColumn {
  BusinessProfileId = 'BUSINESS_PROFILE_ID',
  Name = 'NAME',
  ProfileId = 'PROFILE_ID',
  SubBusinessId = 'SUB_BUSINESS_ID',
  Type = 'TYPE',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetShoplistCollections`. */
export type QueryGetShoplistCollectionsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistCollectionsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistCollectionsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistCollectionsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistCollectionsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetShoplistCollections`. */
export type QueryGetShoplistCollectionsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistCollectionsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistProductCategories.orderBy. */
export enum QueryGetShoplistProductCategoriesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetShoplistProductCategories.orderBy. */
export type QueryGetShoplistProductCategoriesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetShoplistProductCategoriesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetShoplistProductCategories.where. */
export enum QueryGetShoplistProductCategoriesWhereColumn {
  Name = 'NAME',
  ParentCategoryId = 'PARENT_CATEGORY_ID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetShoplistProductCategories`. */
export type QueryGetShoplistProductCategoriesWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistProductCategoriesWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistProductCategoriesWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistProductCategoriesWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistProductCategoriesWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetShoplistProductCategories`. */
export type QueryGetShoplistProductCategoriesWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistProductCategoriesWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistProducts.hasCategory. */
export enum QueryGetShoplistProductsHasCategoryColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasCategory` argument on the query `GetShoplistProducts`. */
export type QueryGetShoplistProductsHasCategoryWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistProductsHasCategoryWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistProductsHasCategoryWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistProductsHasCategoryWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistProductsHasCategoryColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasCategory` argument on the query `GetShoplistProducts`. */
export type QueryGetShoplistProductsHasCategoryWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistProductsHasCategoryWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetShoplistProducts.orderBy. */
export enum QueryGetShoplistProductsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetShoplistProducts.orderBy. */
export type QueryGetShoplistProductsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetShoplistProductsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetShoplistProducts.where. */
export enum QueryGetShoplistProductsWhereColumn {
  Affiliateable = 'AFFILIATEABLE',
  BusinessProfileId = 'BUSINESS_PROFILE_ID',
  Name = 'NAME',
  Status = 'STATUS',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `where` argument on the query `GetShoplistProducts`. */
export type QueryGetShoplistProductsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetShoplistProductsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetShoplistProductsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetShoplistProductsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetShoplistProductsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `GetShoplistProducts`. */
export type QueryGetShoplistProductsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetShoplistProductsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.GetTransactionsByWalletId.orderBy. */
export enum QueryGetTransactionsByWalletIdOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.GetTransactionsByWalletId.orderBy. */
export type QueryGetTransactionsByWalletIdOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryGetTransactionsByWalletIdOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.GetUserFlightBookings.hasUser. */
export enum QueryGetUserFlightBookingsHasUserColumn {
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasUser` argument on the query `GetUserFlightBookings`. */
export type QueryGetUserFlightBookingsHasUserWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryGetUserFlightBookingsHasUserWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryGetUserFlightBookingsHasUserWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryGetUserFlightBookingsHasUserWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QueryGetUserFlightBookingsHasUserColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasUser` argument on the query `GetUserFlightBookings`. */
export type QueryGetUserFlightBookingsHasUserWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryGetUserFlightBookingsHasUserWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.SearchProducts.hasProductCategory. */
export enum QuerySearchProductsHasProductCategoryColumn {
  Name = 'NAME',
  Slug = 'SLUG',
  Uuid = 'UUID'
}

/** Dynamic WHERE conditions for the `hasProductCategory` argument on the query `SearchProducts`. */
export type QuerySearchProductsHasProductCategoryWhereHasConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QuerySearchProductsHasProductCategoryWhereHasConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QuerySearchProductsHasProductCategoryWhereHasConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QuerySearchProductsHasProductCategoryWhereHasConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QuerySearchProductsHasProductCategoryColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `hasProductCategory` argument on the query `SearchProducts`. */
export type QuerySearchProductsHasProductCategoryWhereHasConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QuerySearchProductsHasProductCategoryWhereHasConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Allowed column names for Query.SearchProducts.orderBy. */
export enum QuerySearchProductsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.SearchProducts.orderBy. */
export type QuerySearchProductsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QuerySearchProductsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.SearchProducts.where. */
export enum QuerySearchProductsWhereColumn {
  Code = 'CODE',
  Name = 'NAME',
  Status = 'STATUS'
}

/** Dynamic WHERE conditions for the `where` argument on the query `SearchProducts`. */
export type QuerySearchProductsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QuerySearchProductsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QuerySearchProductsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QuerySearchProductsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<QuerySearchProductsWhereColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `SearchProducts`. */
export type QuerySearchProductsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QuerySearchProductsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Quoteforrefund */
export type Quoteforrefund = {
  __typename?: 'Quoteforrefund';
  MFRef: Scalars['String'];
  Message?: Maybe<Scalars['String']>;
  PTRId: Scalars['Int'];
  PTRStatus: Scalars['String'];
  PTRType: Scalars['String'];
  RefundQuotes: Array<SingleRefundQuotes>;
  SLAInMinutes?: Maybe<Scalars['Int']>;
  TraceId: Scalars['String'];
};

/** Reach Metrics */
export type ReachMetrics = {
  __typename?: 'ReachMetrics';
  /** Total number of impressions */
  impressions: Scalars['Int'];
  /** Total number of users reached */
  total: Scalars['Int'];
};

/** Product Recommendation Response */
export type RecommendationResponse = {
  __typename?: 'RecommendationResponse';
  products: Array<Product>;
  vendors: Array<PaddedVendor>;
};

/** Referral summary */
export type ReferralSummary = {
  __typename?: 'ReferralSummary';
  point_gained: Scalars['Float'];
  point_per_referral: Scalars['Float'];
  referral_code: Scalars['String'];
  referrals: Scalars['Int'];
};

export type RefundPassenger = {
  eTicket: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  passengerType: Scalars['String'];
  title: Scalars['String'];
};

/** A region */
export type Region = {
  __typename?: 'Region';
  /** The region country */
  country: Scalars['String'];
  /** The region currency */
  currency: Scalars['String'];
  /** The region flag_image_url */
  flag_image_url: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The region active status */
  is_active: Scalars['Boolean'];
  /** The region phone_code */
  phone_code: Scalars['String'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

export type ResolveBankAccountResponse = {
  __typename?: 'ResolveBankAccountResponse';
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
};

export enum RewardType {
  Cashback = 'CASHBACK',
  Discount = 'DISCOUNT'
}

/** Game rule */
export type Rule = {
  __typename?: 'Rule';
  /** When the rule was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The reward amount */
  reward_amount: Scalars['String'];
  /** The reward game point */
  reward_game_points: Scalars['String'];
  /** The reward type. can be discount or bonus_point */
  reward_type: Scalars['String'];
  /** When the rule was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

/** Sale on Shoppt. */
export type Sale = {
  __typename?: 'Sale';
  /** The attached booked flight */
  booked_flight?: Maybe<FlightBooking>;
  /** When the sale was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The money amount */
  money_amount: Scalars['String'];
  /** The point amount */
  point_amount: Scalars['String'];
  /** The attached point transaction */
  point_transaction?: Maybe<PointTransaction>;
  /** Product sold */
  product: Product;
  /** The sale reference */
  reference: Scalars['String'];
  /** The sale status. Can be success or finanlized */
  status: Scalars['String'];
  /** Total amount */
  total_amount: Scalars['String'];
  /** The attached transaction */
  transaction?: Maybe<Transaction>;
  /** When the sale was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user attached to the sale */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Scanned receipt on Shoppt. */
export type ScannedReceipt = {
  __typename?: 'ScannedReceipt';
  /** The receipt amount */
  amount: Scalars['Float'];
  /** The receipt category */
  category?: Maybe<Scalars['String']>;
  /** When the scanned receipt was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The receipt image URL */
  image_url: Scalars['String'];
  /** The receipt invoice number */
  invoice_no?: Maybe<Scalars['String']>;
  /** The receipt items */
  items: Scalars['String'];
  /** The main category */
  main_category?: Maybe<Scalars['String']>;
  /** The merchant address */
  merchant_address?: Maybe<Scalars['String']>;
  /** The merchant name */
  merchant_name?: Maybe<Scalars['String']>;
  /** The receipt date */
  receipt_date: Scalars['String'];
  /** The reward claim status */
  reward_claimed: Scalars['Boolean'];
  /** The receipt status */
  status: Scalars['String'];
  /** When the scanned receipt was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Scanned receipt response */
export type ScannedReceiptResponse = {
  __typename?: 'ScannedReceiptResponse';
  point_to_claim: Scalars['Float'];
  scanned_receipt: ScannedReceipt;
};

/** ScheduleChangeResponse */
export type ScheduleChangeResponse = {
  __typename?: 'ScheduleChangeResponse';
  CRMReferenceNumber?: Maybe<Scalars['String']>;
  CreatedTime?: Maybe<Scalars['String']>;
  MFRef: Scalars['String'];
  PTRId?: Maybe<Scalars['Int']>;
  PTRStatus?: Maybe<Scalars['String']>;
  PTRType: Scalars['String'];
  SLAInMinutes?: Maybe<Scalars['String']>;
  TraceId?: Maybe<Scalars['String']>;
};

export type SearchFlightRequest = {
  /** The cabin class */
  cabin_class: Scalars['String'];
  /** All fligths */
  fligths: Array<FlightOption>;
  /** All passengers */
  passengers: Array<PassengerOption>;
};

export type SeatsRemaining = {
  __typename?: 'SeatsRemaining';
  BelowMinimum: Scalars['Boolean'];
  Number: Scalars['Int'];
};

/** Shipping Category */
export type ShippingCategory = {
  __typename?: 'ShippingCategory';
  /** The category */
  category?: Maybe<Scalars['String']>;
  /** The category id */
  category_id?: Maybe<Scalars['ID']>;
};

/** Shipping information */
export type ShippingInfo = {
  __typename?: 'ShippingInfo';
  /** The address */
  address: Scalars['String'];
  /** The email address */
  email: Scalars['String'];
  /** The name */
  name: Scalars['String'];
  /** The phone number */
  phone: Scalars['String'];
};

export type ShippingInfoInput = {
  courier_id: Scalars['String'];
  full_data: Scalars['String'];
  request_token: Scalars['String'];
  service_code: Scalars['String'];
};

/** The shipping info */
export type ShippingInfoOrder = {
  __typename?: 'ShippingInfoOrder';
  /** The courier information */
  courier?: Maybe<Courier>;
  /** The request token for the shipping */
  request_token?: Maybe<Scalars['String']>;
};

/** Shipping rate response */
export type ShippingRate = {
  __typename?: 'ShippingRate';
  /** The cheapest courier option */
  cheapest_courier: Courier;
  /** Checkout data */
  checkout_data: CheckoutData;
  /** List of available couriers */
  couriers: Array<Courier>;
  /** The fastest courier option */
  fastest_courier: Courier;
  /** The request token */
  request_token: Scalars['String'];
};

export type ShippingRateInput = {
  products_by_business: Array<CommercePurchasePerBusiness>;
  shoplist_id: Scalars['String'];
  user_delivery_location_id: Scalars['String'];
};

/** Shipping rate response */
export type ShippingRateResponse = {
  __typename?: 'ShippingRateResponse';
  /** The business id */
  business_id: Scalars['Int'];
  /** The shipping rate */
  shipping_rate: ShippingRate;
};

export type ShopInsuranceRequest = {
  nature_of_business: Scalars['String'];
  nature_of_stock: Scalars['String'];
  product_id: Scalars['String'];
  shop_type: Scalars['String'];
  stock_amount: Scalars['Int'];
  stock_keeping: Scalars['Boolean'];
  stock_taking_interval: Scalars['String'];
};

/** Shopify Collection */
export type ShopifyCollection = {
  __typename?: 'ShopifyCollection';
  /** The collection's handle */
  handle: Scalars['String'];
  /** Unique identifier for the collection */
  id: Scalars['ID'];
  /** Products in the collection */
  products?: Maybe<ShopifyProductConnection>;
  /** Sort order of the collection */
  sortOrder?: Maybe<Scalars['String']>;
  /** The collection's title */
  title: Scalars['String'];
  /** Last update date of the collection */
  updatedAt: Scalars['String'];
};

/** Shopify Collection Connection */
export type ShopifyCollectionConnection = {
  __typename?: 'ShopifyCollectionConnection';
  edges: Array<ShopifyCollectionEdge>;
  pageInfo: PageInfo;
};

export type ShopifyCollectionEdge = {
  __typename?: 'ShopifyCollectionEdge';
  node: ShopifyCollection;
};

export type ShopifyMoney = {
  __typename?: 'ShopifyMoney';
  /** Amount of money */
  amount: Scalars['Float'];
  /** Currency code */
  currencyCode: Scalars['String'];
};

/** Shopify Product */
export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  /** The product's category */
  category?: Maybe<ShopifyProductCategory>;
  /** Contextual pricing information for the product */
  contextualPricing?: Maybe<ShopifyProductContextualPricing>;
  /** The product's description */
  description?: Maybe<Scalars['String']>;
  /** The product's description in HTML format */
  descriptionHtml?: Maybe<Scalars['String']>;
  /** Unique identifier for the product */
  id: Scalars['ID'];
  /** Whether the product is imported */
  imported: Scalars['Boolean'];
  /** Whether the product is a gift card */
  isGiftCard?: Maybe<Scalars['Boolean']>;
  /** Media associated with the product */
  media?: Maybe<ShopifyProductMediaConnection>;
  /** The product's title */
  title: Scalars['String'];
  /** Variants of the product */
  variants?: Maybe<ShopifyProductVariantConnection>;
};

export type ShopifyProductCategory = {
  __typename?: 'ShopifyProductCategory';
  /** Full name of the category */
  fullName?: Maybe<Scalars['String']>;
  /** Unique identifier for the category */
  id: Scalars['ID'];
  /** Name of the category */
  name: Scalars['String'];
  /** ID of the parent category */
  parentId?: Maybe<Scalars['ID']>;
};

export type ShopifyProductConnection = {
  __typename?: 'ShopifyProductConnection';
  edges: Array<ShopifyProductEdge>;
  pageInfo: PageInfo;
};

export type ShopifyProductContextualPricing = {
  __typename?: 'ShopifyProductContextualPricing';
  /** Count of fixed quantity rules */
  fixedQuantityRulesCount: Scalars['Int'];
  /** Maximum variant pricing */
  maxVariantPricing: ShopifyProductPricing;
  /** Minimum variant pricing */
  minVariantPricing: ShopifyProductPricing;
};

export type ShopifyProductEdge = {
  __typename?: 'ShopifyProductEdge';
  node: ShopifyProduct;
};

export type ShopifyProductImage = {
  __typename?: 'ShopifyProductImage';
  /** Alternative text for the image */
  altText?: Maybe<Scalars['String']>;
  /** URL of the image */
  url: Scalars['String'];
};

export type ShopifyProductMedia = {
  __typename?: 'ShopifyProductMedia';
  /** Alternative text for the media */
  alt?: Maybe<Scalars['String']>;
  /** Content type of the media */
  mediaContentType: Scalars['String'];
  /** Preview of the media */
  preview?: Maybe<ShopifyProductMediaPreview>;
};

export type ShopifyProductMediaConnection = {
  __typename?: 'ShopifyProductMediaConnection';
  edges: Array<ShopifyProductMediaEdge>;
};

export type ShopifyProductMediaEdge = {
  __typename?: 'ShopifyProductMediaEdge';
  node: ShopifyProductMedia;
};

export type ShopifyProductMediaPreview = {
  __typename?: 'ShopifyProductMediaPreview';
  image?: Maybe<ShopifyProductImage>;
  status: Scalars['String'];
};

export type ShopifyProductPricing = {
  __typename?: 'ShopifyProductPricing';
  price: ShopifyMoney;
};

export type ShopifyProductPricingQuantityRule = {
  __typename?: 'ShopifyProductPricingQuantityRule';
  /** Maximum quantity */
  maximum?: Maybe<Scalars['Int']>;
  /** Minimum quantity */
  minimum: Scalars['Int'];
};

export type ShopifyProductVariant = {
  __typename?: 'ShopifyProductVariant';
  /** Whether the variant is available for sale */
  availableForSale: Scalars['Boolean'];
  /** Compare at price of the variant */
  compareAtPrice?: Maybe<Scalars['Float']>;
  /** Contextual pricing information for the variant */
  contextualPricing: ShopifyProductVariantContextualPricing;
  /** Creation date of the variant */
  createdAt: Scalars['String'];
  /** Display name of the variant */
  displayName: Scalars['String'];
  /** Unique identifier for the variant */
  id: Scalars['ID'];
  /** Image associated with the variant */
  image?: Maybe<ShopifyProductImage>;
  /** Inventory quantity of the variant */
  inventoryQuantity: Scalars['Int'];
  /** Media associated with the variant */
  media?: Maybe<ShopifyProductMediaConnection>;
};

export type ShopifyProductVariantConnection = {
  __typename?: 'ShopifyProductVariantConnection';
  edges: Array<ShopifyProductVariantEdge>;
};

export type ShopifyProductVariantContextualPricing = {
  __typename?: 'ShopifyProductVariantContextualPricing';
  /** Compare at price in the given context */
  compareAtPrice?: Maybe<ShopifyMoney>;
  /** Price in the given context */
  price: ShopifyMoney;
  /** Quantity rule for the pricing */
  quantityRule?: Maybe<ShopifyProductPricingQuantityRule>;
};

export type ShopifyProductVariantEdge = {
  __typename?: 'ShopifyProductVariantEdge';
  node: ShopifyProductVariant;
};

/** User Cart on Shoppt. */
export type ShoplistCart = {
  __typename?: 'ShoplistCart';
  /** The cart collection */
  collection?: Maybe<Collection>;
  /** When the reward was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** When the reward was last updated. */
  updated_at: Scalars['DateTime'];
  /** The ID of the user who owns the cart */
  user_id: Scalars['Int'];
};

/** Shoplist commission */
export type ShoplistCommission = {
  __typename?: 'ShoplistCommission';
  /** The commission type */
  category_id: Scalars['Int'];
  /** The minimum reward percentage */
  minimum_reward_percentage?: Maybe<Scalars['String']>;
  /** The commission amount */
  percentage: Scalars['String'];
  /** The reward cap percentage */
  reward_cap_percentage?: Maybe<Scalars['String']>;
};

/** Merchant Products on Shoppt. */
export type ShoplistProduct = {
  __typename?: 'ShoplistProduct';
  /** The product gallery images */
  affiliate_commission?: Maybe<Scalars['String']>;
  /** The product image */
  affiliateable?: Maybe<Scalars['Boolean']>;
  /** The product attributes */
  attributes?: Maybe<Array<ProductVariantOption>>;
  /** The product brand */
  brand?: Maybe<Business>;
  /** The product business owner */
  business?: Maybe<Business>;
  /** The ID of the merchant that owns the product */
  business_profile_id?: Maybe<Scalars['Int']>;
  /** Product category */
  category: Category;
  /** When the product was created. */
  created_at: Scalars['DateTime'];
  /** Product code */
  deliverable?: Maybe<Scalars['Boolean']>;
  /** The product delivery areas */
  delivery_areas: Array<ProductDeliveryLocation>;
  /** Product description */
  description?: Maybe<Scalars['String']>;
  /** The dimension size */
  dimension_size?: Maybe<PackageDimension>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The product media */
  medias?: Maybe<Array<MediaContent>>;
  /** Product name */
  name: Scalars['String'];
  /** Product Pricing */
  pricing?: Maybe<Array<Maybe<Pricing>>>;
  /** The point to gain if bought complete with cash */
  primary_image_url: Scalars['String'];
  /** The product price */
  regular_price: Scalars['String'];
  /** The collection products */
  rewards: Array<ShoplistReward>;
  /** The product shipping category */
  shipping_category?: Maybe<ShippingCategory>;
  /** Description Meta */
  sku?: Maybe<Scalars['String']>;
  /** The product status. can be active or inactive */
  status?: Maybe<Scalars['String']>;
  /** The cost for each point gain from product sales */
  stock_quantity: Scalars['Int'];
  /** Product sub category */
  sub_category?: Maybe<Category>;
  /** When the product was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
  /** Product Variant */
  variants?: Maybe<Array<Maybe<ProductVariant>>>;
  /** The product weight */
  weight?: Maybe<Scalars['String']>;
};

/** A paginated list of ShoplistProduct items. */
export type ShoplistProductPaginator = {
  __typename?: 'ShoplistProductPaginator';
  /** A list of ShoplistProduct items. */
  data: Array<ShoplistProduct>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Merchant Reward on Shoppt. */
export type ShoplistReward = {
  __typename?: 'ShoplistReward';
  /** The ID of the merchant that owns the reward */
  business_profile_id?: Maybe<Scalars['Int']>;
  /** When the reward was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The reward percentage */
  percentage: Scalars['String'];
  /** The reward quantity */
  quantity: Scalars['String'];
  /** The reward type */
  type: Scalars['String'];
  /** When the reward was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

export type SinglPassengerFare = {
  __typename?: 'SinglPassengerFare';
  BaseFare: Scalars['String'];
  PaxType: Scalars['String'];
  Quantity: Scalars['Int'];
  TaxBreakUp: Array<SingleTaxBreakUp>;
  TotalFare: Scalars['String'];
};

export type SinglPassengerFareRevalidate = {
  __typename?: 'SinglPassengerFareRevalidate';
  BaseFare: SingleTaxBreakUp;
  EquivFare: SingleTaxBreakUp;
  Surcharges?: Maybe<Array<Maybe<Scalars['String']>>>;
  Taxes: Array<SingleTaxBreakUp>;
  TotalFare: SingleTaxBreakUp;
};

export type SingleFareRules = {
  __typename?: 'SingleFareRules';
  Airline?: Maybe<Scalars['String']>;
  CityPair?: Maybe<Scalars['String']>;
  RuleDetails: Array<SingleRuleDetails>;
};

/** SingleFlight */
export type SingleFlight = {
  __typename?: 'SingleFlight';
  Airline: Airline;
  CabinBaggage?: Maybe<Array<FlightSegmentInfoOptions>>;
  CheckinBaggage?: Maybe<Array<FlightSegmentInfoOptions>>;
  FareBasisCodes?: Maybe<Scalars['String']>;
  FareInfo: FlightFareInfo;
  FareSourceCode: Scalars['String'];
  FulfilmentInfo?: Maybe<Array<Scalars['String']>>;
  PenaltyInfo: FlightPenaltyInfo;
  Provider: Scalars['String'];
  Segments: Array<SingleFlightSegment>;
};

export type SingleFlightBaggageInfos = {
  __typename?: 'SingleFlightBaggageInfos';
  Arrival: Scalars['String'];
  Baggage: Scalars['String'];
  Departure: Scalars['String'];
  FlightNo: Scalars['String'];
};

/** SingleFlightFareRules */
export type SingleFlightFareRules = {
  __typename?: 'SingleFlightFareRules';
  BaggageInfos?: Maybe<Array<SingleFlightBaggageInfos>>;
  FareRules?: Maybe<Array<SingleFareRules>>;
  TraceId: Scalars['String'];
};

export type SingleFlightPassenger = {
  date_of_birth: Scalars['String'];
  first_name: Scalars['String'];
  gender: Scalars['String'];
  last_name: Scalars['String'];
  meal_preference: Scalars['String'];
  nationality: Scalars['String'];
  passport_country?: InputMaybe<Scalars['String']>;
  passport_exp?: InputMaybe<Scalars['String']>;
  passport_no?: InputMaybe<Scalars['String']>;
  seat_preference: Scalars['String'];
  segments: Array<SingleFlightSegmentInput>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type SingleFlightSegment = {
  __typename?: 'SingleFlightSegment';
  ArrivalCity: Scalars['String'];
  DepartureCity: Scalars['String'];
  Info?: Maybe<FlightSegmentInfo>;
  Segment: FlightSegmentDetails;
  /** Can be To or From */
  Type: Scalars['String'];
};

export type SingleFlightSegmentInput = {
  departure_datetime: Scalars['String'];
  destination: Scalars['String'];
  flight_no: Scalars['String'];
  origin: Scalars['String'];
};

export type SingleOriginDestinationOption = {
  __typename?: 'SingleOriginDestinationOption';
  FlightSegments: Array<ValidatedFlightSegment>;
};

export type SinglePtcFareBreakdown = {
  __typename?: 'SinglePTCFareBreakdown';
  BaggageInfo: Array<Scalars['String']>;
  CabinBaggageInfo: Array<Scalars['String']>;
  FareBasisCodes: Array<Scalars['String']>;
  PassengerFare?: Maybe<SinglPassengerFareRevalidate>;
  PassengerTypeQuantity: PassengerTypeQuantity;
  PenaltiesInfo: Array<SinglePenaltiesInfo>;
};

export type SinglePassengerDetails = {
  __typename?: 'SinglePassengerDetails';
  DateOfBirth: Scalars['String'];
  EmailAddress: Scalars['String'];
  Gender: Scalars['String'];
  NameNumber: Scalars['Int'];
  NationalID: Scalars['String'];
  PassengerNationality: Scalars['String'];
  PassengerType: Scalars['String'];
  PassportExpiresOn: Scalars['String'];
  PassportIssuanceCountry: Scalars['String'];
  PassportNationality: Scalars['String'];
  PassportNumber: Scalars['String'];
  PaxName: PaxName;
  PhoneNumber: Scalars['String'];
  PostCode: Scalars['String'];
  TicketStatus?: Maybe<Scalars['String']>;
};

export type SinglePenaltiesInfo = {
  __typename?: 'SinglePenaltiesInfo';
  Allowed: Scalars['Boolean'];
  Amount: Scalars['String'];
  CurrencyCode: Scalars['String'];
  PenaltyType: Scalars['String'];
};

export type SinglePenaltydetails = {
  __typename?: 'SinglePenaltydetails';
  ChangeAllowed: Scalars['Boolean'];
  ChangePenaltyAmount: Scalars['String'];
  Currency: Scalars['String'];
  PaxType: Scalars['String'];
  RefundAllowed: Scalars['Boolean'];
  RefundPenaltyAmount: Scalars['String'];
};

export type SingleRefundQuotes = {
  __typename?: 'SingleRefundQuotes';
  AdminCharges: Scalars['Float'];
  CancellationCharge: Scalars['Float'];
  Currency: Scalars['String'];
  Eticket: Scalars['String'];
  ExtraServiceCharge: Scalars['Float'];
  FirstName: Scalars['String'];
  GSTCharge: Scalars['Float'];
  LastName: Scalars['String'];
  NoShowCharge: Scalars['Float'];
  OtherTaxesK3: Scalars['Float'];
  PassengerType: Scalars['String'];
  Tax: Scalars['Float'];
  Title: Scalars['String'];
  TotalFare: Scalars['Float'];
  TotalRefundAmount: Scalars['Float'];
  TotalRefundCharges: Scalars['Float'];
  UnusedFare: Scalars['Float'];
  YQ_Tax: Scalars['Float'];
  YR_Tax: Scalars['Float'];
};

export type SingleRuleDetails = {
  __typename?: 'SingleRuleDetails';
  Category: Scalars['String'];
  Rules?: Maybe<Scalars['String']>;
};

export type SingleTaxBreakUp = {
  __typename?: 'SingleTaxBreakUp';
  Amount: Scalars['String'];
  CurrencyCode?: Maybe<Scalars['String']>;
  DecimalPlaces?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
};

export type SocialLinks = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
};

export type SocialLinksData = {
  __typename?: 'SocialLinksData';
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type SpendingCategory = {
  __typename?: 'SpendingCategory';
  /** Category ID */
  id: Scalars['String'];
  /** Category name */
  name: Scalars['String'];
};

export type StopQuantityInfo = {
  __typename?: 'StopQuantityInfo';
  ArrivalDateTime: Scalars['String'];
  DepartureDateTime: Scalars['String'];
  Duration: Scalars['Int'];
  LocationCode?: Maybe<Scalars['String']>;
};

export type StorePermissions = {
  __typename?: 'StorePermissions';
  manage_all_orders: Scalars['Boolean'];
  manage_assigned_branch_orders: Scalars['Boolean'];
  manage_offline_payments: Scalars['Boolean'];
  manage_products: Scalars['Boolean'];
  manage_profile: Scalars['Boolean'];
  manage_promotions: Scalars['Boolean'];
  manage_settlement_accounts: Scalars['Boolean'];
  manage_shoplists: Scalars['Boolean'];
  manage_store_branches: Scalars['Boolean'];
  user_role?: Maybe<Scalars['String']>;
};

export type StorePermissionsInput = {
  manage_all_orders: Scalars['Boolean'];
  manage_assigned_branch_orders: Scalars['Boolean'];
  manage_offline_payments: Scalars['Boolean'];
  manage_products: Scalars['Boolean'];
  manage_profile: Scalars['Boolean'];
  manage_promotions: Scalars['Boolean'];
  manage_settlement_accounts: Scalars['Boolean'];
  manage_shoplists: Scalars['Boolean'];
  manage_store_branches: Scalars['Boolean'];
  user_role?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to global Auth update */
  GlobalAuthUpdate?: Maybe<User>;
  /** Subscribe to point gain events */
  NewPointGain?: Maybe<CashPointGainEvent>;
};

/** Subscription commission */
export type SubscriptionCommission = {
  __typename?: 'SubscriptionCommission';
  plan_id: Scalars['Int'];
  reward_percentage?: Maybe<Scalars['String']>;
};

/** All subscription plans on Shoppt. */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  /** The annual spend cap */
  annual_spend_cap?: Maybe<Scalars['String']>;
  /** The claim point expiry in days */
  claim_point_expiry: Scalars['Float'];
  /** When the plan was created. */
  created_at: Scalars['DateTime'];
  /** The delivery amount */
  delivery_amount?: Maybe<Scalars['String']>;
  /** The delivery type */
  delivery_type?: Maybe<Scalars['String']>;
  /** The plan description */
  description?: Maybe<Scalars['String']>;
  /** Total free transfer per month */
  free_transfer_per_month: Scalars['Float'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The cashback monthly growth */
  monthly_cashback_growth: Scalars['Float'];
  /** The plan name */
  name: Scalars['String'];
  /** The preload cashback percentage */
  preload_cashback_percentage: Scalars['Float'];
  /** The plan price */
  price: Scalars['String'];
  /** The allow back date for receipt scan */
  receipt_scan_allowed_days: Scalars['Float'];
  /** The receipt weekly spend limit */
  receipt_weekly_spend_limit?: Maybe<Scalars['String']>;
  /** The allow processing time for a receipt */
  scan_processing_period: Scalars['Float'];
  /** The shoplist commission */
  shoplist_commission?: Maybe<Array<ShoplistCommission>>;
  /** When the plan was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Support area on Shoppt. */
export type SupportedArea = {
  __typename?: 'SupportedArea';
  /** The area name */
  area_name?: Maybe<Scalars['String']>;
  /** When the reward was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The land area */
  land_area?: Maybe<Scalars['String']>;
  /** The latitude */
  lat?: Maybe<Scalars['String']>;
  /** The longtitude */
  lng?: Maybe<Scalars['String']>;
  /** The state name */
  state_color?: Maybe<Scalars['String']>;
  /** The state name */
  state_name?: Maybe<Scalars['String']>;
  /** When the reward was last updated. */
  updated_at: Scalars['DateTime'];
  /** Unique UUID */
  uuid?: Maybe<Scalars['String']>;
};

/** Wallet transactions on Shoppt. */
export type Transaction = {
  __typename?: 'Transaction';
  /** The transaction amount */
  amount: Scalars['Float'];
  /** The ID that entity that generated the transaction */
  charge_id?: Maybe<Scalars['String']>;
  /** The type of entity that generated the transaction */
  chargeable_type?: Maybe<Scalars['String']>;
  /** The charges attached to the transaction */
  charges?: Maybe<Scalars['Float']>;
  /** When the transaction was created. */
  created_at: Scalars['DateTime'];
  /** The currency of the transaction. Only ngn is available for now */
  currency: Scalars['String'];
  /** The transaction description or narration */
  description?: Maybe<Scalars['String']>;
  /** The type of transaction. Can be debit or credit */
  dr_or_cr: Scalars['String'];
  /** Extra custom data attached to the transaction */
  extra_data?: Maybe<Scalars['String']>;
  /** The transaction gateway. Can be shpt-wallet, paystack, fincra */
  gateway: Scalars['String'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The point balance before the transaction */
  point_balance?: Maybe<Scalars['Float']>;
  /** The transaction unique reference */
  reference?: Maybe<Scalars['String']>;
  /** The transaction status. Can be success, failed, pending */
  status: Scalars['String'];
  /** When the transaction was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user: User;
  /** Unique UUID */
  uuid: Scalars['String'];
  /** The attached wallet */
  wallet: Wallet;
  /** The wallet balance before the transaction */
  wallet_balance: Scalars['Float'];
};

/** A paginated list of Transaction items. */
export type TransactionPaginator = {
  __typename?: 'TransactionPaginator';
  /** A list of Transaction items. */
  data: Array<Transaction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type TravelInsuranceRequest = {
  destination_country: Scalars['String'];
  end_date: Scalars['String'];
  nok_address: Scalars['String'];
  nok_full_name: Scalars['String'];
  nok_relationship: Scalars['String'];
  passport_image_url: Scalars['String'];
  passport_number: Scalars['String'];
  pre_medical: Scalars['Boolean'];
  product_id: Scalars['String'];
  start_date: Scalars['String'];
};

export type TravelItinerary = {
  __typename?: 'TravelItinerary';
  BookingMode: Scalars['Int'];
  BookingNotes?: Maybe<Array<Maybe<BookingNotes>>>;
  BookingStatus: Scalars['String'];
  ClientUTCOffset: Scalars['Float'];
  Destination: Scalars['String'];
  ExtraServices?: Maybe<ExtraServices>;
  FareType: Scalars['String'];
  Itineraries: Array<EachBookedItineraries>;
  MFRef: Scalars['String'];
  Origin: Scalars['String'];
  PassengerInfos: Array<EachPassengerInfos>;
  ReroutingAllowed: Scalars['String'];
  SpoilageFee: Scalars['Float'];
  TicketStatus?: Maybe<Scalars['String']>;
  TicketingTimeLimit: Scalars['String'];
  TransactionDetails?: Maybe<Array<Maybe<Scalars['String']>>>;
  TripDetailsPTC_FareBreakdowns: Array<EachTripDetailsPtc_FareBreakdowns>;
  TripType: Scalars['String'];
  VoidingWindow?: Maybe<Scalars['String']>;
};

/** TripDetails */
export type TripDetails = {
  __typename?: 'TripDetails';
  BookingCreatedOn: Scalars['String'];
  Provider: Scalars['String'];
  Success?: Maybe<Scalars['Boolean']>;
  TravelItinerary: TravelItinerary;
};

export type TripDetailsPassengerFare = {
  __typename?: 'TripDetailsPassengerFare';
  AirportTaxBreakUp: Scalars['String'];
  EquiFare: SingleTaxBreakUp;
  ServiceTax?: Maybe<SingleTaxBreakUp>;
  Tax: SingleTaxBreakUp;
  TotalFare: SingleTaxBreakUp;
};

export type UpdateProductPricingInput = {
  amount?: InputMaybe<Scalars['String']>;
  pricing_id: Scalars['Int'];
  supported_area_id?: InputMaybe<Scalars['Int']>;
};

export enum UpdateShoplistCollectionType {
  Business = 'BUSINESS',
  User = 'User'
}

export type UpdateflightoptionsRequest = {
  booking_reference: Scalars['String'];
  comment: Scalars['String'];
  flight_options: Array<FlightOptionUpdate>;
  /** Can be either Earlierflightoptions or LaterFlightOptions */
  new_options: Scalars['String'];
};

/** A shoppt. user */
export type User = {
  __typename?: 'User';
  /** The user total spend in the current year */
  annual_spend: Scalars['Float'];
  /** The user business */
  business?: Maybe<Business>;
  /** The user business admin */
  business_admin?: Maybe<BusinessAdmin>;
  /** The user business wallet */
  business_wallet?: Maybe<Wallet>;
  /** When the user was created */
  created_at: Scalars['DateTime'];
  /** The user current total free transfers used */
  current_month_free_transfers: Scalars['Float'];
  /** The user unique email address */
  email?: Maybe<Scalars['String']>;
  /** The user email verification datetime */
  email_verified_at?: Maybe<Scalars['String']>;
  /** The user enrolled cards */
  enrolled_cards?: Maybe<Array<EnrolledCard>>;
  /** The user first name */
  first_name?: Maybe<Scalars['String']>;
  /** The user full name. first name + last name */
  full_name?: Maybe<Scalars['String']>;
  /** The user game profile */
  game_profile?: Maybe<GameProfile>;
  /** The user has password status */
  has_password: Scalars['Boolean'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The user last name */
  last_name?: Maybe<Scalars['String']>;
  /** The user notifications */
  notifications?: Maybe<Array<Notification>>;
  /** The user pending points */
  pending_points: Scalars['Float'];
  /** The user phone number with the country code e.g +234 */
  phone?: Maybe<Scalars['String']>;
  /** The user phone verification datetime */
  phone_verified_at?: Maybe<Scalars['String']>;
  /** The user point categories */
  point_categories: Array<PointCategory>;
  /** The user profile info */
  profile?: Maybe<Profile>;
  /** The user push notification devices */
  push_notification_devices?: Maybe<Array<PushNotification>>;
  /** The user total receipt spend amount in the current week */
  receipt_week_spend: Scalars['Float'];
  /** The user referral summary */
  referral_summary?: Maybe<ReferralSummary>;
  /** The user account status */
  status: Scalars['String'];
  /** The user transactions */
  transactions?: Maybe<Array<Transaction>>;
  /** The user unique username */
  username?: Maybe<Scalars['String']>;
  /** Unique UUID */
  uuid: Scalars['String'];
  /** The user wallet */
  wallet?: Maybe<Wallet>;
};

/** Saved bank account on Shoppt. */
export type UserBank = {
  __typename?: 'UserBank';
  /** The bank account number */
  account_no: Scalars['String'];
  /** The bank code */
  bank_code: Scalars['String'];
  /** The bank name */
  bank_name: Scalars['String'];
  /** When the user bank was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The default bank */
  is_default: Scalars['Boolean'];
  /** The bank account status */
  is_verified: Scalars['Boolean'];
  /** Extra bank data */
  meta_data: BankMetaData;
  /** The sub business ID */
  sub_business_id?: Maybe<Scalars['Int']>;
  /** When the user bank was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
  /** The attached wallet */
  wallet?: Maybe<Wallet>;
};

/** Saved debit card on Shoppt. */
export type UserCard = {
  __typename?: 'UserCard';
  /** When the user card was created. */
  created_at: Scalars['DateTime'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The card metadaya */
  meta_data: CardMetaData;
  /** When the user card was last updated. */
  updated_at: Scalars['DateTime'];
  /** The attached user */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
  /** The attached wallet */
  wallet?: Maybe<Wallet>;
};

/** A user content */
export type UserContent = {
  __typename?: 'UserContent';
  contents: Array<Content>;
  current_page: Scalars['Int'];
  request_uuid: Scalars['String'];
  total_pages: Scalars['Int'];
};

export type ValidatedFlightSegment = {
  __typename?: 'ValidatedFlightSegment';
  ArrivalAirport: Scalars['String'];
  ArrivalAirportLocationCode: Scalars['String'];
  ArrivalCity: Scalars['String'];
  ArrivalDateTime: Scalars['String'];
  CabinClassCode: Scalars['String'];
  CabinClassText?: Maybe<Scalars['String']>;
  DepartureAirport: Scalars['String'];
  DepartureAirportLocationCode: Scalars['String'];
  DepartureCity: Scalars['String'];
  DepartureDateTime: Scalars['String'];
  Eticket: Scalars['Boolean'];
  FlightNumber: Scalars['Boolean'];
  JourneyDuration: Scalars['Int'];
  MarketingAirlineCode: Scalars['String'];
  MarriageGroup?: Maybe<Scalars['String']>;
  MealCode?: Maybe<Scalars['String']>;
  OperatingAirline: OperatingAirline;
  ResBookDesigCode: Scalars['String'];
  ResBookDesigText?: Maybe<Scalars['String']>;
  SeatsRemaining: SeatsRemaining;
  StopQuantity: Scalars['Int'];
  StopQuantityInfo: StopQuantityInfo;
};

export type VariantInput = {
  id?: InputMaybe<Scalars['String']>;
  image_url?: InputMaybe<Scalars['Upload']>;
  inventory_quantity: Scalars['Int'];
  is_available: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['String'];
  selected_options: Array<VariantSelectedOptions>;
};

export type VariantSelectedOptions = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Product Vendor */
export type Vendor = {
  __typename?: 'Vendor';
  /** Cover image */
  cover_image?: Maybe<Scalars['String']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Merchant logo */
  logo_url: Scalars['String'];
  /** Merchant name */
  merchant_name: Scalars['String'];
  /** Vendor name */
  name: Scalars['String'];
  /** Other info */
  other_info?: Maybe<Array<OtherInfo>>;
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** A single waitlist */
export type Waitlist = {
  __typename?: 'Waitlist';
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Unique UUID */
  uuid: Scalars['String'];
};

/** Wallet on Shoppt. */
export type Wallet = {
  __typename?: 'Wallet';
  /** The attached saved bank accounts */
  banks?: Maybe<Array<UserBank>>;
  /** The attached saved cards */
  cards?: Maybe<Array<UserCard>>;
  /** The monetary worth of each points in the wallet */
  cash_per_point: Scalars['Float'];
  /** Total cash point balance. Cash point is monetary value of the point balance */
  cash_point_balance: Scalars['Float'];
  /** When the wallet was created. */
  created_at: Scalars['DateTime'];
  /** Total credited amount */
  credited_amount: Scalars['Float'];
  /** Total credited point amount */
  credited_point_amount: Scalars['Float'];
  /** Total debited amount */
  debited_amount: Scalars['Float'];
  /** Total debited point amount */
  debited_point_amount: Scalars['Float'];
  /** Total earned free transfers */
  free_transfers: Scalars['Float'];
  /** Unique primary key. */
  id: Scalars['ID'];
  /** The wallet lock status */
  is_locked: Scalars['Boolean'];
  /** Total locked amount. Locked means the amount that can't be spent */
  locked_balance: Scalars['Float'];
  /** Total spendable point balance */
  point_balance: Scalars['Float'];
  /** The attached point transactions */
  point_transactions: Array<PointTransaction>;
  /** Spending account tier */
  spending_account_tier: Scalars['Int'];
  /** Account upgrade stauts */
  spending_account_upgraded: Scalars['Boolean'];
  /** Total wallet balance */
  total_balance: Scalars['Float'];
  /** The attached transactions */
  transactions: Array<Transaction>;
  /** When the wallet was last updated. */
  updated_at: Scalars['DateTime'];
  /** The user attached to the wallet */
  user?: Maybe<User>;
  /** Unique UUID */
  uuid: Scalars['String'];
  /** The virtual wallet account */
  wallet_account?: Maybe<WalletAccount>;
};

/** Virtual account on Shoppt. */
export type WalletAccount = {
  __typename?: 'WalletAccount';
  /** The virtual account ID */
  account_id: Scalars['String'];
  /** The virtual account name */
  account_name: Scalars['String'];
  /** The virtual account number */
  account_number: Scalars['String'];
  /** The virtual account type. Can be individual or corporate */
  account_type: Scalars['String'];
  /** The virtual account bank name */
  bank_name: Scalars['String'];
  /** The virtual account reference */
  reference: Scalars['String'];
  /** The virtual account status */
  status: Scalars['String'];
};

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type WoocommerceAttribute = {
  __typename?: 'WoocommerceAttribute';
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  variation: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type WoocommerceCategory = {
  __typename?: 'WoocommerceCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type WoocommerceDefaultAttribute = {
  __typename?: 'WoocommerceDefaultAttribute';
  id: Scalars['ID'];
  name: Scalars['String'];
  option: Scalars['String'];
};

export type WoocommerceDimensions = {
  __typename?: 'WoocommerceDimensions';
  height?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type WoocommerceImage = {
  __typename?: 'WoocommerceImage';
  alt?: Maybe<Scalars['String']>;
  date_created: Scalars['String'];
  date_modified: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  src: Scalars['String'];
};

/** Woocommerce product */
export type WoocommerceProduct = {
  __typename?: 'WoocommerceProduct';
  /** Attributes of the product */
  attributes: Array<WoocommerceAttribute>;
  /** Average rating of the product */
  average_rating: Scalars['String'];
  /** Whether the product is on backorder */
  backordered: Scalars['Boolean'];
  /** Backorder status */
  backorders: Scalars['String'];
  /** Whether backorders are allowed */
  backorders_allowed: Scalars['Boolean'];
  /** Visibility in the catalog */
  catalog_visibility: Scalars['String'];
  /** Categories of the product */
  categories: Array<WoocommerceCategory>;
  /** Date when the product was created */
  date_created: Scalars['String'];
  /** Date when the product was last modified */
  date_modified: Scalars['String'];
  /** Default attributes of the product */
  default_attributes: Array<WoocommerceDefaultAttribute>;
  /** Full description of the product */
  description: Scalars['String'];
  /** Dimensions of the product */
  dimensions?: Maybe<WoocommerceDimensions>;
  /** Whether the product is downloadable */
  downloadable: Scalars['Boolean'];
  /** Whether the product is featured */
  featured: Scalars['Boolean'];
  /** Unique identifier for the product */
  id: Scalars['ID'];
  /** Images of the product */
  images: Array<WoocommerceImage>;
  /** Whether the product is imported */
  imported: Scalars['Boolean'];
  /** Whether stock management is enabled */
  manage_stock: Scalars['Boolean'];
  /** Menu order of the product */
  menu_order: Scalars['Int'];
  /** Name of the product */
  name: Scalars['String'];
  /** Whether the product is on sale */
  on_sale: Scalars['Boolean'];
  /** Parent product ID */
  parent_id: Scalars['Int'];
  /** Full URL of the product */
  permalink: Scalars['String'];
  /** Current price of the product */
  price?: Maybe<Scalars['String']>;
  /** HTML formatted price */
  price_html: Scalars['String'];
  /** Whether the product can be purchased */
  purchasable: Scalars['Boolean'];
  /** Purchase note */
  purchase_note?: Maybe<Scalars['String']>;
  /** Number of ratings */
  rating_count: Scalars['Int'];
  /** Regular price of the product */
  regular_price?: Maybe<Scalars['String']>;
  /** IDs of related products */
  related_ids: Array<Scalars['Int']>;
  /** Whether reviews are allowed */
  reviews_allowed: Scalars['Boolean'];
  /** Sale price of the product */
  sale_price?: Maybe<Scalars['String']>;
  /** Shipping class of the product */
  shipping_class?: Maybe<Scalars['String']>;
  /** ID of the shipping class */
  shipping_class_id: Scalars['Int'];
  /** Whether shipping is required */
  shipping_required: Scalars['Boolean'];
  /** Whether shipping is taxable */
  shipping_taxable: Scalars['Boolean'];
  /** Short description of the product */
  short_description: Scalars['String'];
  /** SKU (Stock Keeping Unit) of the product */
  sku?: Maybe<Scalars['String']>;
  /** URL-friendly slug */
  slug: Scalars['String'];
  /** Whether the product is sold individually */
  sold_individually: Scalars['Boolean'];
  /** Current status of the product */
  status: Scalars['String'];
  /** Current stock quantity */
  stock_quantity?: Maybe<Scalars['Int']>;
  /** Current stock status */
  stock_status: Scalars['String'];
  /** Tags of the product */
  tags: Array<WoocommerceTag>;
  /** Tax class of the product */
  tax_class?: Maybe<Scalars['String']>;
  /** Tax status of the product */
  tax_status: Scalars['String'];
  /** Total number of sales */
  total_sales: Scalars['Int'];
  /** Type of the product (e.g., 'variable') */
  type: Scalars['String'];
  /** Whether the product is virtual */
  virtual: Scalars['Boolean'];
  /** Weight of the product */
  weight?: Maybe<Scalars['String']>;
};

/** Woocommerce product data */
export type WoocommerceResponse = {
  __typename?: 'WoocommerceResponse';
  /** The products. */
  products?: Maybe<Array<Maybe<WoocommerceProduct>>>;
  /** total pages */
  total_pages?: Maybe<Scalars['String']>;
};

export type WoocommerceTag = {
  __typename?: 'WoocommerceTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 pt-4" }
const _hoisted_2 = { class: "w-full flex flex-row px-4" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 px-4"
}
const _hoisted_5 = { class: "!font-semibold" }
const _hoisted_6 = {
  key: 1,
  class: "w-full flex flex-col items-center justify-center h-[350px]"
}
const _hoisted_7 = {
  key: 2,
  class: "w-full flex flex-col"
}
const _hoisted_8 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_10 = { class: "w-full flex flex-col pl-4 !-mt-3" }
const _hoisted_11 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide",
  id: "categoryContainerShoplist"
}
const _hoisted_12 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_13 = ["onClick", "id"]
const _hoisted_14 = { class: "w-full flex flex-row items-center justify-between px-4 pt-2" }
const _hoisted_15 = { class: "w-full flex flex-col" }
const _hoisted_16 = { class: "w-full flex flex-col space-y-2 pt-3" }
const _hoisted_17 = { class: "w-full flex flex-row items-center justify-between px-4" }
const _hoisted_18 = {
  key: 0,
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
}
const _hoisted_19 = { class: "flex flex-row space-x-2 py-1 pr-4" }
const _hoisted_20 = {
  key: 0,
  class: "flex flex-col space-y-3 h-full justify-end"
}
const _hoisted_21 = { class: "w-full flex flex-col space-y-2 pt-3" }
const _hoisted_22 = { class: "w-full flex flex-row items-center justify-between px-4" }
const _hoisted_23 = {
  key: 0,
  class: "w-full flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_loading = _resolveComponent("app-loading")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_shoplist_explore = _resolveComponent("app-shoplist-explore")!
  const _component_app_business = _resolveComponent("app-business")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_app_banner_ad = _resolveComponent("app-banner-ad")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_shoplist_recommended = _resolveComponent("app-shoplist-recommended")!
  const _component_app_shoplist_sponsored = _resolveComponent("app-shoplist-sponsored")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        topBarType: "plain",
        title: "Shop",
        pageKey: 'shoplist'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_text_field, {
                  class: "w-full",
                  placeholder: 'Search for products, shoplists or brands',
                  inputStyle: '',
                  onFocused: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchIsFocused = true)),
                  onBlurred: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchIsFocused = false)),
                  modelValue: _ctx.searchValue,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchValue) = $event))
                }, {
                  "inner-prefix": _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createVNode(_component_app_icon, {
                        "custom-class": '',
                        name: 'search'
                      })
                    ])
                  ]),
                  "inner-suffix": _withCtx(() => [
                    (_ctx.searchValue.length > 2 && _ctx.searchResultsLoading)
                      ? (_openBlock(), _createBlock(_component_app_loading, {
                          key: 0,
                          class: "!text-primary-400 dark:!text-white"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            (_ctx.searchValue.length > 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.ShoplistSearchResults.data.length)
                    ? (_openBlock(), _createBlock(_component_app_normal_text, {
                        key: 0,
                        class: "w-full !text-left"
                      }, {
                        default: _withCtx(() => [
                          _cache[6] || (_cache[6] = _createTextVNode(" Search results for ")),
                          _createElementVNode("span", _hoisted_5, "\"" + _toDisplayString(_ctx.searchValue) + "\"", 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.ShoplistSearchResults.data.length && !_ctx.searchResultsLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_app_empty_state, {
                          title: 'No products found',
                          subTitle: `No products found for '${_ctx.searchValue}'`
                        }, null, 8, ["subTitle"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_app_virtual_scroller, {
                          data: _ctx.ShoplistSearchResults.data,
                          pagination: _ctx.ShoplistSearchResults.paginatorInfo,
                          fetchMore: _ctx.fetchMoreShoplistSearchResults,
                          "container-class": ` grid grid-cols-2 gap-4 !space-y-0`
                        }, {
                          "item-content": _withCtx(({ index, item }) => [
                            (_ctx.itemIsMediaBox(item))
                              ? (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                                  key: index,
                                  item: item,
                                  onClick: ($event: any) => (_ctx.handleShoplsistClick(item))
                                }, null, 8, ["item", "onClick"]))
                              : (_openBlock(), _createBlock(_component_app_business, {
                                  key: 1,
                                  item: item,
                                  onClick: ($event: any) => (_ctx.Logic.Common.GoToRoute(`/brand?uuid=${item.data.uuid}`))
                                }, null, 8, ["item", "onClick"]))
                          ]),
                          "skeleton-loaders": _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("div", { class: "col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton" }, null, -1),
                            _createElementVNode("div", { class: "col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton" }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["data", "pagination", "fetchMore"])
                      ]))
                ]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_app_banner_ad, { data: _ctx.bannerAds }, null, 8, ["data"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryOptions, (category, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "flex flex-row cursor-pointer pr-2",
                              key: index,
                              onClick: ($event: any) => (_ctx.selectedCategory = category.key),
                              id: `category${category.key}`
                            }, [
                              _createElementVNode("div", {
                                class: _normalizeClass(` ${
                        _ctx.selectedCategory == category.key
                          ? 'bg-primary-main'
                          : 'bg-[#f3f3f3] dark:bg-gray-700'
                      } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`)
                              }, [
                                _createVNode(_component_app_normal_text, {
                                  class: _normalizeClass(`!whitespace-nowrap ${
                          _ctx.selectedCategory == category.key
                            ? 'text-white'
                            : 'text-gray-600 dark:text-gray-50'
                        }`)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(category.value), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["class"])
                              ], 2)
                            ], 8, _hoisted_13))
                          }), 128))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_app_header_text, { customClass: '!text-left  !text-base !font-semibold' }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode(" Most Popular Savings Deals ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, {
                        class: "!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (
                  _ctx.Logic.Common.GoToRoute(`/shoplist/products?key=${_ctx.selectedCategory}`)
                ))
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" See all > ")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_app_virtual_scroller, {
                        "container-class": 'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2',
                        data: _ctx.topCashbackOffers.data,
                        pagination: _ctx.topCashbackOffers.paginatorInfo,
                        fetchMore: _ctx.fetchMoreCashbackOffers,
                        fetchNew: _ctx.fetchNewCashbackOffers,
                        direction: 'horizontal',
                        ref: "productVirtualScrollerRef"
                      }, {
                        "item-content": _withCtx(({ index, item }) => [
                          _createElementVNode("div", null, [
                            (_openBlock(), _createBlock(_component_app_shoplist_recommended, {
                              item: item,
                              onClick: ($event: any) => (_ctx.handleShoplsistClick(item)),
                              key: index,
                              hideCategory: true
                            }, null, 8, ["item", "onClick"]))
                          ])
                        ]),
                        "skeleton-loaders": _withCtx(() => _cache[10] || (_cache[10] = [
                          _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                            _createElementVNode("div", { class: "!h-[180px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                          ], -1),
                          _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                            _createElementVNode("div", { class: "!h-[180px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                          ], -1)
                        ])),
                        _: 1
                      }, 8, ["data", "pagination", "fetchMore", "fetchNew"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_app_header_text, { customClass: '!text-left  !text-base !font-semibold' }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Limited Bundle Deals ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, {
                        class: "!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (
                  _ctx.Logic.Common.GoToRoute(
                    `/shoplist/featured?key=${
                      _ctx.selectedCategory == 'All' ? '0' : _ctx.selectedCategory
                    }`
                  )
                ))
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode(" See all > ")
                        ])),
                        _: 1
                      })
                    ]),
                    (_ctx.sponsoredShoplists)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sponsoredShoplists, (item, index) => {
                              return (_openBlock(), _createBlock(_component_app_shoplist_sponsored, {
                                key: index,
                                item: item,
                                onClick: ($event: any) => (
                    _ctx.setStoryContent(_ctx.ContentType.SponsoredShoplist, {
                      id: parseInt(item.id),
                      type: item.type,
                    })
                  )
                              }, null, 8, ["item", "onClick"]))
                            }), 128)),
                            (_ctx.showSponsoredShoplistIsLoading)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[13] || (_cache[13] = [
                                  _createElementVNode("div", { class: "w-[270px] skeleton !rounded-[10px]" }, null, -1)
                                ])))
                              : _createCommentVNode("", true)
                          ]),
                          _cache[14] || (_cache[14] = _createElementVNode("div", { id: "visibility-handle-sponsored-shoplist" }, null, -1))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_app_header_text, { customClass: '!text-left  !text-base !font-semibold' }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createTextVNode(" Top brands ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, {
                        class: "!text-[#525050] dark:!text-primary-300 cursor-pointer !uppercase",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (
                  _ctx.Logic.Common.GoToRoute(
                    `/shoplist/brands?key=${
                      _ctx.selectedCategory == 'All' ? '0' : _ctx.selectedCategory
                    }`
                  )
                ))
                      }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode(" See all > ")
                        ])),
                        _: 1
                      })
                    ]),
                    (_ctx.ManyBusinesses)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createVNode(_component_app_virtual_scroller, {
                            "container-class": 'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2',
                            data: _ctx.ManyBusinesses.data,
                            pagination: _ctx.ManyBusinesses.paginatorInfo,
                            fetchMore: _ctx.fetchMoreBusinesses,
                            direction: 'horizontal'
                          }, {
                            "item-content": _withCtx(({ index, item }) => [
                              (_openBlock(), _createBlock(_component_app_business, {
                                item: item,
                                key: index,
                                onClick: ($event: any) => (_ctx.Logic.Common.GoToRoute(`/brand?uuid=${item.uuid}`))
                              }, null, 8, ["item", "onClick"]))
                            ]),
                            "skeleton-loaders": _withCtx(() => _cache[17] || (_cache[17] = [
                              _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                                _createElementVNode("div", { class: "!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                              ], -1),
                              _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                                _createElementVNode("div", { class: "!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                              ], -1)
                            ])),
                            _: 1
                          }, 8, ["data", "pagination", "fetchMore"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ], 64)),
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
<template>
  <div class="w-[340px] xs:!w-[270px] flex flex-col space-y-2 cursor-pointer" v-if="item">
    <app-media-box
      :data="item.data"
      :class="`h-[270px] xs:!h-[230px] !rounded-[10px] relative ${mediaBoxStyle} border-[1px] border-gray-200 dark:border-gray-700`"
      :isVideo="!item.data.is_grid"
    >
    </app-media-box>
    <div class="w-full flex flex-row items-center justify-between">
      <div class="flex flex-col space-y-1">
        <app-normal-text class="!font-semibold !text-left !text-[13px] !line-clamp-1">
          {{ item.data.title }}
        </app-normal-text>
        <app-normal-text class="!text-[13px] !text-left">
          {{ Logic.Common.convertToMoney(item.data.price || 0) }}
        </app-normal-text>
      </div>

      <div class="flex flex-col space-y-1">
        <app-normal-text
          :class="`!text-grey-600 dark:!text-gray-200 !text-[11px] !text-right ${
            item.data.data?.expires_at ? '' : '!invisible'
          }`"
        >
          Expires {{ Logic.Common.timeFromNow(item.data.data?.expires_at) }}
        </app-normal-text>

        <!-- <app-normal-text
          class="!text-[#575454] dark:!text-gray-300 !text-[12px] "
        >
          ₦{{ numberToAbbrev(item.data.points || 0) }} Cashback
        </app-normal-text> -->

        <span class="flex flex-row space-x-1 justify-end !text-right">
          <!-- <app-icon :name="'badge-game'" :customClass="'h-[11px]'" /> -->
          <app-normal-text
            :customClass="'!text-[11px] !text-grey-400 dark:!text-gray-200  !text-right !font-light !whitespace-nowrap'"
          >
            <span class="!font-semibold !text-[#9D4EDD] !text-[12px]">
              ₦{{ numberToAbbrev(item.data.points || 0) }}
            </span>
            Cashback</app-normal-text
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MediaBox } from "../../types";
import { defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppIcon from "../AppIcon";
import AppMediaBox from "../AppMediaBox";
import { Logic, numberToAbbrev } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppHeaderText,
    AppMediaBox,
  },
  props: {
    item: {
      type: Object as () => {
        data: MediaBox;
        id: string;
        type: "collection" | "ad";
      },
    },
    mediaBoxStyle: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      numberToAbbrev,
      Logic,
    };
  },
  name: "AppShoplistExplore",
});
</script>

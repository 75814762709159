<template>
  <div class="w-full flex flex-row h-full items-center justify-center relative">
    <div
      :class="`h-full w-full mdlg:!w-[70%] lg:!w-[65%]   text-center relative grid grid-cols-12  lg:text-sm mdlg:text-[12px] text-xs bg-white  dark:!bg-black !font-inter`"
      :id="`parentContainer-${pageKey}`"
    >
      <!-- Left side -->
      <teleport to="#app" :disabled="Logic.Common.currentBuildType() == 'web'">
        <div
          class="hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col fixed top-0 h-full z-[9999999] md:!pl-4 bg-white dark:bg-black"
          :style="`width: ${sideBarConfig.left.width}px; left: ${sideBarConfig.left.left}px;`"
          :id="`sidbarContainerLeft-${pageKey}`"
          v-if="
            showWebSideBar ||
            Logic.Common.currentMediaQuery() != 'md' ||
            (Logic.Common.currentMediaQuery() == 'md' &&
              Logic.Common.currentBuildType() == 'web')
          "
        >
          <app-web-side-bar :tabIsActive="tabIsActive" />
        </div>
      </teleport>

      <div
        class="hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col sticky top-0"
      ></div>

      <!-- Main section -->
      <div
        :class="`col-span-12 mdlg:!col-span-6 md:!col-span-8 flex flex-col h-full mdlg:!min-h-screen mdlg:!border-l-[1px] md:!min-h-screen md:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-grey-100  mdlg:dark:!border-grey-700 md:dark:!border-grey-700 ${
          Logic.Common.currentBuildType() == 'web' ? '' : 'overflow-y-auto'
        }`"
        :id="`container-${pageKey}`"
      >
        <!-- Top bar -->
        <app-top-bar
          v-if="
            pageKey != 'home'
              ? true
              : Logic.Common.currentMediaQuery() == 'xs' ||
                Logic.Common.currentMediaQuery() == 'sm'
          "
          :has-notify="hasNotify"
          :has-search="hasSearch"
          :has-story="hasStory"
          :has-shopping-basket="hasShoppingBasket"
          :title="title"
          :type="topBarType"
          :hasAddButton="hasAddButton"
          :addButtonAction="addButtonAction"
          :openSideBar="
            () => {
              Logic.Common.currentMediaQuery() == 'xs' ||
              Logic.Common.currentMediaQuery() == 'sm'
                ? (showSideBar = true)
                : (showSideBar = false);
            }
          "
          :showStory="
            () => {
              setStoryContent(ContentType.Explore, {
                id: 0,
                type: 'collection',
              });
            }
          "
          :greetings="greetings"
          :top-bar-style="'bg-white dark:bg-black'"
        >
          <template #extra-top-bar-right>
            <slot name="extra-top-bar-right" />
          </template>
        </app-top-bar>
        <!-- Extra top bar -->
        <slot name="extra-top-bar" />

        <!-- Top bar minimal for home -->
        <div
          :class="`w-full flex flex-col sticky top-0 !bg-white dark:!bg-black z-50 pb-2 ${topBarVisibility}`"
          style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
          v-if="showMinimalTopBar"
        >
          <div class="w-full flex flex-row items-center justify-between px-4">
            <app-image-loader
              :photoUrl="
                Logic.Common.currentAccountType() == 'merchant'
                  ? Logic.Auth.GetCurrentBusiness()?.photo_url || ''
                  : Logic.Auth.AuthUser?.profile?.photo_url || ''
                  ? Logic.Auth.AuthUser?.profile?.photo_url || ''
                  : '/images/avatar-1.png'
              "
              :customClass="'!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200'"
              @click="showSideBar = true"
            />

            <div class="flex flex-row space-x-2 items-center justify-center">
              <template v-if="isHome">
                <div
                  class="flex flex-col space-y-1 items-center justify-center w-[30px]"
                  v-for="(option, index) in options"
                  :key="index"
                  @click="option.action()"
                >
                  <app-icon :name="option.icon" :customClass="'!h-[30px]'" />
                </div>
              </template>
              <template v-else>
                <app-header-text>
                  {{ title }}
                </app-header-text>
              </template>
            </div>

            <div class="flex flex-row space-x-3 items-center">
              <template v-if="Logic.Common.currentAccountType() == 'client'">
                <template v-if="isHome">
                  <app-icon
                    :name="`shopping-basket-circle${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`"
                    :customClass="'!h-[38px]'"
                  />
                </template>
                <template v-else>
                  <app-icon
                    v-if="hasNotify"
                    :name="`notify-circle${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`"
                    :customClass="'!h-[38px]'"
                  />

                  <app-icon
                    v-if="hasSearch"
                    :name="`search-circle${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`"
                    :customClass="'!h-[38px]'"
                  />

                  <app-icon
                    v-if="hasAddButton"
                    :name="`add-circle${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`"
                    :customClass="'!h-[38px] cursor-pointer'"
                    @click="addButtonAction()"
                  />

                  <app-icon
                    v-if="hasStory"
                    :name="`story-circle${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`"
                    :customClass="'!h-[38px] cursor-pointer'"
                    @click="
                      setStoryContent(ContentType.Explore, {
                        id: 0,
                        type: 'collection',
                      })
                    "
                  />
                  <app-icon
                    v-if="hasShoppingBasket"
                    :name="`shopping-basket-circle${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`"
                    :customClass="'!h-[38px]'"
                  />
                </template>
              </template>
              <template v-else>
                <app-icon
                  v-if="hasNotify || !hasAddButton"
                  :name="`notify-circle${
                    Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                  }`"
                  :customClass="'!h-[38px] cursor-pointer'"
                  @click="Logic.Common.GoToRoute('/others/notifications')"
                />
                <app-icon
                  v-if="hasAddButton"
                  :name="`add-circle${
                    Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                  }`"
                  :customClass="'!h-[38px] cursor-pointer'"
                  @click="addButtonAction()"
                />
              </template>
            </div>
          </div>
          <slot name="extra-top-bar" />
        </div>
        <slot />

        <!-- Bottom blank -->
        <app-fixed-container
          class="w-full bottom-0 left-0 bg-white md:!bg-white dark:bg-black z-[9999999]"
          style="height: calc(env(safe-area-inset-bottom))"
        ></app-fixed-container>
      </div>

      <!-- Right side -->
      <div class="hidden col-span-3 mdlg:!flex flex-col sticky top-0"></div>

      <div
        class="hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full"
        :style="`width: ${sideBarConfig.left.width}px; right: ${sideBarConfig.left.left}px;`"
        :id="`sidbarContainerRight-${pageKey}`"
      >
        <app-board-side-bar />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, reactive, watch, onMounted, defineComponent } from "vue";
import { AppFixedContainer } from "../AppCommon";
import AppIcon from "../AppIcon";
import AppImageLoader from "../AppImageLoader";
import { AppTopBar, AppWebSideBar, AppBoardSideBar } from "../AppNavigator";
import { AppHeaderText } from "../AppTypography";
import {
  bottomNavStyle,
  currentLayout,
  showSideBar,
  tabIsActive,
  tabTitle,
} from "../../composable";
import { Logic } from "../../index";
import { ContentType } from "../../gql/graphql";
import { setStoryContent } from "../../composable/story";

export default defineComponent({
  components: {
    AppTopBar,
    AppImageLoader,
    AppIcon,
    AppHeaderText,
    AppWebSideBar,
    AppBoardSideBar,
    AppFixedContainer,
  },
  props: {
    topBarType: {
      type: String,
      default: "full",
    },
    title: {
      type: String,
      default: "",
    },
    hasNotify: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    hasCart: {
      type: Boolean,
      default: false,
    },
    isHome: {
      type: Boolean,
      default: false,
    },
    hasStory: {
      type: Boolean,
      default: true,
    },
    hasShoppingBasket: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: false,
    },
    addButtonAction: {
      type: Function,
      default: () => {
        //
      },
    },
    pageKey: {
      type: String,
      default: "home",
    },
    scrollUpThreshold: {
      type: Number,
      default: 90,
    },
    greetings: {
      type: String,
      default: "Welcome",
    },
  },

  setup(props) {
    const router = Logic.Common.router;
    const route = Logic.Common.route;

    const uniqueKey = Logic.Common.makeid(13);
    const pagePath = ref("");

    const showWebSideBar = ref(false);

    const goBack = () => {
      window.history.length > 1 ? router?.go(-1) : router?.push("/");
    };

    const goToRoute = (route: string) => {
      router?.push(route);
    };

    const sideBarConfig = reactive({
      left: {
        width: 0,
        left: 0,
      },
      right: {
        width: 0,
        left: 0,
      },
    });

    const scrollHeight = ref(0);
    const currentScrollAction = ref<"scrolling_up" | "scrolling_down">("scrolling_up");
    const lastScrollTop = ref(0);

    const showMinimalTopBar = ref(false);
    const topBarVisibility = ref("");

    watch(scrollHeight, () => {
      const safeAreaInsetTop = parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue("--safe-area-inset-top")
          .trim(),
        10
      );
      let scrollUpThreshold = 150 + safeAreaInsetTop;
      let scrollDownThreshold = 100 + safeAreaInsetTop;

      if (!props.isHome) {
        scrollUpThreshold = props.scrollUpThreshold + safeAreaInsetTop;
        scrollDownThreshold = 50 + safeAreaInsetTop;
      }

      if (currentScrollAction.value == "scrolling_down") {
        if (scrollHeight.value >= scrollDownThreshold) {
          bottomNavStyle.value = "opacity-[70%]";
        } else {
          bottomNavStyle.value = "";
        }
        topBarVisibility.value = "opacity-[0%]";
      } else if (currentScrollAction.value == "scrolling_up") {
        bottomNavStyle.value = "";
        topBarVisibility.value = "";
        if (scrollHeight.value >= scrollUpThreshold) {
          showMinimalTopBar.value = true;
        } else {
          showMinimalTopBar.value = false;
        }
      }
    });

    const options = reactive([
      {
        title: "What's New",
        icon: "options/whats-new",
        action: () => {
          setStoryContent(ContentType.WhatsNew, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Favorite",
        icon: "options/favourite",
        action: () => {
          setStoryContent(ContentType.Favorite, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Limited",
        icon: "options/limited",
        action: () => {
          setStoryContent(ContentType.Limited, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Explore",
        icon: "options/explore",
        action: () => {
          setStoryContent(ContentType.Explore, {
            id: 0,
            type: "collection",
          });
        },
      },
    ]);

    const listenToContainerScroll = () => {
      const handlePageScroll = () => {
        let scrollElement =
          Logic.Common.currentBuildType() == "web"
            ? window
            : document.getElementById(`container-${props.pageKey}`);

        if (!scrollElement) return;

        let scrollTop =
          Logic.Common.currentBuildType() == "web"
            ? window.pageYOffset || document.documentElement.scrollTop
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              scrollElement.scrollTop;

        if (scrollTop < lastScrollTop.value) {
          currentScrollAction.value = "scrolling_up";
          scrollHeight.value = scrollTop;
        } else {
          currentScrollAction.value = "scrolling_down";
          scrollHeight.value = scrollTop;
        }
        lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
      };

      if (Logic.Common.currentBuildType() == "web") {
        window.addEventListener("scroll", handlePageScroll, false);
      } else {
        const container = document.getElementById(`container-${props.pageKey}`);
        if (container) {
          container.addEventListener("scroll", handlePageScroll, false);
        }
      }
      window.addEventListener(
        "scroll",
        () => {
          handlePageScroll();
        },
        false
      );
    };

    const calculateSideBarPosition = () => {
      const parentContainer = document.getElementById(`parentContainer-${props.pageKey}`);
      if (parentContainer) {
        const parentWidth = parentContainer.offsetWidth;
        const sidebarWidth =
          parentWidth * (Logic.Common.currentMediaQuery() != "md" ? 0.25 : 0.33); // 25% or 33% of parent width
        const parentLeftOffset = parentContainer.offsetLeft;

        // Calculate the right edge of the parent container
        const parentRightEdge = parentLeftOffset + parentWidth;

        // Update sideBarConfig for both left and right sidebars
        sideBarConfig.left = {
          width: sidebarWidth,
          left: parentLeftOffset,
        };
        sideBarConfig.right = {
          width: sidebarWidth,
          left: parentRightEdge,
        };
      }
    };

    watch(currentLayout, () => {
      if (currentLayout.value?.path.toString() === pagePath.value) {
        showWebSideBar.value = true;
      } else {
        showWebSideBar.value = false;
      }
      if (
        Logic.Common.currentMediaQuery() === "md" &&
        Logic.Common.currentBuildType() == "mobile"
      ) {
        setTimeout(calculateSideBarPosition, 100);
      } else {
        calculateSideBarPosition();
      }
    });

    onMounted(() => {
      setTimeout(listenToContainerScroll, 500);
      pagePath.value = route?.path || "";
    });

    return {
      currentLayout,
      showMinimalTopBar,
      Logic,
      options,
      tabTitle,
      currentScrollAction,
      topBarVisibility,
      showSideBar,
      ContentType,
      sideBarConfig,
      route,
      showWebSideBar,
      uniqueKey,
      setStoryContent,
      goBack,
      goToRoute,
      tabIsActive,
    };
  },
});
</script>

import { CombinedError } from 'urql'
import { Logic } from '..'
import {
  AppContent,
  Business,
  BusinessAdminPaginator,
  ContentType,
  DataDate,
  LeaderboardUser,
  MutationAddUserToWaitlistArgs,
  MutationFollowUnfollowBusinessArgs,
  MutationInviteBusinessAdminArgs,
  MutationSaveUserActivityArgs,
  MutationSaveUserPushNotificationDeviceArgs,
  MutationSetTransactionPinArgs,
  MutationUpdateBusinessAdminArgs,
  MutationUpdateBusinessProfileArgs,
  MutationUpdateProfileArgs,
  NotificationPaginator,
  PollSummary,
  Profile,
  QueryGetExpensesAnalysisArgs,
  ReferralSummary,
  SubscriptionPlan,
  UserContent,
  User as UserModel,
} from '../../gql/graphql'
import { $api } from '../../services'
import Common from './Common'

export default class User extends Common {
  constructor() {
    super()
  }

  // Default variables
  private CurrentTaskReference: string = ''
  public UserProfile: Profile | undefined
  public ManySubscriptionPlans: SubscriptionPlan[] | undefined
  public SearchResult: UserModel[] | undefined
  public DailyLeaderboard: LeaderboardUser[] | undefined
  public WeeklyLeaderboard: LeaderboardUser[] | undefined
  public MonthlyLeaderboard: LeaderboardUser[] | undefined
  public GlobalLeaderboard: LeaderboardUser[] | undefined
  public MyFriendsLeaderboard: LeaderboardUser[] | undefined
  public Notifications: NotificationPaginator | undefined
  public ClientAppContent: AppContent | undefined
  public ExpensesAnalysis: DataDate[] | undefined
  // Content types - whats_new, favorite, limited, recommended_for_you, explore, sponsored_shoplist, banner_ads
  public WhatsNewContent: UserContent | undefined
  public FavoriteContent: UserContent | undefined
  public LimitedContent: UserContent | undefined
  public RecommendedForYouContent: UserContent | undefined
  public ExploreContent: UserContent | undefined
  public SponsoredShoplistContent: UserContent | undefined
  public BannerAdsContent: UserContent | undefined

  public MerchantShoplistsContent: UserContent | undefined
  public BusinessProfile: Business | undefined
  public UnClaimedPointEarnedCount: number | undefined
  public ReferralSummary: ReferralSummary | undefined
  public ManyBusinessAdmins: BusinessAdminPaginator | undefined

  // Mutation input variables
  public SetTransactionPinForm: MutationSetTransactionPinArgs | undefined
  public UpdateProfileForm: MutationUpdateProfileArgs | undefined
  public UpdateBusinessProfileForm:
    | MutationUpdateBusinessProfileArgs
    | undefined
  public SavePushNotificationDeviceForm:
    | MutationSaveUserPushNotificationDeviceArgs
    | undefined
  public SaveUserActivityForm: MutationSaveUserActivityArgs = {
    event: '',
    type: '',
    user_uuid: '',
    metadata: '',
    stage_type: '',
    extra_uuid: '',
    task_reference: '',
    user_id: '',
  }
  public AddUserToWaitlistForm: MutationAddUserToWaitlistArgs | undefined
  public InviteBusinessAdminForm: MutationInviteBusinessAdminArgs | undefined
  public UpdateBusinessAdminForm: MutationUpdateBusinessAdminArgs | undefined
  public FollowUnfollowBusinessForm:
    | MutationFollowUnfollowBusinessArgs
    | undefined

  // Query actions
  public GetUserProfile = () => {
    return $api.user
      .GetUserProfile()
      .then((response) => {
        this.UserProfile = response.data?.GetUserProfile
        if (Logic.Auth.AuthUser) {
          Logic.Auth.AuthUser.profile = response.data?.GetUserProfile
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetImageThumbnail = (image_url: string) => {
    return $api.user.GetImageThumbnail(image_url).then((response) => {
      return response.data?.GetImageThumbnail
    })
  }

  public GetUnreadNotificationsCount = () => {
    return $api.user
      .GetUnreadNotificationsCount()
      .then((response) => {
        Logic.Notification.UnreadNotification =
          response.data?.GetUnreadNotificationsCount || 0
        return response.data?.GetUnreadNotificationsCount
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetUnClaimedPointEarnedCount = () => {
    return $api.user
      .GetUnClaimedPointEarnedCount()
      .then((response) => {
        this.UnClaimedPointEarnedCount =
          response.data?.GetUnClaimedPointEarnedCount
        return response.data?.GetUnClaimedPointEarnedCount
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }

  public GetBusinessProfile = (uuid: string) => {
    return $api.user
      .GetBusinessProfile(uuid)
      .then((response) => {
        this.BusinessProfile = response.data?.GetBusinessProfile
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetReferralSummary = () => {
    return $api.user
      .GetReferralSummary()
      .then((response) => {
        this.ReferralSummary = response.data?.GetAuthUser
          ?.referral_summary as ReferralSummary

        if (Logic.Auth.AuthUser) {
          Logic.Auth.AuthUser.referral_summary = this.ReferralSummary
          Logic.Auth.saveAuthUser()
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserContent = (
    business_uuid = '',
    contentType: ContentType,
    page: number,
    perPage: number,
    with_product = false,
    isUpdate = false,
    enforced_content_type = '',
    enforced_content_uuid = '',
  ) => {
    if (business_uuid == 'empty') {
      business_uuid = ''
    }
    return $api.user
      .GetUserContent(contentType, page, perPage, business_uuid, with_product, enforced_content_uuid, enforced_content_type)
      .then((response) => {
        if (!isUpdate) {
          switch (contentType) {
            case ContentType.WhatsNew:
              this.WhatsNewContent = response.data?.GetUserContents
              break
            case ContentType.Favorite:
              this.FavoriteContent = response.data?.GetUserContents
              break
            case ContentType.Limited:
              this.LimitedContent = response.data?.GetUserContents
              break
            case ContentType.RecommendedForYou:
              this.RecommendedForYouContent = response.data?.GetUserContents
              break
            case ContentType.Explore:
              this.ExploreContent = response.data?.GetUserContents
              break
            case ContentType.SponsoredShoplist:
              this.SponsoredShoplistContent = response.data?.GetUserContents
              break
            case ContentType.BannerAds:
              this.BannerAdsContent = response.data?.GetUserContents
              break
            case ContentType.BusinessContents:
              this.MerchantShoplistsContent = response.data?.GetUserContents
              break
            default:
              break
          }
        }
        return response.data?.GetUserContents
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public UpdatePollVote = (advert_uuid: string, answer: string) => {
    const allDataToUpdate = [
      'WhatsNewContent',
      'FavoriteContent',
      'LimitedContent',
      'RecommendedForYouContent',
      'ExploreContent',
      'SponsoredShoplistContent',
      'BannerAdsContent',
      'MerchantShoplistsContent',
    ]

    allDataToUpdate.forEach((baseData) => {
      // @ts-ignore
      if (Logic.User[baseData]) {
        // @ts-ignore
        Logic.User[baseData].contents.forEach((item) => {
          if (item.type == 'ad') {
            if (item.data.type == 'polls' && item.data.uuid == advert_uuid) {
              let pollSummary: PollSummary[] =
                item.data.poll_summary.map((item: PollSummary) => {
                  if (item.slug == answer) {
                    item.count += 1
                  }
                  return item
                }) || []

              // update poll percentage
              const totalVotes = pollSummary.reduce(
                (acc, curr) => acc + curr.count,
                0,
              )
              pollSummary =
                pollSummary.map((item: PollSummary) => {
                  item.percentage =
                    totalVotes > 0 ? (item.count / totalVotes) * 100 : 0
                  return item
                }) || []

              item.data.poll_summary = pollSummary
            }
          }
        })
      }
    })
  }

  public GetSubscriptionPlans = () => {
    return $api.user
      .GetSubscriptionPlans()
      .then((response) => {
        this.ManySubscriptionPlans = response.data?.GetSubscriptionPlans
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public SearchUser = (query: string) => {
    return $api.user.SearchUser(query).then((response) => {
      this.SearchResult = response.data?.SearchUser
    })
  }

  public GetDailyGlobalLeaderBoard = () => {
    return $api.user
      .GetGlobalLeaderBoard('day')
      .then((response) => {
        this.DailyLeaderboard = response.data?.GetGlobalLeaderBoard
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetExpensesAnalysis = (data: QueryGetExpensesAnalysisArgs) => {
    return $api.user
      .GetExpensesAnalysis(data)
      .then((response) => {
        this.ExpensesAnalysis = response.data?.GetExpensesAnalysis
        return response.data?.GetExpensesAnalysis
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetClientAppContent = () => {
    return $api.user
      .GetClientAppContent()
      .then((response) => {
        this.ClientAppContent = response.data?.AppContent
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetWeeklyGlobalLeaderBoard = () => {
    return $api.user
      .GetGlobalLeaderBoard('week')
      .then((response) => {
        this.WeeklyLeaderboard = response.data?.GetGlobalLeaderBoard
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetMonthlyGlobalLeaderBoard = () => {
    return $api.user
      .GetGlobalLeaderBoard('month')
      .then((response) => {
        this.MonthlyLeaderboard = response.data?.GetGlobalLeaderBoard
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetGlobalLeaderBoard = () => {
    return $api.user
      .GetGlobalLeaderBoard('global')
      .then((response) => {
        this.GlobalLeaderboard = response.data?.GetGlobalLeaderBoard
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetMyFriendsLeaderBoard = () => {
    return $api.user
      .GetGlobalLeaderBoard('my_friends')
      .then((response) => {
        this.MyFriendsLeaderboard = response.data?.GetGlobalLeaderBoard
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetNotifications = (page: number, first: number, isUpdate = false) => {
    return $api.user
      .GetNotifications(
        first,
        page,
        'CREATED_AT',
        'DESC',
        `{
        column: UUID
        operator: EQ
        value: "${Logic.Auth.AuthUser?.uuid}"
      }`,
      )
      .then((response) => {
        if (isUpdate) {
          return response.data?.GetNotifications
        }

        this.Notifications = response.data?.GetNotifications

        if (this.Notifications) {
          Logic.Notification.UnreadNotification = this.Notifications.data.filter(
            (item) => !item.unread,
          ).length
        }

        return response.data?.GetNotifications
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetBusinessAdmins = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    hasBusiness = '',
    whereQuery = '',
  ) => {
    return $api.user
      .GetBusinessAdmins(count, page, orderType, order, hasBusiness, whereQuery)
      .then((response) => {
        this.ManyBusinessAdmins = response.data?.GetBusinessAdmin
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  // Mutation actions
  public SetTransactionPin = () => {
    if (this.SetTransactionPinForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.user
        .SetTransactionPin(this.SetTransactionPinForm)
        .then((response) => {
          // Logic.Common.hideLoader()
          this.UserProfile = this.updatedData(
            this.UserProfile,
            response.data?.SetTransactionPin,
          )
          Logic.User.SaveUserActivity('Set Transaction PIN', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Set Transaction PIN`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public UpdateProfile = (isSlient = false) => {
    if (this.UpdateProfileForm) {
      if (!isSlient) {
        Logic.Common.showLoader({
          loading: true,
        })
      }

      return $api.user
        .UpdateProfile(this.UpdateProfileForm)
        .then((response) => {
          Logic.Common.hideLoader()

          if (!isSlient) {
            Logic.Auth.GetAuthUser()

            Logic.User.SaveUserActivity('Update Profile', 'action')
          } else {
            this.GetUserProfile()
          }
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Update Profile`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public FollowUnfollowBusiness = () => {
    if (this.FollowUnfollowBusinessForm) {
      return $api.user
        .FollowUnfollowBusiness(this.FollowUnfollowBusinessForm)
        .then((response) => {
          return response.data?.FollowUnfollowBusiness
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public InviteBusinessAdmin = () => {
    if (this.InviteBusinessAdminForm) {
      Logic.Common.showLoader({
        loading: true,
      })

      return $api.user
        .InviteBusinessAdmin(this.InviteBusinessAdminForm)
        .then((response) => {
          Logic.Common.hideLoader()
          Logic.User.SaveUserActivity('Invite Business Admin', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Invite Business Admin`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public AcceptBusinessInvitation = (invitation_code: string) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.user
      .AcceptBusinessInvitation(invitation_code)
      .then((response) => {
        Logic.User.SaveUserActivity('Accept Business Invitation', 'action')
        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Accept Business Invitation`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public UpdateBusinessAdmin = () => {
    if (this.UpdateBusinessAdminForm) {
      Logic.Common.showLoader({
        loading: true,
      })

      return $api.user
        .UpdateBusinessAdmin(this.UpdateBusinessAdminForm)
        .then((response) => {
          Logic.Common.hideLoader()
          Logic.User.SaveUserActivity('Update Business Admin', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Update Business Admin`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public UpdateBusinessProfile = () => {
    if (this.UpdateBusinessProfileForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.user
        .UpdateBusinessProfile(this.UpdateBusinessProfileForm)
        .then((response) => {
          Logic.Common.hideLoader()
          Logic.User.SaveUserActivity('Update Business Profile', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Update Business Profile`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public UpdateUserSubscription = (
    subscription_plan_uuid: string,
    name: string,
  ) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.user
      .UpdateUserSubscription(subscription_plan_uuid)
      .then((response) => {
        Logic.Auth.GetAuthUser()
        Logic.Common.hideLoader()
        Logic.Common.showSuccess(
          `Your ${name} subscription was successful.`,
          () => {
            Logic.Common.showModal({ show: false })
            Logic.Common.goBack()
          },
        )
        Logic.User.SaveUserActivity('Update User Subscription', 'action')
        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Update User Subscription`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public CreateShortCode = (content: string) => {
    return $api.user.CreateShortCode({ content })
  }

  public ConnectShopifyStore = (shopify_store_url: string) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.user
      .ConnectShopifyStore({
        shopify_store_url,
      })
      .then((response) => {
        return response.data?.ConnectShopifyStore
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public SaveUserPushNotificationDevice = (formIsValid: boolean) => {
    if (formIsValid && this.SavePushNotificationDeviceForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.user
        .SavePushNotificationDevice(this.SavePushNotificationDeviceForm)
        .then((_response) => {
          //
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Save Push Notification Token`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public ClaimPointGained = (month: string) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.user
      .ClaimPointGained(month)
      .then((response) => {
        if (response.data?.ClaimPointGained) {
          Logic.Common.showSuccess(
            `${month} Cashback Claimed! Your cashback savings has been credited to your cashback balance`,
          )
          Logic.Auth.GetAuthUser()
          Logic.User.SaveUserActivity('Claim Cashback For Month', 'action')
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Claim Cashback For Month`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public AddUserToWaitlist = () => {
    if (this.AddUserToWaitlistForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.user
        .AddUserToWaitlist(this.AddUserToWaitlistForm)
        .then((response) => {
          if (response.data?.AddUserToWaitlist) {
            Logic.Common.showSuccess(
              `You are now waiting! Please, check your email for your referral code.`,
            )
            Logic.User.SaveUserActivity('Join Waitlist', 'action')
          }
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Join Waitlist`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public SaveUserActivity = (
    event: string,
    eventType: string,
    metadata: any = undefined,
    extraData:
      | {
          lable: string
          stage_type: string
          end_stage: string
          get_extra_uuid?: Function | undefined
        }
      | undefined = undefined,
  ) => {
    if (this.SaveUserActivityForm) {
      // save last activity time
      localStorage.setItem('last_activity_period', Date.now().toString())

      if (eventType == 'page_view') {
        //   FirebaseCrashlytics.setContext({
        //     key: 'page',
        //     value: extraData?.lable || 'New page',
        //     type: 'string',
        //   })
      }

      this.SaveUserActivityForm.user_uuid = Logic.Auth.AuthUser?.uuid || ''
      this.SaveUserActivityForm.user_id = Logic.Auth.AuthUser?.id || ''
      this.SaveUserActivityForm.stage_type = extraData
        ? extraData.stage_type
        : 'neutral'
      if (extraData) {
        if (extraData.stage_type == 'start') {
          this.CurrentTaskReference = Logic.Common.makeid(16)
        }

        if (extraData.stage_type == 'action') {
          this.SaveUserActivityForm.stage_type = 'end'
        }

        this.SaveUserActivityForm.extra_uuid = extraData.get_extra_uuid
          ? extraData.get_extra_uuid()
          : undefined
      } else {
        this.SaveUserActivityForm.extra_uuid = undefined
      }

      this.SaveUserActivityForm.task_reference = this.CurrentTaskReference
        ? this.CurrentTaskReference
        : undefined

      if (eventType == 'action') {
        this.SaveUserActivityForm.stage_type = 'end'
      }

      this.SaveUserActivityForm.event = event
      this.SaveUserActivityForm.type = eventType

      this.SaveUserActivityForm.metadata = metadata
        ? JSON.stringify(metadata)
        : undefined

      if (this.SaveUserActivityForm.metadata && extraData?.lable) {
        const metaData = JSON.parse(this.SaveUserActivityForm.metadata)
        metaData['label'] = extraData?.lable
        this.SaveUserActivityForm.metadata = JSON.stringify(metaData)
      }

      if (this.SaveUserActivityForm.metadata == undefined) {
        if (extraData?.lable) {
          this.SaveUserActivityForm.metadata = JSON.stringify({
            label: extraData?.lable,
          })
        }
      }

      $api.user
        .SaveUserActivity(this.SaveUserActivityForm)
        .then(() => {
          //
        })
        .catch((_error: CombinedError) => {
          //
        })
    }
  }

  public DeleteUser = () => {
    Logic.Common.showLoader({
      loading: true,
      show: true,
      useModal: true,
    })

    return $api.user
      .DeleteUser()
      .then((response) => {
        if (response.data?.DeleteUser) {
          this.SaveUserActivity('Delete Account', 'action')
          Logic.Auth.SignOut()
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Delete Account`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }
}

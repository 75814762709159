import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 pb-4 border-b-[1px] border-[#E8E8E8] dark:border-gray-500"
}
const _hoisted_3 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col space-y-1 bg-[#F3F3F3] dark:!bg-gray-800 rounded-[10px] px-3 py-3"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full flex flex-row items-center justify-center h-[60px] bg-[#F3F3F3] dark:!bg-gray-900 rounded-[10px] py-4"
}
const _hoisted_6 = { class: "w-full flex flex-col space-y-2 pb-4" }
const _hoisted_7 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_8 = { class: "flex flex-row px-4 py-1 bg-primary-50 dark:!bg-primary-500 rounded-[30px]" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-3 bg-grey-50 dark:!bg-gray-900 rounded-[5px] px-3 py-2 justify-between" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_11 = { class: "w-full flex flex-col space-y-2 pb-2" }
const _hoisted_12 = {
  class: /*@__PURE__*/_normalizeClass(`flex flex-row items-center space-x-2 `)
}
const _hoisted_13 = { class: "w-[35px]" }
const _hoisted_14 = { class: "flex flex-col items-start space-y-[2px]" }
const _hoisted_15 = {
  key: 0,
  class: "px-2 py-[1px] bg-red-500 text-white text-[9px] rounded-full"
}
const _hoisted_16 = {
  key: 0,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_17 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_18 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_19 = {
  key: 0,
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
}
const _hoisted_20 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "w-[35px] flex items-center justify-center" }
const _hoisted_23 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_24 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_25 = { class: "w-full flex flex-row items-center justify-between space-x-1 pt-[1px]" }
const _hoisted_26 = { class: "w-full flex flex-col border-t-[1px] border-grey-300 py-3 space-y-2" }
const _hoisted_27 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }
const _hoisted_28 = { class: "flex flex-row items-center space-x-1 justify-between w-full border-b-[1px] border-grey-300 pb-2" }
const _hoisted_29 = { class: "flex flex-row items-center space-x-2 justify-start w-full" }
const _hoisted_30 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }
const _hoisted_31 = { class: "w-full flex flex-col space-y-2 pt-2 border-t-[1px] border-grey-300" }
const _hoisted_32 = { class: "flex flex-row items-center space-x-2 justify-start w-full" }
const _hoisted_33 = { class: "w-full flex flex-col px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_app_ratings = _resolveComponent("app-ratings")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        mainSectionClass: 
        _ctx.Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      ,
        title: 'Checkout'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.hasAtLeastOneProductWithDelivery)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_app_header_text, { class: "!text-sm !text-left" }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Delivery Info ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: _normalizeClass(`flex flex-row items-center space-x-1 cursor-pointer ${
                _ctx.hasAtLeastOneProductWithDelivery ? 'opacity-100' : 'opacity-50'
              }`),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showDeliveryAddressModal && _ctx.showDeliveryAddressModal(...args)))
                    }, [
                      _createVNode(_component_app_icon, {
                        name: 'edit-purple',
                        "custom-class": "h-[12px]"
                      }),
                      _createVNode(_component_app_normal_text, { class: "!text-[#9D4EDD]" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" Set delivery address ")
                        ])),
                        _: 1
                      })
                    ], 2)
                  ]),
                  (_ctx.selectedDeliveryInfo)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-300 !font-semibold" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedDeliveryInfo?.contact_name), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { class: "text-left !text-[11px]" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedDeliveryInfo?.delivery_address?.name), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { class: "text-left !text-[11px]" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedDeliveryInfo?.contact_phone), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-50" }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode(" No delivery address set yet ")
                          ])),
                          _: 1
                        })
                      ]))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_header_text, { class: "!text-sm !text-left" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Order Summary ")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_app_normal_text, { class: "!text-primary-500 !font-semibold !text-[11px] dark:!text-primary-50" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.totalReward || 0)) + " Cashback " + _toDisplayString(_ctx.checkoutSetup.totalDiscount
                    ? `and (${_ctx.Logic.Common.convertToMoney(
                        _ctx.checkoutSetup.totalDiscount,
                        true,
                        "ngn"
                      )} off)`
                    : ""), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedProducts, (item, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" From " + _toDisplayString(item.business_name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.products, (product, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: _normalizeClass(`w-full flex flex-row items-center justify-between space-x-2`)
                        }, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", {
                              class: _normalizeClass(`w-[35px]  ${
                        product.available_in_area ? 'opacity-100' : '!opacity-50'
                      }`)
                            }, [
                              _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_app_image_loader, {
                                  photoUrl: product.base_image,
                                  "custom-class": "h-[35px] w-[35px] rounded-[2px]"
                                }, null, 8, ["photoUrl"])
                              ])
                            ], 2),
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_app_normal_text, {
                                class: _normalizeClass(`!text-[#202020] !line-clamp-2 text-left dark:!text-gray-300 ${
                          product.available_in_area ? '' : '!text-grey-500'
                        }`)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(product.title) + " (x" + _toDisplayString(product.qty) + ") ", 1)
                                ]),
                                _: 2
                              }, 1032, ["class"]),
                              (!product.available_in_area)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, " Not Available "))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(`!whitespace-nowrap !font-semibold dark:!text-white ${
                      product.available_in_area
                        ? ''
                        : '!text-grey-500 dark:!text-gray-500'
                    }`)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(product.price, false, "ngn")), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ]))
                      }), 128))
                    ]),
                    (
                  (_ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate?.couriers
                    ?.length || 0) > 0 || _ctx.loadingCouriers
                )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_app_normal_text, { class: "font-semibold text-left" }, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode(" Select a delivery courier ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_app_badge, { color: 'purple' }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.applyDeliveryDiscountForUser(0).comment), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            (_ctx.loadingCouriers)
                              ? (_openBlock(), _createBlock(_component_app_loading_state, {
                                  key: 0,
                                  class: "text-black"
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          (
                    _ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate.couriers
                  )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentBusinessShippingRate(
                        item.business_id
                      )?.shipping_rate.couriers, (courier, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: index,
                                      class: _normalizeClass(`w-[210px] flex flex-row items-start space-x-2 bg-white dark:bg-gray-900 rounded-[5px] px-2 py-2 border-[1px] cursor-pointer ${
                        _ctx.selectedCourier[item.business_id]?.courier_id ==
                        courier.courier_id
                          ? 'border-primary-400 border-[2px] dark:border-primary-400  '
                          : 'border-grey-200 dark:border-gray-100'
                      }`),
                                      onClick: ($event: any) => (
                        _ctx.selectCourier(
                          courier,
                          item.business_id,
                          _ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate
                            ?.request_token || ''
                        )
                      )
                                    }, [
                                      _createElementVNode("div", _hoisted_22, [
                                        _createVNode(_component_app_image_loader, {
                                          photoUrl: courier.courier_image,
                                          "custom-class": "h-[35px] w-[35px]"
                                        }, null, 8, ["photoUrl"])
                                      ]),
                                      _createElementVNode("div", _hoisted_23, [
                                        _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(courier.courier_name), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createElementVNode("div", _hoisted_24, [
                                          _createVNode(_component_app_normal_text, { class: "!font-bold" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                                _ctx.applyDeliveryDiscountForUser(courier.total).amount,
                                false,
                                "ngn"
                              )), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _createElementVNode("div", _hoisted_25, [
                                          (
                              _ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.fastest_courier.courier_id != courier.courier_id &&
                              _ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.cheapest_courier.courier_id != courier.courier_id
                            )
                                            ? (_openBlock(), _createBlock(_component_app_ratings, {
                                                key: 0,
                                                rating: courier.ratings,
                                                "custom-class": "!h-[11px]"
                                              }, null, 8, ["rating"]))
                                            : _createCommentVNode("", true),
                                          (
                              _ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.fastest_courier.courier_id == courier.courier_id
                            )
                                            ? (_openBlock(), _createBlock(_component_app_badge, {
                                                key: 1,
                                                color: 'green',
                                                class: "!text-[9px]"
                                              }, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                  _createTextVNode(" Fastest ")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          (
                              _ctx.currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.cheapest_courier.courier_id == courier.courier_id
                            )
                                            ? (_openBlock(), _createBlock(_component_app_badge, {
                                                key: 2,
                                                color: 'purple',
                                                class: "!text-[9px]"
                                              }, {
                                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                  _createTextVNode(" Cheapest ")
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true)
                                        ])
                                      ])
                                    ], 10, _hoisted_21))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          _cache[8] || (_cache[8] = _createElementVNode("div", null, null, -1))
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-300" }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Delivery Fee ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.deliveryFee, false, "ngn")), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-300" }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode(" Sub Total ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                        item.finalCost + item.deliveryFee,
                        false,
                        "ngn"
                      )), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-300" }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode(" Cashback Savings: ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.finalReward, false, "ngn")), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ], 64))
                }), 128)),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-300" }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" Total ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.totalCost, false, "ngn")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-300" }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode(" Total Cashback: ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.totalReward, false, "ngn")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "h-[100px] w-full" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, { customClass: "!px-0 pt-4 bg-white dark:!bg-black" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "bg-color": 'bg-primary-400',
                  "text-color": 'text-white',
                  class: _normalizeClass(`w-full ${_ctx.formIsValid ? 'opacity-100' : 'opacity-50'}`),
                  onClick: _ctx.showReviewPayment,
                  loading: _ctx.purchaseIsLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Pay (" + _toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.totalCost, false, "ngn")) + ") ", 1)
                  ]),
                  _: 1
                }, 8, ["class", "onClick", "loading"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["mainSectionClass"])
    ]),
    _: 1
  }))
}
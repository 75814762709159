<template>
  <app-wrapper>
    <subpage-layout :title="'Buy Electricity'">
      <!-- Beneficiaries -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            class="!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center"
          >
            <app-icon
              :name="`beneficiary${
                Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`"
              custom-class="!h-[12px]"
            />
            <app-normal-text class="!text-primary-500 dark:!text-primary-100">
              Beneficiaries
            </app-normal-text>
          </app-button>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-3 px-4">
        <app-form-wrapper
          :parentRefs="parentRefs"
          ref="formComp"
          class="w-full flex flex-col space-y-4"
        >
          <!-- Select biller -->
          <app-select
            :placeholder="'Select Biller'"
            :hasTitle="true"
            :paddings="'py-3 px-3'"
            :options="billersOptons"
            @on-option-selected="handleBillerSelected"
            :updateKey="electricityFormData.biller_name"
            :auto-complete="true"
            custom-class="dark:!bg-grey-900"
            v-model="electricityFormData.biller_name"
            :theme="Logic.Common.currentAppTheme()"
          >
            <template v-slot:title> Biller </template>
          </app-select>

          <!-- Select payment item -->
          <app-select
            :placeholder="'Select payment plan'"
            :hasTitle="true"
            :paddings="'py-3 px-3'"
            :options="billerItems"
            :updateKey="electricityFormData.payment_code"
            v-model="electricityFormData.payment_code"
            custom-class="dark:!bg-grey-900"
            :theme="Logic.Common.currentAppTheme()"
          >
            <template v-slot:title> Payment item </template>
          </app-select>

          <!-- Meter number -->
          <div class="w-full flex flex-col space-y-2">
            <app-text-field
              :placeholder="'Enter meter number'"
              :hasTitle="true"
              type="tel"
              v-model="meterNumber"
              :rules="[FormValidations.RequiredRule]"
              :update-value="meterNumber"
              name="Meter number"
              :content-rule="{
                addAfterCount: 0,
                characterToAdd: ``,
                max: 11,
              }"
            >
              <template v-slot:title> Meter number </template>
            </app-text-field>
            <div
              class="w-full flex flex-row items-center justify-end"
              v-if="meterNumber.length >= 7 && resolveTrials > 0"
            >
              <app-normal-text
                :class="
                  isResolvingNumber
                    ? '!text-secondary-main dark:!text-secondary-100'
                    : resolveIsError
                    ? '!text-red-500 dark:!text-red-300'
                    : '!text-primary-500 dark:!text-primary-300'
                "
              >
                {{
                  isResolvingNumber
                    ? "Validating number..."
                    : resolveIsError
                    ? "Unable to resolve meter number"
                    : electricityBiller
                }}
              </app-normal-text>
            </div>
          </div>

          <!-- Amount -->
          <div class="w-full flex flex-col space-y-2 pb-4 xs:pb-2">
            <!-- Amount field -->
            <app-text-field
              :placeholder="'Enter amount'"
              :hasTitle="true"
              :type="'tel'"
              :isFormatted="true"
              :rules="[FormValidations.RequiredRule]"
              ref="amount"
              name="amount"
              :update-value="amountValue"
              v-model="amountValue"
            >
              <template v-slot:title> Amount </template>
              <template v-slot:inner-prefix>
                <app-normal-text class="dark:!text-gray-200">
                  ₦
                </app-normal-text>
              </template>
            </app-text-field>

            <div class="w-full flex flex-row space-x-1 justify-end flex-grow">
              <app-icon
                :name="'cashback-icon'"
                :customClass="'h-[20px] dark:!text-white'"
              />
              <app-normal-text :customClass="'!whitespace-nowrap'">
                You'll get
                <span
                  class="font-semibold text-primary-500 dark:!text-primary-200 px-1"
                  >{{
                    Logic.Common.convertToMoney(
                      getProductPoint(
                        "electricity",
                        parseFloat(amountValue?.replace(/,/g, "")).toString()
                      ),
                      false,
                      ""
                    )
                  }}
                  Points</span
                >
              </app-normal-text>
            </div>
          </div>
        </app-form-wrapper>
        <!-- Spacer  -->
        <div class="h-[120px]"></div>
      </div>
      <!-- Bottom section -->
      <fixed-container class="pt-4">
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :bg-color="'bg-primary-400'"
            :text-color="'text-white'"
            :class="formIsValid ? 'w-full' : 'opacity-50'"
            @click="continueAction"
          >
            {{ resolveIsError ? "Check meter number" : "Continue" }}
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppFormWrapper,
  AppSelect,
  AppNormalText,
  AppButton,
  AppTextField,
  AppIcon,
} from "@shpt/ui-components";
import {
  getProductPoint,
  makePurchase,
  processProductData,
  selectedBeneficiary,
  SingleProduct,
} from "@shpt/ui-components/src/composable/shop";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import { FormValidations } from "@shpt/ui-components/src/composable";
import { SelectOption } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppFormWrapper,
    AppSelect,
    AppNormalText,
    AppButton,
    AppTextField,
    AppIcon,
    AppWrapper,
    FixedContainer,
  },
  name: "BuyElectricityPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Buy Electricity Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Shop",
        property: "ShopData",
        subProperty: "electricity",
        method: "GetProductsByCategory",
        params: ["electricity"],
        requireAuth: false,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Buy Electricity",
    });

    const resolveTrials = ref(0);

    const electricityFormData = reactive({
      biller_name: "",
      payment_code: "",
      meter_number: "",
      amount: "",
    });
    const selectedProductSlug = ref("");
    const billersOptons = reactive<SelectOption[]>([]);
    const billerItems = reactive<SelectOption[]>([]);
    const saveAsBeneficiary = ref(true);
    const electricityBiller = ref("");
    const meterNumber = ref("");
    const resolveIsError = ref(false);
    const isResolvingNumber = ref(false);
    const amountValue = ref("");

    const setPaymentItems = () => {
      if (electricityFormData.biller_name) {
        const currentBiller = billersOptons.filter((item) => {
          return item.key == electricityFormData.biller_name;
        })[0];

        const allItems: any = currentBiller.extras;
        billerItems.length = 0;

        const billingItems: any[] = [];

        allItems.forEach((item: any) => {
          const metadata = JSON.parse(item.metadata);
          billingItems.push(
            ...[
              {
                key: item.code,
                value: metadata.name,
                categorySlug: item.product_category.slug,
              },
            ]
          );
        });
        if (billingItems) {
          billerItems.push(
            ...billingItems.filter(
              (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
            )
          );
        }

        electricityFormData.payment_code = billerItems[0].key;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectedProductSlug.value = billerItems[0].categorySlug;
      }
    };

    const setBillers = () => {
      const electricityDeals = processProductData(
        Logic.Shop.ShopData.electricity || []
      );
      const electricityDealsData = electricityDeals.map((item) => item.data);

      const groupedData = Logic.Common.groupArrayBy(
        electricityDealsData,
        "merchant_name"
      );
      billersOptons.length = 0;

      for (const key in groupedData) {
        if (Object.prototype.hasOwnProperty.call(groupedData, key)) {
          const element = groupedData[key];
          billersOptons.push({
            key,
            value: capitalize(key),
            extras: element,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            imageUrl: element[0].image_url,
          });
        }
      }
      electricityFormData.biller_name = billersOptons[0].key;

      setPaymentItems();
    };

    const formIsValid = computed(() => {
      const amount = parseFloat(amountValue?.value.replace(/,/g, ""));
      return meterNumber.value.length >= 7 && amount > 0;
    });

    const resolveMeterNumber = () => {
      if (meterNumber.value.length >= 7) {
        resolveTrials.value += 1;
        Logic.Shop.ValidatePaymentCustomerForm = {
          customer_id: meterNumber.value,
          payment_code: electricityFormData.payment_code,
        };
        isResolvingNumber.value = true;
        electricityBiller.value = "";
        Logic.Shop.ValidatePaymentCustomer()
          ?.then((data) => {
            if (data) {
              electricityBiller.value = data.ValidatePaymentCustomer;
            }
            resolveIsError.value = false;
            isResolvingNumber.value = false;
          })
          .catch(() => {
            resolveIsError.value = true;
            isResolvingNumber.value = false;
            electricityBiller.value = "";
          });
      }
    };

    watch(meterNumber, () => {
      resolveIsError.value = true;
    });

    watch(electricityFormData, () => {
      resolveIsError.value = true;
    });

    const handleBillerSelected = () => {
      setPaymentItems();
    };

    const setElectricityData = () => {
      setTarget();
      setBillers();
      setTimeout(() => {
        if (SingleProduct.value) {
          electricityFormData.biller_name =
            SingleProduct.value.merchant_name || "";
          setPaymentItems();
          electricityFormData.amount = SingleProduct.value.price;
          amountValue.value = SingleProduct.value.price;
        }
      }, 300);
      meterNumber.value = "";
      if (selectedBeneficiary.value) {
        meterNumber.value = selectedBeneficiary.value.id;
      }
    };

    const purchaseElectricity = () => {
      const amount = parseFloat(amountValue.value?.replace(/,/g, ""));

      if (amount > 0 && meterNumber.value && electricityFormData.payment_code) {
        makePurchase(
          {
            code: electricityFormData.payment_code,
            amount: amount.toString(),
            customerId: meterNumber.value,
            phone_no: Logic.Auth.AuthUser?.phone?.replace("234", "0") || "",
          },
          saveAsBeneficiary.value
        );
      }
    };

    const setTarget = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        const products = Logic.Shop.ShopData.electricity;
        const currentProvider =
          currentPathQuery.target?.toString().toLowerCase() || "";

        const currentProduct = products?.filter(
          (item) => item.merchant_name == currentProvider
        );

        if (currentProduct?.length) {
          SingleProduct.value = currentProduct[0];
        }
      }
    };

    const continueAction = () => {
      if (formIsValid.value) {
        if (resolveIsError.value) {
          resolveMeterNumber();
        } else {
          purchaseElectricity();
        }
      }
    };

    onMounted(() => {
      setElectricityData();
      resolveTrials.value = 0;
    });

    watch(selectedBeneficiary, () => {
      setElectricityData();
    });

    watch(SingleProduct, () => {
      setElectricityData();
    });

    onIonViewWillEnter(() => {
      setElectricityData();
      resolveTrials.value = 0;
    });

    return {
      billersOptons,
      electricityFormData,
      billerItems,
      FormValidations,
      selectedProductSlug,
      Logic,
      saveAsBeneficiary,
      electricityBiller,
      meterNumber,
      resolveIsError,
      isResolvingNumber,
      amountValue,
      loaderSetup,
      formIsValid,
      resolveTrials,
      handleBillerSelected,
      purchaseElectricity,
      getProductPoint,
      continueAction,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>

<template>
  <div
    class="w-full flex flex-col space-y-3 py-3 pb-4 items-center justify-center md:!pb-0 mdlg:!pb-0"
    v-if="data"
  >
    <!-- Delivery details -->
    <div class="w-full flex flex-col space-y-2" v-if="data.data.user_delivery">
      <div class="w-full flex flex-row items-center justify-between">
        <app-normal-text class="font-semibold">Delivery Details</app-normal-text>

        <div class="flex flex-row items-center justify-between space-x-2">
          <app-badge :color="getStatusColor(data.data.status)">
            {{ capitalize(data.data.status.split("_").join(" ")) }}
          </app-badge>
        </div>
      </div>
      <div
        :class="`w-full flex flex-col space-y-2 bg-[#F3F3F3] rounded-[10px] px-3 py-3 border-gray-200 dark:bg-gray-900`"
      >
        <div class="w-full flex flex-row items-center justify-between">
          <app-normal-text class="!text-[#202020] dark:!text-white !font-semibold">
            {{ data?.data?.user_delivery?.title }}
          </app-normal-text>
        </div>
        <div class="w-full flex flex-row items-center justify-between">
          <app-normal-text class="text-left">
            {{ data?.data?.user_delivery?.address }}
          </app-normal-text>
        </div>
        <div class="w-full flex flex-row items-center justify-between">
          <app-normal-text class="text-left">
            {{ data?.data?.user_delivery?.phone }}
          </app-normal-text>
        </div>
      </div>
    </div>

    <!-- Courier details -->
    <div class="w-full flex flex-col space-y-2" v-if="data.data.shipping_info?.courier">
      <div
        :class="`w-full flex flex-row items-start space-x-2 bg-white rounded-[5px] px-2 py-2 border-[1px] border-gray-200 dark:bg-gray-900`"
      >
        <div class="w-[35px] flex items-center justify-center">
          <app-image-loader
            :photoUrl="data.data?.shipping_info?.courier?.courier_image"
            custom-class="h-[35px] w-[35px]"
          >
          </app-image-loader>
        </div>

        <div class="w-full flex flex-col space-y-1">
          <app-normal-text class="!font-semibold">
            {{ data.data?.shipping_info?.courier?.courier_name }}
          </app-normal-text>
          <div class="w-full flex flex-row justify-between items-center">
            <app-normal-text class="!font-bold">
              {{ Logic.Common.convertToMoney(data?.data.delivery_fee, false, "ngn") }}
            </app-normal-text>
          </div>
          <div
            class="w-full flex flex-row items-center justify-between space-x-1 pt-[1px]"
          >
            <app-ratings
              :rating="data.data?.shipping_info?.courier?.ratings"
              custom-class="!h-[11px]"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Order items -->
    <div class="w-full flex flex-col space-y-2">
      <div class="w-full flex flex-row items-center justify-between">
        <app-normal-text class="font-semibold">Items</app-normal-text>
      </div>

      <div
        class="w-full flex flex-col space-y-3 bg-grey-50 dark:bg-gray-900 rounded-[5px] px-3 py-3 justify-between"
      >
        <!-- Product list -->
        <div class="w-full flex flex-col space-y-2 pb-2">
          <div
            v-for="(product, index) in data.data.items"
            :key="index"
            :class="`w-full flex flex-row items-center justify-between space-x-2`"
          >
            <div :class="`flex flex-row items-center space-x-2 `">
              <div :class="`w-[35px]`">
                <div class="w-[35px]">
                  <app-image-loader
                    :photoUrl="
                      product.variant?.base_image_url || product.product.primary_image_url
                    "
                    custom-class="h-[35px] w-[35px] rounded-[2px]"
                  >
                  </app-image-loader>
                </div>
              </div>
              <div class="flex flex-col items-start space-y-[2px]">
                <app-normal-text
                  :class="`!text-[#202020] !line-clamp-2 text-left dark:!text-gray-100 `"
                >
                  {{ product.product.name }} (x{{ product.quantity }})
                </app-normal-text>
              </div>
            </div>
            <app-normal-text :class="`!whitespace-nowrap !font-semibold  `">
              {{ Logic.Common.convertToMoney(product.total_price, false, "ngn") }}
            </app-normal-text>
          </div>
        </div>

        <!-- Other info -->
        <div class="w-full flex flex-col border-t-[1px] border-grey-300 pt-3 space-y-2">
          <div class="flex flex-row items-center space-x-1 justify-between w-full">
            <app-normal-text class="!text-[#202020] dark:!text-gray-100">
              Total Discount
            </app-normal-text>
            <app-normal-text class="!font-semibold">
              {{
                Logic.Common.convertToMoney(
                  data.for_business ? data.data.total_discount : data.data.discount ?? 0,
                  false,
                  "ngn"
                )
              }}
            </app-normal-text>
          </div>
          <div class="flex flex-row items-center space-x-1 justify-between w-full">
            <app-normal-text class="!text-[#202020] dark:!text-gray-100">
              Delivery Fee
            </app-normal-text>
            <app-normal-text class="!font-semibold">
              {{ Logic.Common.convertToMoney(data.data.delivery_fee, false, "ngn") }}
            </app-normal-text>
          </div>
          <div class="flex flex-row items-center space-x-1 justify-between w-full">
            <app-normal-text class="!text-[#202020] dark:!text-gray-100">
              Sub Total
            </app-normal-text>
            <app-normal-text class="!font-semibold">
              {{
                Logic.Common.convertToMoney(
                  data.data.items.reduce((acc, item) => acc + item.total_price, 0),
                  false,
                  "ngn"
                )
              }}
            </app-normal-text>
          </div>

          <div class="flex flex-row items-center space-x-1 justify-between w-full">
            <app-normal-text class="!text-[#202020] dark:!text-gray-100">
              Total
            </app-normal-text>
            <app-normal-text class="!font-semibold">
              {{
                Logic.Common.convertToMoney(
                  data.data.total_amount + (data.data.delivery_fee || 0),
                  false,
                  "ngn"
                )
              }}
            </app-normal-text>
          </div>

          <div class="w-full flex flex-col space-y-2 pt-2 border-t-[1px] border-grey-300">
            <div class="flex flex-row items-center space-x-1 justify-between w-full">
              <app-normal-text class="!text-[#202020] dark:!text-gray-100">
                Cashback To Earn
              </app-normal-text>
              <app-normal-text class="!font-semibold">
                {{
                  Logic.Common.convertToMoney(
                    data.for_business ? data.data.total_cashback : data.data.cashback,
                    false,
                    "ngn"
                  )
                }}
              </app-normal-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { capitalize, defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";
import { Order } from "../../gql/graphql";
import { Logic } from "../../composable";
import AppImageLoader from "../AppImageLoader";
import AppBadge from "../AppBadge";
import AppRatings from "../AppRatings";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppButton,
    AppImageLoader,
    AppBadge,
    AppRatings,
  },
  props: {
    data: {
      type: Object as () => {
        data: Order;
        for_business: boolean;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const getStatusColor = (
      status: string
    ): "yellow" | "blue" | "green" | "red" | "purple" => {
      if (status == "pending") {
        return "yellow";
      } else if (
        status == "confirmed" ||
        status == "picked_up" ||
        status == "in_transit"
      ) {
        return "blue";
      } else if (status == "completed") {
        return "green";
      } else if (status == "cancelled") {
        return "red";
      } else if (status.includes("return")) {
        return "purple";
      }

      return "yellow";
    };

    return {
      Logic,
      getStatusColor,
      capitalize,
    };
  },
});
</script>

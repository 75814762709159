import { OperationResult } from 'urql'
import {
  Beneficiary,
  PointEarned,
  MutationInitiateProductPaymentArgs,
  MutationProcessScannedReceiptArgs,
  MutationValidatePaymentCustomerArgs,
  Product,
  ProductCategory,
  ProductPaginator,
  ScannedReceiptResponse,
  ScannedReceipt,
  MutationReportScannedReceiptArgs,
  MutationSendGiftCardArgs,
  MerchantCategory,
  QueryGetProductRecommendationArgs,
  RecommendationResponse,
  Vendor,
  SpendingCategory,
  MutationUpdateScannedReceiptArgs,
  MutationMakeCommercePurchaseArgs,
  Order,
  OrderPaginator,
  MutationUpdateShoplistOrderStatusArgs,
  BusinessDashboardSummary,
} from '../gql/graphql'
import { BaseApiService } from './common/BaseService'

export default class ShopApi extends BaseApiService {
  public GetProducts = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
    hasProductCategoryQuery = '',
  ) => {
    const requestData = `
        query GetProducts($page: Int!, $count: Int!){
            GetProducts(first: $count, page: $page,
                orderBy: {column: ${
                  orderType ? orderType : 'CREATED_AT'
                }, order: ${order}}
                   ${whereQuery ? `where: ${whereQuery}` : ''} ${
      hasProductCategoryQuery
        ? `hasProductCategory: ${hasProductCategoryQuery}`
        : ''
    } ){
              paginatorInfo{
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
                lastPage
              }
              data{
                id
                uuid
                name
                product_category{
                  uuid
                  name
                  slug
                }
                price
                deal_type
                point_gain
                merchant_product_code
                cash_per_point
                image_url
                gallery_images
                metadata
                merchant_name
                status
                code
              }
            }
          }
                `

    const response: Promise<OperationResult<{
      GetProducts: ProductPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public SearchProducts = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
    hasProductCategoryQuery = '',
  ) => {
    const requestData = `
        query SearchProducts($page: Int!, $count: Int!){
          SearchProducts(first: $count, page: $page,
                orderBy: {column: ${
                  orderType ? orderType : 'CREATED_AT'
                }, order: ${order}}
                   ${whereQuery ? `where: ${whereQuery}` : ''} ${
      hasProductCategoryQuery
        ? `hasProductCategory: ${hasProductCategoryQuery}`
        : ''
    } ){
              paginatorInfo{
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
                lastPage
              }
              data{
                id
                uuid
                name
                product_category{
                  uuid
                  name
                  slug
                }
                price
                deal_type
                point_gain
                merchant_product_code
                cash_per_point
                image_url
                gallery_images
                metadata
                merchant_name
                status
                code
              }
            }
          }
                `

    const response: Promise<OperationResult<{
      SearchProducts: ProductPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetAllProducts = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
    hasProductCategoryQuery = '',
  ) => {
    const requestData = `
        query GetAllProducts($page: Int!, $count: Int!){
          GetAllProducts(first: $count, page: $page,
                orderBy: {column: ${
                  orderType ? orderType : 'CREATED_AT'
                }, order: ${order}}
                   ${whereQuery ? `where: ${whereQuery}` : ''} ${
      hasProductCategoryQuery
        ? `hasProductCategory: ${hasProductCategoryQuery}`
        : ''
    } ){
              paginatorInfo{
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
                lastPage
              }
              data{
                id
                uuid
                name
                description_meta
                description
                product_category{
                  uuid
                  name
                  slug
                }
                price
                deal_type
                merchant_product_code
                point_gain
                cash_per_point
                image_url
                gallery_images
                metadata
                merchant_name
                status
                code
              }
            }
          }
                `

    const response: Promise<OperationResult<{
      GetAllProducts: ProductPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetTrendingProducts = () => {
    const requestData = `
    query GetTrendingProducts {
      TrendingProducts {
          id
          uuid
          name
          product_category {
            uuid
            name
            slug
          }
          price
          deal_type
          point_gain
          image_url
          gallery_images
          metadata
          merchant_name
          status
          code
          description
          merchant_product_code
          created_at
        }
      }
        `

    const response: Promise<OperationResult<{
      TrendingProducts: Product[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetProductRecommendation = (
    data: QueryGetProductRecommendationArgs,
  ) => {
    const requestData = `
    query GetProductRecommendation($category: String!, $sub_category: String) {
      GetProductRecommendation(category: $category, sub_category: $sub_category) {
        products {
          id
          uuid
          name
          product_category {
            uuid
            name
            slug
          }
          vendor {
            name
            logo_url
            merchant_name
          }
          price
          deal_type
          point_gain
          image_url
          gallery_images
          metadata
          merchant_name
          status
          code
          description
          merchant_product_code
          created_at
        }
        vendors {
          name
          merchant_name
          logo
          product_uuid
        }
      }
    }
        `

    const response: Promise<OperationResult<{
      GetProductRecommendation: RecommendationResponse
    }>> = this.query(requestData, data)

    return response
  }

  public GetSpendingCategories = () => {
    const requestData = `
    query GetSpendingCategories {
      GetSpendingCategories{
        id
        name
      }
    }
    `
    const response: Promise<OperationResult<{
      GetSpendingCategories: SpendingCategory[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetVendor = (merchant_name: string) => {
    const requestData = `
    query GetVendor($merchant_name: String!) {
      GetVendor(merchant_name: $merchant_name) {
        id
        uuid
        name
        merchant_name
        cover_image
        logo_url
        other_info {
          key
          value
        }
      }
    }`

    const response: Promise<OperationResult<{
      GetVendor: Vendor
    }>> = this.query(requestData, {
      merchant_name,
    })

    return response
  }

  public GetProduct = (uuid: string) => {
    const requestData = `
    query GetProduct($uuid: String!) {
        GetProduct(uuid: $uuid) {
          id
          uuid
          name
          product_category {
            uuid
            name
            slug
          }
          description_meta
          vendor {
            name
            logo_url
            merchant_name
          }
          price
          deal_type
          point_gain
          purchase_data
          cash_per_point
          image_url
          gallery_images
          metadata
          merchant_name
          status
          code
          description
          merchant_product_code
          created_at
        }
      }
        `

    const response: Promise<OperationResult<{
      GetProduct: Product
    }>> = this.query(requestData, {
      uuid,
    })

    return response
  }

  public GetScannedReceipt = (uuid: string) => {
    const requestData = `
    query GetScannedReceipt($uuid: String!) {
      GetScannedReceipt(uuid: $uuid) {
        uuid
        amount
        category
        receipt_date
        items
        merchant_name
        status
        image_url
        created_at
        }
      }
        `
    const response: Promise<OperationResult<{
      GetScannedReceipt: ScannedReceipt
    }>> = this.query(requestData, {
      uuid,
    })

    return response
  }

  public GetProductCategories = () => {
    const requestData = `
    query GetProductCategories {
        GetProductCategories {
          id
          uuid
          name
          slug
          image_url
          created_at
        }
      }
        `

    const response: Promise<OperationResult<{
      GetProductCategories: ProductCategory[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetBusinessDashboardSummary = () => {
    const requestData = `
    query GetBusinessDashboardSummary {
      GetBusinessDashboardSummary {
        total_earnings
        total_pending_orders
        total_shoplists
        total_products
        this_week_earnings
        total_impressions
        total_engagements
        impression_change_from_last_week {
          percentage
          direction
        }
        engagement_change_from_last_week {
          percentage
          direction
        }
      }
    }
    `

    const response: Promise<OperationResult<{
      GetBusinessDashboardSummary: BusinessDashboardSummary
    }>> = this.query(requestData, {})

    return response
  }

  public GetUserOrders = () => {
    const requestData = `
   query GetOrders {
      GetOrders {
        id
        uuid
        total_cashback
        total_discount
        delivery_fee
        user_delivery {
          title
          address
          phone
        }
        collection{
          id
          uuid
          name
        }
        shipping_info {
          courier {
              courier_id
              courier_name
              courier_image
              service_code
              pickup_eta
              pickup_eta_time
              delivery_eta
              pickup_eta_time
              info
              currency
              ratings
              total
            }
          }
        items {
          id
          unit_price
          total_price
          quantity
          product {
            id
            uuid
            name
            category {
              id
              parent_category_id
              name
            }
            regular_price
            primary_image_url
          }
          variant {
            id
            name
            price
            base_image_url
          }
        }
        cashback
        commission
        total_amount
        payment_status
        status
        updated_at
      }
    }
    `

    const response: Promise<OperationResult<{
      GetOrders: Order[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetBusinessOrders = (
    business_profile_id: number,
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
  ) => {
    const requestData = `
        query GetBusinessOrdres($business_profile_id: Int!, $page: Int!, $count: Int!){
          GetBusinessOrders(business_profile_id: $business_profile_id, first: $count, page: $page,
                orderBy: {column: ${
                  orderType ? orderType : 'CREATED_AT'
                }, order: ${order}}
                   ${whereQuery ? `where: ${whereQuery}` : ''} ){
              paginatorInfo{
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
                lastPage
              }
              data{
                id
                uuid
                total_cashback
                total_discount
                total_platform_fee
                total_payment_processing_fee
                delivery_fee
                user_delivery {
                  id
                  title
                  address
                  phone
                  
                }
                collection{
                  id
                  uuid
                  name
                }
                shipping_info {
                  courier {
                    courier_id
                    courier_name
                    courier_image
                    service_code
                    pickup_eta
                    pickup_eta_time
                    delivery_eta
                    pickup_eta_time
                    info
                    currency
                    ratings
                    total
                  }
                }
                business_delivery {
                  id
                  uuid
                  title
                  fixed_cost
                }
                items {
                  id
                    unit_price
                    total_price
                    quantity
                    product {
                      id
                      uuid
                      name
                      category {
                        id
                        parent_category_id
                        name
                      }
                      regular_price
                      primary_image_url
                    }
                    variant {
                      id
                      name
                      price
                      base_image_url
                    }
                }
                total_amount
                payment_status
                sold_by {
                  id
                  business_name
                  business_tag
                  photo_url
                }
                cashback
                commission
                status
                updated_at
                created_at
               }
              }
          }
                `

    const response: Promise<OperationResult<{
      GetBusinessOrders: OrderPaginator
    }>> = this.query(requestData, {
      business_profile_id,
      page,
      count,
    })

    return response
  }

  public GetProductBeneficiaries = (product_category_uuid: string) => {
    const requestData = `
    query GetProductBeneficiaries($product_category_uuid: String!) {
        GetProductBeneficiaries(product_category_uuid: $product_category_uuid) {
          id
          uuid
          product_code
          customer_code
          metadata
          created_at
        }
      }
        `

    const response: Promise<OperationResult<{
      GetProductBeneficiaries: Beneficiary[]
    }>> = this.query(requestData, {
      product_category_uuid,
    })

    return response
  }

  public GetMerchantByCategories = () => {
    const requestData = `
    query MerchantByCategories {
      MerchantByCategories {
        name
        slug
        sub_categories {
          name
          label
          logo
          uuid
          soon
        }
      }
    }`

    const response: Promise<OperationResult<{
      MerchantByCategories: MerchantCategory[]
    }>> = this.query(requestData, {})

    return response
  }

  public ValidatePaymentCustomer = (
    data: MutationValidatePaymentCustomerArgs,
  ) => {
    const requestData = `
    mutation ValidatePaymentCustomer($customer_id: String!, $payment_code: String!) {
        ValidatePaymentCustomer(customer_id: $customer_id, payment_code: $payment_code)
      }
        `

    const response: Promise<OperationResult<{
      ValidatePaymentCustomer: string
    }>> = this.mutation(requestData, data)

    return response
  }

  public ScanReciept = (image: Blob) => {
    const requestData = `
    mutation ScanReciept($image: Upload!) {
        ScanReciept(image: $image) {
          scanned_receipt {
            uuid
            amount
            category
            receipt_date
            items
            merchant_name
            status
            image_url
            created_at
          }
          point_to_claim
        }
      }
        `

    const response: Promise<OperationResult<{
      ScanReciept: ScannedReceiptResponse
    }>> = this.mutation(requestData, { image })

    return response
  }

  public MakeCommercePurchase = (data: MutationMakeCommercePurchaseArgs) => {
    const requestData = `
    mutation MakeCommercePurchase($input: CommercePurchaseInput!) {
      MakeCommercePurchase(input: $input)
    }`

    const response: Promise<OperationResult<{
      MakeCommercePurchase: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public UploadFile = (attachment: any, type: string) => {
    const requestData = `
        mutation UploadFile($attachment: Upload!, $type: String) {
          UploadFile(
            attachment: $attachment
            type: $type
          )  
          }
          `
    const response: Promise<OperationResult<{
      UploadFile: string
    }>> = this.mutation(requestData, {
      attachment,
      type,
    })

    return response
  }

  public ProcessScannedReceipt = (data: MutationProcessScannedReceiptArgs) => {
    const requestData = `
    mutation ProcessScannedReceipt($scanned_receipt_uuid: String!, $account_type: String!, $is_force_continue: Boolean) {
        ProcessScannedReceipt(scanned_receipt_uuid: $scanned_receipt_uuid, account_type: $account_type, is_force_continue: $is_force_continue){
          id
          uuid
          money_amount
          points
          description
          event_slug
          claimed
          processing_status
          entity_uuid
          processed_at
          expire_at
          created_at
          updated_at
        }
      }
        `

    const response: Promise<OperationResult<{
      ProcessScannedReceipt: PointEarned
    }>> = this.mutation(requestData, data)

    return response
  }

  public InitiateProductPayment = (
    data: MutationInitiateProductPaymentArgs,
  ) => {
    const requestData = `
    mutation InitiateProductPayment($save_beneficiary: Boolean, $use_point: Boolean!, $products: [Products!]!, $account_type: String!, $card_uuid: String) {
        InitiateProductPayment(save_beneficiary: $save_beneficiary, use_point: $use_point, products: $products, account_type: $account_type, card_uuid: $card_uuid){
          id
          uuid
          money_amount
          points
          description
          event_slug
          claimed
          processing_status
          entity_uuid
          processed_at
          expire_at
          created_at
          updated_at
        }
      }
        `

    const response: Promise<OperationResult<{
      InitiateProductPayment: PointEarned
    }>> = this.mutation(requestData, data)

    return response
  }

  public ReportScannedReceipt = (data: MutationReportScannedReceiptArgs) => {
    const requestData = `
    mutation ReportScannedReceipt($scanned_receipt_uuid: String!, $report_type: String!) {
      ReportScannedReceipt(scanned_receipt_uuid: $scanned_receipt_uuid, report_type: $report_type)
      }
        `

    const response: Promise<OperationResult<{
      ReportScannedReceipt: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateScannedReceipt = (data: MutationUpdateScannedReceiptArgs) => {
    const requestData = `
    mutation UpdateScannedReceipt(
      $scanned_receipt_uuid: String!
      $amount: String!
      $merchant_name: String!
      $main_category: String!
    ) {
      UpdateScannedReceipt(scanned_receipt_uuid: $scanned_receipt_uuid, amount: $amount, merchant_name: $merchant_name, main_category: $main_category){
        uuid
        amount
        invoice_no
        category
        main_category
        receipt_date
        items
        merchant_name
        status
        image_url
        created_at
      }
      }
        `

    const response: Promise<OperationResult<{
      UpdateScannedReceipt: ScannedReceipt
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateOrderStatus = (data: MutationUpdateShoplistOrderStatusArgs) => {
    const requestData = `
    mutation UpdateShoplistOrderStatus($order_id: Int!, $status: OrderStatus!) {
      UpdateShoplistOrderStatus(order_id: $order_id, status: $status)
      }
        `

    const response: Promise<OperationResult<{
      UpdateShoplistOrderStatus: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public SendGiftCard = (data: MutationSendGiftCardArgs) => {
    const requestData = `
    mutation SendGiftCard($transaction_uuid: String!, $name: String!, $email: String!) {
      SendGiftCard(transaction_uuid: $transaction_uuid, name: $name, email: $email)
      }
        `
    const response: Promise<OperationResult<{
      SendGiftCard: boolean
    }>> = this.mutation(requestData, data)

    return response
  }
}

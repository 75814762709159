import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2" }
const _hoisted_2 = { class: "w-full py-4 px-4 bg-grey-50 dark:bg-gray-900 rounded-[12px] items-center justify-center space-y-1 flex flex-col" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-row" }
const _hoisted_5 = { class: "w-full flex flex-col rounded-[12px] border-[1px] border-gray-200 dark:border-gray-700" }
const _hoisted_6 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_7 = {
  key: 0,
  class: "w-full py-4 px-4 bg-grey-50 dark:bg-gray-900 rounded-[12px] items-center justify-between space-x-1 flex flex-row"
}
const _hoisted_8 = {
  key: 1,
  class: "w-full py-4 px-4 rounded-[12px] items-center justify-between space-x-1 flex flex-row"
}
const _hoisted_9 = {
  key: 2,
  class: "w-full flex flex-col rounded-[14px] border-[1px] border-shop_deep_purple_100 dark:border-gray-700"
}
const _hoisted_10 = { class: "py-4 flex flex-col space-y-3 px-3" }
const _hoisted_11 = { class: "w-full flex flex-col space-y-3 px-0 py-3 bg-grey-50 dark:bg-gray-900 rounded-[14px]" }
const _hoisted_12 = { class: "px-4 flex flex-row items-center space-x-2 justify-between" }
const _hoisted_13 = { class: "flex flex-col space-y-1" }
const _hoisted_14 = { class: "px-4 flex pb-4 flex-row items-center space-x-2 justify-between" }
const _hoisted_15 = { class: "flex flex-col space-y-1" }
const _hoisted_16 = { class: "w-full flex flex-col px-4" }
const _hoisted_17 = {
  key: 1,
  class: "w-full flex flex-col space-y-3 h-[100px] justify-center items-center px-4"
}
const _hoisted_18 = { class: "w-full flex flex-row items-center justify-center" }
const _hoisted_19 = { class: "w-full flex flex-col pt-1 xs:pb-3 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_normal_text, { color: 'text-black' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data?.payment_tag), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_app_header_text, { "custom-class": '!font-extrabold !text-xl' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.data?.amount || 0, true, "ngn")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_app_normal_text, {
          class: _normalizeClass('!font-semibold !text-black')
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Select wallet to pay ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.data?.from_bank_payment)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "w-full flex flex-row items-center justify-between px-3 py-3",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.useCashPoint ? (_ctx.useCashPoint = false) : (_ctx.useCashPoint = true)))
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_app_icon, {
                  name: 
                _ctx.useCashPoint
                  ? `selected${_ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'}`
                  : 'not-selected'
              ,
                  customClass: 'h-[17px]'
                }, null, 8, ["name"]),
                _createVNode(_component_app_normal_text, { color: 'text-black' }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Cashback Balance ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_app_normal_text, {
                color: 'text-black',
                customClass: '!font-semibold'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                _ctx.AuthUser?.wallet?.point_balance || 0,
                true,
                "ngn"
              )), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    ((_ctx.data?.type != 'bank_transfer' || _ctx.getFreeTransfer() == 0) && !_ctx.useCashPoint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_app_normal_text, {
            customClass: '!font-extrabold',
            color: 'text-black'
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Fee ")
            ])),
            _: 1
          }),
          (_ctx.data?.type != 'bank_transfer')
            ? (_openBlock(), _createBlock(_component_app_normal_text, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(0, false, "ngn")), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_app_normal_text, { key: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.getTransferFee(_ctx.data?.amount || 0), true, "ngn")), 1)
                ]),
                _: 1
              }))
        ]))
      : _createCommentVNode("", true),
    (_ctx.data?.type == 'bank_transfer' && _ctx.getFreeTransfer() > 0 && !_ctx.useCashPoint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_app_normal_text, {
            customClass: '!font-extrabold',
            color: 'text-black'
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Free transfers left ")
            ])),
            _: 1
          }),
          _createVNode(_component_app_normal_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFreeTransfer()), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedMethod == 'wallet' && _ctx.showWalletAccount && !_ctx.useCashPoint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_ctx.AuthUser?.wallet?.wallet_account?.account_name)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_app_normal_text, {
                      customClass: 'w-full px-4  pb-2   border-b-[1px] border-opacity-30 border-[#BDA3D7]',
                      color: 'text-black'
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Fund wallet ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_app_normal_text, { color: 'text-primary-400 dark:text-primary-100' }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Bank Name ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { color: 'text-black !font-semibold dark:text-white' }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.AuthUser?.wallet?.wallet_account?.bank_name || ""), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_app_normal_text, { color: 'text-primary-400 dark:text-primary-100' }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode(" Account Number ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, { color: 'text-black !font-semibold dark:text-white' }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.AuthUser?.wallet?.wallet_account?.account_number || ""), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createVNode(_component_app_button, {
                        padding: 'px-3 py-2',
                        border: 'border-b-[3px]',
                        customClass: '!flex flex-row space-x-2 items-center',
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.copyAccountNumber(
                    _ctx.AuthUser?.wallet?.wallet_account?.account_number || ''
                  )
                ))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.accountCopied ? "Copied!" : "Copy") + " ", 1),
                          _createVNode(_component_app_icon, {
                            name: 'copy-white',
                            customClass: 'h-[17px] pl-2'
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_app_normal_text, { color: 'text-primary-400 dark:text-primary-100' }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Money transfer made might take up to 5mins to reflect ")
                        ])),
                        _: 1
                      })
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createVNode(_component_app_normal_text, { customClass: 'text-center' }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode(" Setup your wallet account and fund your Shoppoint wallet with bank transfer. ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_app_button, {
                        padding: 'px-4 py-1',
                        border: 'border-b-[2px]',
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToSetupWallet()))
                      }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createTextVNode(" Set up wallet account ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(_component_app_button, {
        customClass: 'w-full',
        padding: 'py-3',
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.continuePayment()))
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [
          _createTextVNode(" Continue ")
        ])),
        _: 1
      })
    ])
  ]))
}
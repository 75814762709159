import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pl-4 pb-2" }
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_3 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_7 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center space-y-2 h-[400px] px-4"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_10 = { class: "h-[82px] !w-[120px]" }
const _hoisted_11 = { class: "flex flex-col items-end w-full justify-between" }
const _hoisted_12 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_13 = { class: "w-full flex flex-row items-center space-x-2" }
const _hoisted_14 = { class: "w-full flex flex-row space-x-2 justify-between items-center" }
const _hoisted_15 = { class: "flex flex-row justify-end items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Shopping Basket' }, {
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(`px-4 py-1 ${
                  _ctx.selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-700'
                }  rounded-[20px]  flex flex-row items-center justify-center cursor-pointer`),
                    onClick: ($event: any) => (_ctx.selectedFilter = item.id)
                  }, [
                    _createVNode(_component_app_normal_text, {
                      class: _normalizeClass(`${
                    _ctx.selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`),
                      "custom-class": 'whitespace-nowrap'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ], 10, _hoisted_4))
                }), 128))
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.items.length == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_ctx.selectedFilter == 'your-lists')
                      ? (_openBlock(), _createBlock(_component_app_empty_state, {
                          key: 0,
                          title: 'No lists here',
                          subTitle: 'Your lists will appear here'
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.selectedFilter == 'saved-lists')
                      ? (_openBlock(), _createBlock(_component_app_empty_state, {
                          key: 1,
                          title: 'No saved lists here',
                          subTitle: 'Your saved lists will appear here'
                        }))
                      : (_ctx.selectedFilter == 'orders')
                        ? (_openBlock(), _createBlock(_component_app_empty_state, {
                            key: 2,
                            title: 'No orders yet',
                            subTitle: 'You can order shoplists by clicking the buy now button'
                          }))
                        : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 dark:border-grey-main py-3 cursor-pointer",
                  onClick: ($event: any) => (_ctx.handleItemClick(item))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (_ctx.selectedFilter == 'your-lists')
                        ? (_openBlock(), _createBlock(_component_app_media_box, {
                            key: 0,
                            data: item,
                            isVideo: false,
                            class: "!h-[82px] !w-[82px] !rounded-[4.4px]",
                            isBasketType: true,
                            enforceGrid: true
                          }, null, 8, ["data"]))
                        : _createCommentVNode("", true),
                      (_ctx.selectedFilter == 'saved-lists')
                        ? (_openBlock(), _createBlock(_component_app_media_box, {
                            key: 1,
                            data: item,
                            isVideo: false,
                            class: "!h-[82px] !w-[82px] !rounded-[4.4px]",
                            isBasketType: true,
                            enforceGrid: true
                          }, null, 8, ["data"]))
                        : _createCommentVNode("", true),
                      (_ctx.selectedFilter == 'orders')
                        ? (_openBlock(), _createBlock(_component_app_media_box, {
                            key: 2,
                            data: item,
                            isVideo: false,
                            class: "!h-[82px] !w-[82px] !rounded-[4.4px]",
                            isBasketType: true,
                            enforceGrid: false
                          }, null, 8, ["data"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_app_header_text, { class: "!text-sm text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900 dark:!text-gray-300" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.selectedFilter == "your-lists" || _ctx.selectedFilter == "saved-lists"
                          ? item.stats?.items + " Items"
                          : item.order_info?.date), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "h-[3px] w-[3px] rounded-full bg-grey-900 dark:!bg-gray-300" }, null, -1)),
                          _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900 dark:!text-gray-300" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.selectedFilter == "your-lists" || _ctx.selectedFilter == "saved-lists"
                          ? item.main_category
                          : "#" + item.order_info?.id), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_app_header_text, { class: "!text-sm" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.selectedFilter == "your-lists" || _ctx.selectedFilter == "saved-lists"
                        ? _ctx.Logic.Common.convertToMoney(item.price, true, "ngn")
                        : item.delivery_state), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_app_button, {
                            padding: '!px-4 !py-1',
                            class: "!bg-primary-50 !text-primary-500 dark:!bg-primary-500 dark:!text-primary-50"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.selectedFilter == "orders"
                          ? `${item.delivery_state != "Delivered" ? "Track" : "Review"}`
                          : "Buy Now"), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ])
                    ])
                  ])
                ], 8, _hoisted_8))
              }), 128))
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 py-3 pb-4 items-center justify-center md:!pb-0 mdlg:!pb-0"
}
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_3 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_4 = { class: "flex flex-row items-center justify-between space-x-2" }
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col space-y-2 bg-[#F3F3F3] rounded-[10px] px-3 py-3 border-gray-200 dark:bg-gray-900`)
}
const _hoisted_6 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_7 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_8 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_9 = {
  key: 1,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_10 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-row items-start space-x-2 bg-white rounded-[5px] px-2 py-2 border-[1px] border-gray-200 dark:bg-gray-900`)
}
const _hoisted_11 = { class: "w-[35px] flex items-center justify-center" }
const _hoisted_12 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_13 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_14 = { class: "w-full flex flex-row items-center justify-between space-x-1 pt-[1px]" }
const _hoisted_15 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_16 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_17 = { class: "w-full flex flex-col space-y-3 bg-grey-50 dark:bg-gray-900 rounded-[5px] px-3 py-3 justify-between" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-2 pb-2" }
const _hoisted_19 = {
  class: /*@__PURE__*/_normalizeClass(`flex flex-row items-center space-x-2 `)
}
const _hoisted_20 = {
  class: /*@__PURE__*/_normalizeClass(`w-[35px]`)
}
const _hoisted_21 = { class: "w-[35px]" }
const _hoisted_22 = { class: "flex flex-col items-start space-y-[2px]" }
const _hoisted_23 = { class: "w-full flex flex-col border-t-[1px] border-grey-300 pt-3 space-y-2" }
const _hoisted_24 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }
const _hoisted_25 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }
const _hoisted_26 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }
const _hoisted_27 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }
const _hoisted_28 = { class: "w-full flex flex-col space-y-2 pt-2 border-t-[1px] border-grey-300" }
const _hoisted_29 = { class: "flex flex-row items-center space-x-1 justify-between w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_ratings = _resolveComponent("app-ratings")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.data.data.user_delivery)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_normal_text, { class: "font-semibold" }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Delivery Details")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_badge, {
                    color: _ctx.getStatusColor(_ctx.data.data.status)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.data.data.status.split("_").join(" "))), 1)
                    ]),
                    _: 1
                  }, 8, ["color"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-white !font-semibold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.data?.user_delivery?.title), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_app_normal_text, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.data?.user_delivery?.address), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_app_normal_text, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data?.data?.user_delivery?.phone), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.data.data.shipping_info?.courier)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: _ctx.data.data?.shipping_info?.courier?.courier_image,
                    "custom-class": "h-[35px] w-[35px]"
                  }, null, 8, ["photoUrl"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.data?.shipping_info?.courier?.courier_name), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_app_normal_text, { class: "!font-bold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.data?.data.delivery_fee, false, "ngn")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_app_ratings, {
                      rating: _ctx.data.data?.shipping_info?.courier?.ratings,
                      "custom-class": "!h-[11px]"
                    }, null, 8, ["rating"])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_app_normal_text, { class: "font-semibold" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Items")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.data.items, (product, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(`w-full flex flex-row items-center justify-between space-x-2`)
                }, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_app_image_loader, {
                          photoUrl: 
                      product.variant?.base_image_url || product.product.primary_image_url
                    ,
                          "custom-class": "h-[35px] w-[35px] rounded-[2px]"
                        }, null, 8, ["photoUrl"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass(`!text-[#202020] !line-clamp-2 text-left dark:!text-gray-100 `)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(product.product.name) + " (x" + _toDisplayString(product.quantity) + ") ", 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _createVNode(_component_app_normal_text, {
                    class: _normalizeClass(`!whitespace-nowrap !font-semibold  `)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(product.total_price, false, "ngn")), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-100" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Total Discount ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.data.for_business ? _ctx.data.data.total_discount : _ctx.data.data.discount ?? 0,
                  false,
                  "ngn"
                )), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-100" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Delivery Fee ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.data.data.delivery_fee, false, "ngn")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-100" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Sub Total ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.data.data.items.reduce((acc, item) => acc + item.total_price, 0),
                  false,
                  "ngn"
                )), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-100" }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Total ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                  _ctx.data.data.total_amount + (_ctx.data.data.delivery_fee || 0),
                  false,
                  "ngn"
                )), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_component_app_normal_text, { class: "!text-[#202020] dark:!text-gray-100" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Cashback To Earn ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.data.for_business ? _ctx.data.data.total_cashback : _ctx.data.data.cashback,
                    false,
                    "ngn"
                  )), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
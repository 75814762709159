<template>
  <app-wrapper>
    <subpage-layout
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
      :title="'Checkout'"
    >
      <div class="w-full flex flex-col space-y-3 px-4">
        <!-- Delivery info -->
        <div
          class="w-full flex flex-col space-y-2 pb-4 border-b-[1px] border-[#E8E8E8] dark:border-gray-500"
          v-if="hasAtLeastOneProductWithDelivery"
        >
          <div class="w-full flex flex-row justify-between items-center">
            <app-header-text class="!text-sm !text-left"> Delivery Info </app-header-text>

            <div
              :class="`flex flex-row items-center space-x-1 cursor-pointer ${
                hasAtLeastOneProductWithDelivery ? 'opacity-100' : 'opacity-50'
              }`"
              @click="showDeliveryAddressModal"
            >
              <app-icon :name="'edit-purple'" custom-class="h-[12px]" />
              <app-normal-text class="!text-[#9D4EDD]">
                Set delivery address
              </app-normal-text>
            </div>
          </div>
          <template v-if="selectedDeliveryInfo">
            <div
              class="w-full flex flex-col space-y-1 bg-[#F3F3F3] dark:!bg-gray-800 rounded-[10px] px-3 py-3"
            >
              <app-normal-text class="!text-[#202020] dark:!text-gray-300 !font-semibold">
                {{ selectedDeliveryInfo?.contact_name }}
              </app-normal-text>
              <app-normal-text class="text-left !text-[11px]">
                {{ selectedDeliveryInfo?.delivery_address?.name }}
              </app-normal-text>
              <app-normal-text class="text-left !text-[11px]">
                {{ selectedDeliveryInfo?.contact_phone }}
              </app-normal-text>
            </div>
          </template>

          <template v-else>
            <div
              class="w-full flex flex-row items-center justify-center h-[60px] bg-[#F3F3F3] dark:!bg-gray-900 rounded-[10px] py-4"
            >
              <app-normal-text class="!text-[#202020] dark:!text-gray-50">
                No delivery address set yet
              </app-normal-text>
            </div>
          </template>
        </div>

        <!-- Order summaries -->
        <div class="w-full flex flex-col space-y-2 pb-4">
          <div class="w-full flex flex-row justify-between items-center">
            <app-header-text class="!text-sm !text-left"> Order Summary </app-header-text>
            <div
              class="flex flex-row px-4 py-1 bg-primary-50 dark:!bg-primary-500 rounded-[30px]"
            >
              <app-normal-text
                class="!text-primary-500 !font-semibold !text-[11px] dark:!text-primary-50"
              >
                ₦{{ numberToAbbrev(totalReward || 0) }} Cashback
                {{
                  checkoutSetup.totalDiscount
                    ? `and (${Logic.Common.convertToMoney(
                        checkoutSetup.totalDiscount,
                        true,
                        "ngn"
                      )} off)`
                    : ""
                }}
              </app-normal-text>
            </div>
          </div>
          <div
            class="w-full flex flex-col space-y-3 bg-grey-50 dark:!bg-gray-900 rounded-[5px] px-3 py-2 justify-between"
          >
            <template v-for="(item, index) in groupedProducts" :key="index">
              <div class="w-full flex flex-col space-y-2">
                <app-normal-text class="!font-semibold">
                  From {{ item.business_name }}
                </app-normal-text>
              </div>

              <!-- Product list -->
              <div class="w-full flex flex-col space-y-2 pb-2">
                <div
                  v-for="(product, index) in item.products"
                  :key="index"
                  :class="`w-full flex flex-row items-center justify-between space-x-2`"
                >
                  <div :class="`flex flex-row items-center space-x-2 `">
                    <div
                      :class="`w-[35px]  ${
                        product.available_in_area ? 'opacity-100' : '!opacity-50'
                      }`"
                    >
                      <div class="w-[35px]">
                        <app-image-loader
                          :photoUrl="product.base_image"
                          custom-class="h-[35px] w-[35px] rounded-[2px]"
                        >
                        </app-image-loader>
                      </div>
                    </div>
                    <div class="flex flex-col items-start space-y-[2px]">
                      <app-normal-text
                        :class="`!text-[#202020] !line-clamp-2 text-left dark:!text-gray-300 ${
                          product.available_in_area ? '' : '!text-grey-500'
                        }`"
                      >
                        {{ product.title }} (x{{ product.qty }})
                      </app-normal-text>
                      <span
                        v-if="!product.available_in_area"
                        class="px-2 py-[1px] bg-red-500 text-white text-[9px] rounded-full"
                      >
                        Not Available
                      </span>
                    </div>
                  </div>
                  <app-normal-text
                    :class="`!whitespace-nowrap !font-semibold dark:!text-white ${
                      product.available_in_area
                        ? ''
                        : '!text-grey-500 dark:!text-gray-500'
                    }`"
                  >
                    {{ Logic.Common.convertToMoney(product.price, false, "ngn") }}
                  </app-normal-text>
                </div>
              </div>

              <!-- Selecte delivery courier -->

              <div
                class="w-full flex flex-col space-y-2"
                v-if="
                  (currentBusinessShippingRate(item.business_id)?.shipping_rate?.couriers
                    ?.length || 0) > 0 || loadingCouriers
                "
              >
                <div class="w-full flex flex-row items-center justify-between">
                  <div class="flex flex-row items-center space-x-2">
                    <app-normal-text class="font-semibold text-left">
                      Select a delivery courier
                    </app-normal-text>

                    <app-badge :color="'purple'">{{
                      applyDeliveryDiscountForUser(0).comment
                    }}</app-badge>
                  </div>

                  <app-loading-state v-if="loadingCouriers" class="text-black" />
                </div>
                <div
                  class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
                  v-if="
                    currentBusinessShippingRate(item.business_id)?.shipping_rate.couriers
                  "
                >
                  <div class="flex flex-row space-x-3 pr-4">
                    <div
                      v-for="(courier, index) in currentBusinessShippingRate(
                        item.business_id
                      )?.shipping_rate.couriers"
                      :key="index"
                      :class="`w-[210px] flex flex-row items-start space-x-2 bg-white dark:bg-gray-900 rounded-[5px] px-2 py-2 border-[1px] cursor-pointer ${
                        selectedCourier[item.business_id]?.courier_id ==
                        courier.courier_id
                          ? 'border-primary-400 border-[2px] dark:border-primary-400  '
                          : 'border-grey-200 dark:border-gray-100'
                      }`"
                      @click="
                        selectCourier(
                          courier,
                          item.business_id,
                          currentBusinessShippingRate(item.business_id)?.shipping_rate
                            ?.request_token || ''
                        )
                      "
                    >
                      <div class="w-[35px] flex items-center justify-center">
                        <app-image-loader
                          :photoUrl="courier.courier_image"
                          custom-class="h-[35px] w-[35px]"
                        >
                        </app-image-loader>
                      </div>

                      <div class="w-full flex flex-col space-y-1">
                        <app-normal-text class="!font-semibold">
                          {{ courier.courier_name }}
                        </app-normal-text>
                        <div class="w-full flex flex-row justify-between items-center">
                          <app-normal-text class="!font-bold">
                            {{
                              Logic.Common.convertToMoney(
                                applyDeliveryDiscountForUser(courier.total).amount,
                                false,
                                "ngn"
                              )
                            }}
                          </app-normal-text>
                        </div>
                        <div
                          class="w-full flex flex-row items-center justify-between space-x-1 pt-[1px]"
                        >
                          <template
                            v-if="
                              currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.fastest_courier.courier_id != courier.courier_id &&
                              currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.cheapest_courier.courier_id != courier.courier_id
                            "
                          >
                            <app-ratings
                              :rating="courier.ratings"
                              custom-class="!h-[11px]"
                            />
                          </template>
                          <app-badge
                            :color="'green'"
                            class="!text-[9px]"
                            v-if="
                              currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.fastest_courier.courier_id == courier.courier_id
                            "
                          >
                            Fastest
                          </app-badge>
                          <app-badge
                            :color="'purple'"
                            class="!text-[9px]"
                            v-if="
                              currentBusinessShippingRate(item.business_id)?.shipping_rate
                                ?.cheapest_courier.courier_id == courier.courier_id
                            "
                          >
                            Cheapest
                          </app-badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div></div>
              </div>

              <!-- Other info -->
              <div
                class="w-full flex flex-col border-t-[1px] border-grey-300 py-3 space-y-2"
              >
                <div class="flex flex-row items-center space-x-1 justify-between w-full">
                  <app-normal-text class="!text-[#202020] dark:!text-gray-300">
                    Delivery Fee
                  </app-normal-text>
                  <app-normal-text class="!font-semibold">
                    {{ Logic.Common.convertToMoney(item.deliveryFee, false, "ngn") }}
                  </app-normal-text>
                </div>
                <div
                  class="flex flex-row items-center space-x-1 justify-between w-full border-b-[1px] border-grey-300 pb-2"
                >
                  <app-normal-text class="!text-[#202020] dark:!text-gray-300">
                    Sub Total
                  </app-normal-text>
                  <app-normal-text class="!font-semibold">
                    {{
                      Logic.Common.convertToMoney(
                        item.finalCost + item.deliveryFee,
                        false,
                        "ngn"
                      )
                    }}
                  </app-normal-text>
                </div>

                <div class="flex flex-row items-center space-x-2 justify-start w-full">
                  <app-normal-text class="!text-[#202020] dark:!text-gray-300">
                    Cashback Savings:
                  </app-normal-text>
                  <app-normal-text class="!font-semibold">
                    {{ Logic.Common.convertToMoney(item.finalReward, false, "ngn") }}
                  </app-normal-text>
                </div>
              </div>
            </template>

            <!-- Final total -->
            <div class="flex flex-row items-center space-x-1 justify-between w-full">
              <app-normal-text class="!text-[#202020] dark:!text-gray-300">
                Total
              </app-normal-text>
              <app-normal-text class="!font-semibold">
                {{ Logic.Common.convertToMoney(totalCost, false, "ngn") }}
              </app-normal-text>
            </div>

            <div
              class="w-full flex flex-col space-y-2 pt-2 border-t-[1px] border-grey-300"
            >
              <div class="flex flex-row items-center space-x-2 justify-start w-full">
                <app-normal-text class="!text-[#202020] dark:!text-gray-300">
                  Total Cashback:
                </app-normal-text>
                <app-normal-text class="!font-semibold">
                  {{ Logic.Common.convertToMoney(totalReward, false, "ngn") }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <div class="h-[100px] w-full"></div>
      </div>

      <!-- Bottom section -->
      <fixed-container customClass="!px-0 pt-4 bg-white dark:!bg-black">
        <div class="w-full flex flex-col px-4">
          <AppButton
            :padding="'py-3'"
            :bg-color="'bg-primary-400'"
            :text-color="'text-white'"
            :class="`w-full ${formIsValid ? 'opacity-100' : 'opacity-50'}`"
            @click="showReviewPayment"
            :loading="purchaseIsLoading"
          >
            Pay ({{ Logic.Common.convertToMoney(totalCost, false, "ngn") }})
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppIcon,
  AppButton,
  AppImageLoader,
  AppBadge,
  AppLoadingState,
  AppRatings,
} from "@shpt/ui-components";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { CommercePurchasePerBusiness, Courier } from "@shpt/logic/src/gql/graphql";
import {
  applyDeliveryDiscountForUser,
  getMediaBoxForProduct,
} from "@shpt/ui-components/src/composable";
import AppWrapper from "@/components/AppWrapper.vue";
import { scrollToTop } from "@shpt/ui-components/src/composable";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";
import { getMediaBox } from "@shpt/ui-components/src/composable";

type DeliveryInfo = {
  id: string;
  contact_name: string;
  contact_phone: string;
  delivery_address: {
    name: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
};

type ProductList = {
  title: string;
  weight: string;
  main_category: string;
  price: number;
  base_image: string;
  qty: number;
  id: string;
  reward_type: string;
  data: ShoplistProduct;
  max_qty: number;
  discount: number;
  cashback: number;
  reward_point: number;
  available_in_area: boolean;
  business: {
    name: string;
    id: string;
  };
  delivery: {
    id: string;
    cost: string;
  };
  has_delivery: boolean;
  shoplist_id: string;
  variant_id: string;
};

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppIcon,
    AppButton,
    AppImageLoader,
    AppBadge,
    AppLoadingState,
    AppRatings,
    AppWrapper,
    FixedContainer,
  },
  name: "ShoplistCheckoutPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistCollection",
        method: "GetShoplistCollection",
        params: [],
        useRouteId: true,
        requireAuth: true,
        condition: {
          routeSearchItem: "query",
          searchQuery: "collection",
        },
      },
      {
        domain: "Shoplist",
        property: "SingleShoplistProduct",
        method: "GetShoplistProduct",
        ignoreProperty: true,
        params: [],
        useRouteId: true,
        requireAuth: true,
        condition: {
          routeSearchItem: "query",
          searchQuery: "product",
        },
      },
      {
        domain: "Shoplist",
        property: "ManyDeliveryLocations",
        method: "GetDeliveryLocation",
        params: [
          1,
          50,
          "CREATED_AT",
          "DESC",
          `{
        column: USER_ID
        operator: EQ
        value: ${Logic.Auth.AuthUser?.id}
      }`,
        ],
        requireAuth: true,
      },
      {
        domain: "Wallet",
        property: "UserWallet",
        method: "GetUserWallet",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyUserShoplistCarts",
        method: "GetUserShoplistCarts",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Shoplist Checkout Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Checkout",
    });

    const ManyDeliveryLocations = ref(Logic.Shoplist.ManyDeliveryLocations);
    // const ManyUserShoplistCarts = ref(Logic.Shoplist.ManyUserShoplistCarts);
    const ManyShippingRates = ref(Logic.Shoplist.ManyShippingRates);
    const SingleShoplistCollection = ref(Logic.Shoplist.SingleShoplistCollection);
    const SingleShoplistProduct = ref(Logic.Shoplist.SingleShoplistProduct);
    const loadingCouriers = ref(false);
    const selectedCourier = reactive<{ [key: string]: Courier }>({});
    const selectedRequestToken = reactive<{
      [key: string]: {
        courier: Courier;
        request_token: string;
      };
    }>({});

    const purchaseIsLoading = ref(false);

    const checkoutSetup = reactive<{
      products: ProductList[];
      totalCost: number;
      totalReward: number;
      totalDiscount: number;
    }>({
      products: [],
      totalCost: 0,
      totalReward: 0,
      totalDiscount: 0,
    });

    const totalReward = computed(() => {
      let total = 0;

      checkoutSetup.products.forEach((item) => {
        total += item.reward_point * item.qty;
      });

      return total;
    });

    const totalCost = computed(() => {
      return groupedProducts.reduce((acc, curr) => {
        if (curr.products.some((product) => product.available_in_area)) {
          return acc + (curr.finalCost + curr.deliveryFee - curr.finalDiscount);
        }
        return acc;
      }, 0);
    });

    const hasAtLeastOneProductWithDelivery = computed(() => {
      return checkoutSetup.products.some((item) => item.has_delivery);
    });

    const groupedProducts = reactive<
      {
        business_id: string;
        business_name: string;
        products: ProductList[];
        finalCost: number;
        finalReward: number;
        finalRewardPercentage: number;
        finalDiscount: number;
        deliveryFee: number;
      }[]
    >([]);

    const purchaseSummary = reactive([
      {
        name: "Sub Total",
        value: "₦36,000.00",
      },
      {
        name: "Delivery Fee",
        value: "₦3,512.00",
      },
      {
        name: "Service Fee",
        value: "₦1,500.00",
      },
      {
        name: "Total",
        value: "₦36,125.00",
        is_bold: true,
      },
    ]);

    const showCalendarModal = ref(false);

    const selectedDeliveryInfo = ref<DeliveryInfo | undefined>(undefined);

    const allDeliveryInfo = reactive<DeliveryInfo[]>([]);

    const currentBusinessShippingRate = (business_id: string) => {
      const businessShippingRate = ManyShippingRates.value?.find(
        (item) => item?.business_id == parseInt(business_id)
      );

      return businessShippingRate;
    };

    const formIsValid = computed(() => {
      return (
        selectedDeliveryInfo.value &&
        checkoutSetup.products.length > 0 &&
        checkoutSetup.totalCost > 0 &&
        totalCost.value > 0 &&
        ((Object.values(selectedCourier).every((courier) => courier !== undefined) &&
          Object.values(selectedCourier).length > 0) ||
          !hasAtLeastOneProductWithDelivery.value)
      );
    });

    const setDeliveryInfo = () => {
      allDeliveryInfo.length = 0;

      ManyDeliveryLocations.value?.data.forEach((item) => {
        allDeliveryInfo.push({
          id: item.id,
          contact_name: item.title || "",
          contact_phone: item.phone || "",
          delivery_address: {
            name: item.address || "",
            coordinates: {
              latitude: parseFloat(item.lat || "0") || 0,
              longitude: parseFloat(item.lng || "0") || 0,
            },
          },
        });
      });
    };

    const selectedPurchaseConfig = reactive({
      frequency: "once",
      start_date: new Date().toString(),
    });

    const showDeliveryAddressModal = () => {
      Logic.Common.showModal({
        show: true,
        type: "delivery_info",
        title: "Choose a delivery address",
        extraData: {
          delivery_addresses: allDeliveryInfo,
          selected_delivery_info: selectedDeliveryInfo.value,
          defaults: {
            name: Logic.Auth.AuthUser?.full_name,
            phone: Logic.Auth.AuthUser?.phone,
          },
        },
        action: (data: DeliveryInfo) => {
          selectedDeliveryInfo.value = data;

          Logic.Common.showModal({
            show: false,
          });
        },
      });
    };

    const getProductList = (ShoplistProducts: ShoplistProduct[], shoplist_id: string) => {
      const productList: ProductList[] = [];

      ShoplistProducts.forEach((product) => {
        let discount = 0;
        let cashback = 0;
        let points = 0;
        let rewardType = "cashback";

        const mediaBox = getMediaBoxForProduct(product);

        cashback = mediaBox?.points || 0;
        points = cashback;

        productList.push({
          base_image: product.primary_image_url,
          main_category: product.category.name,
          price: parseFloat(product.regular_price),
          title: product.name,
          weight: "",
          qty: 1,
          id: product.id,
          data: product,
          max_qty: product.stock_quantity,
          discount,
          cashback,
          reward_point: points,
          reward_type: rewardType,
          available_in_area: true,
          business: {
            name: product.business?.business_name || "",
            id: product.business?.id || "",
          },
          delivery: {
            id: "",
            cost: "",
          },
          has_delivery: product.deliverable || false,
          shoplist_id,
          variant_id: "",
        });
      });

      return productList;
    };

    const createProductList = () => {
      checkoutSetup.products.length = 0;

      const itemType = Logic.Common.route?.query?.product ? "product" : "collection";

      let Products: ShoplistProduct[] = [];

      if (itemType == "collection") {
        Products = SingleShoplistCollection.value?.products || [];
      } else {
        Products = [SingleShoplistProduct.value as ShoplistProduct];
      }

      checkoutSetup.products = getProductList(
        Products,
        itemType == "collection" ? SingleShoplistCollection.value?.id || "" : ""
      );
    };

    const getProductsByBusiness = (withDeliveryOnly = false) => {
      const productsByBusiness: CommercePurchasePerBusiness[] = groupedProducts.map(
        (group) => ({
          business_id: group.business_id,
          products: withDeliveryOnly
            ? group.products
                .filter((product) => product.has_delivery)
                .map((product) => ({
                  product_id: product.id,
                  quantity: product.qty,
                  shoplist_id: product.shoplist_id,
                  variant_id: product.variant_id,
                }))
            : group.products.map((product) => ({
                product_id: product.id,
                quantity: product.qty,
                shoplist_id: product.shoplist_id,
                variant_id: product.variant_id,
              })),
          delivery_location_id: group.products[0].delivery.id,
          shipping_info: {
            courier_id:
              selectedRequestToken[group.business_id]?.courier?.courier_id || "",
            full_data: selectedRequestToken[group.business_id]
              ? JSON.stringify(selectedRequestToken[group.business_id])
              : "",
            request_token: selectedRequestToken[group.business_id]?.request_token || "",
            service_code:
              selectedRequestToken[group.business_id]?.courier?.service_code || "",
          },
        })
      );

      return productsByBusiness;
    };

    const setProductAvailability = async () => {
      if (selectedDeliveryInfo.value) {
        if (loadingCouriers.value) {
          return;
        }
        loadingCouriers.value = true;
        let productsByBusiness = getProductsByBusiness(true);

        // Filter out business with no delivery products
        productsByBusiness = productsByBusiness.filter(
          (item) => item.products.length > 0
        );

        Logic.Shoplist.RequestShipmentRateForm = {
          input: {
            products_by_business: productsByBusiness,
            shoplist_id: SingleShoplistCollection.value?.id || "",
            user_delivery_location_id: selectedDeliveryInfo.value?.id || "",
          },
        };

        Logic.Shoplist.RequestShipmentRate()
          ?.then((data) => {
            data?.forEach((item) => {
              selectCourier(
                item.shipping_rate.couriers[0],
                item.business_id.toString(),
                item.shipping_rate.request_token
              );
            });
            loadingCouriers.value = false;
          })
          .catch(() => {
            Logic.Common.showAlert({
              show: true,
              type: "error",
              message: "Failed to load couriers",
            });
            loadingCouriers.value = false;
          });
      }
    };

    const saveMetric = (event_type: string) => {
      const itemType = Logic.Common.route?.query?.product ? "product" : "collection";

      if (itemType == "collection") {
        const sponsored_uuid = localStorage.getItem(
          `sponsored_shoplist_${SingleShoplistCollection.value?.uuid}`
        );
        Logic.Ad.SaveMetric({
          entity_type: "shoplist",
          event_type,
          entity_uuid: SingleShoplistCollection.value?.uuid || "",
          event_metadata: JSON.stringify({
            type: "shoplist",
            shoplist_uuid: SingleShoplistCollection.value?.uuid || "",
          }),
        });

        if (sponsored_uuid) {
          const sponsored_shoplist_request_uuid = localStorage.getItem(
            `sponsored_shoplist_request_uuid_${SingleShoplistCollection.value?.uuid}`
          );
          Logic.Ad.SaveMetric({
            entity_type: "advert",
            event_type,
            entity_uuid: sponsored_uuid,
            event_metadata: JSON.stringify({
              type: "advert",
              advert_uuid: sponsored_uuid,
              request_uuid: sponsored_shoplist_request_uuid,
            }),
          });
        }
      } else {
        Logic.Ad.SaveMetric({
          entity_type: "product",
          event_type,
          entity_uuid: SingleShoplistProduct.value?.uuid || "",
          event_metadata: JSON.stringify({
            type: "product",
            product_uuid: SingleShoplistProduct.value?.uuid || "",
          }),
        });
      }
    };

    const setGroupedProducts = () => {
      groupedProducts.length = 0;

      const groupedProductsObj = Logic.Common.groupArrayBy(
        checkoutSetup.products.map((product) => ({
          ...product,
          business_name: product.business.name,
        })),
        "business_name"
      );

      for (const key in groupedProductsObj) {
        if (Object.prototype.hasOwnProperty.call(groupedProductsObj, key)) {
          const element = groupedProductsObj[key];

          let finalCost = 0;
          let finalReward = 0;
          let finalDiscount = 0;
          let deliveryFee = 0;
          let finalRewardPercentage = 0;

          element.forEach((product: ProductList) => {
            if (product.available_in_area) {
              if (product.reward_type == "discount") {
                finalDiscount += product.discount;
                finalCost += product.price * product.qty;
              }
              if (product.reward_type == "cashback") {
                finalReward += product.reward_point * product.qty;
                finalRewardPercentage += product.cashback;
                finalCost += product.price * product.qty;
              }
            }
          });

          const availableProducts = element.filter(
            (product: ProductList) => product.available_in_area
          );

          if (availableProducts.length > 0) {
            deliveryFee = parseFloat(availableProducts[0].delivery.cost || `0`);
          }

          groupedProducts.push({
            business_id: element[0].business.id,
            business_name: key,
            products: element,
            finalCost,
            finalReward,
            finalDiscount,
            finalRewardPercentage,
            deliveryFee,
          });
        }
      }
    };

    const setCheckoutSetup = async () => {
      const itemType = Logic.Common.route?.query?.product ? "product" : "collection";

      let savedSetup: any = null;

      if (itemType == "collection") {
        savedSetup = await Logic.Shoplist.Storage.get(
          `checkout_setup_${SingleShoplistCollection.value?.uuid}`
        );
      } else {
        savedSetup = await Logic.Shoplist.Storage.get(
          `checkout_setup_product_${SingleShoplistProduct.value?.uuid}`
        );
      }

      if (savedSetup) {
        const setup = JSON.parse(savedSetup);

        checkoutSetup.products = setup.products;
        checkoutSetup.totalCost = setup.totalCost;
        checkoutSetup.totalReward = setup.totalReward;
        checkoutSetup.totalDiscount = setup.totalDiscount;
      } else {
        createProductList();
        checkoutSetup.totalCost = checkoutSetup.products.reduce(
          (acc, curr) => acc + curr.price * curr.qty,
          0
        );
        checkoutSetup.totalReward = checkoutSetup.products.reduce(
          (acc, curr) => acc + curr.reward_point * curr.qty,
          0
        );
        checkoutSetup.totalDiscount = checkoutSetup.products.reduce(
          (acc, curr) => acc + curr.discount,
          0
        );
      }

      // For collections
      if (itemType == "collection") {
        SingleShoplistCollection.value?.collections.forEach((item) => {
          const mediaBox = getMediaBox(item);
          const productList = getProductList(item.products, item.id);
          if (mediaBox) {
            checkoutSetup.totalReward += productList.reduce(
              (acc, curr) => acc + curr.reward_point,
              0
            );
            checkoutSetup.totalCost += mediaBox.price || 0;
            checkoutSetup.totalDiscount += productList.reduce(
              (acc, curr) => acc + curr.discount,
              0
            );
            checkoutSetup.products.push(...productList);
          }
        });
      }

      Logic.Shoplist.ManyShippingRates = [];

      await setProductAvailability();
      setGroupedProducts();
    };

    const completePurchase = () => {
      const productsByBusiness = getProductsByBusiness();

      const itemType = Logic.Common.route?.query?.product ? "product" : "collection";

      let cartId: string | undefined = undefined;

      if (itemType == "collection") {
        // ManyUserShoplistCarts.value?.forEach((item) => {
        //   if (item.collection?.id == SingleShoplistCollection.value?.id) {
        //     cartId = item.id;
        //   }
        // });
        cartId = SingleShoplistCollection.value?.id;
      }

      Logic.Shop.MakeCommercePurchaseForm = {
        input: {
          products_by_business: productsByBusiness,
          user_delivery_location_id: selectedDeliveryInfo.value?.id || "",
          cart_id: cartId,
          shoplist_owner_business_id:
            itemType == "collection"
              ? SingleShoplistCollection.value?.business?.id || ""
              : SingleShoplistProduct.value?.business?.id || "",
        },
      };

      if (purchaseIsLoading.value) {
        return;
      }

      purchaseIsLoading.value = true;

      Logic.Shop.MakeCommercePurchase()
        ?.then((data) => {
          if (data) {
            purchaseIsLoading.value = false;
            Logic.Common.showAlert({
              show: true,
              type: "success",
              message: "Your purchase has been successful",
            });
            saveMetric("purchased");
            Logic.Common.GoToRoute("/basket?tab=orders&backRoute=/");
            if (cartId) {
              Logic.Shoplist.Storage.remove(
                `checkout_setup_${SingleShoplistCollection.value?.uuid}`
              );
              Logic.Shoplist.GetUserShoplistCarts();
              Logic.Shop.GetUserOrders();
              Logic.Wallet.GetUserWallet();
              Logic.User.GetUnreadNotificationsCount();
              Logic.User.GetUnClaimedPointEarnedCount();
              Logic.Shoplist.GetUserProductCart();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          purchaseIsLoading.value = false;
        });
    };

    const selectCourier = (
      courier: Courier,
      business_id: string,
      request_token: string
    ) => {
      selectedCourier[business_id] = courier;
      selectedRequestToken[business_id] = {
        courier,
        request_token,
      };
      groupedProducts.forEach((item) => {
        if (item.business_id == business_id) {
          item.deliveryFee = applyDeliveryDiscountForUser(courier.total).amount;
        }
      });
    };

    const showReviewPayment = () => {
      if (formIsValid.value) {
        Logic.Common.showModal({
          show: true,
          type: "payment",
          title: "Review payment",
          extraData: {
            payment_tag: "Amount",
            amount: totalCost.value,
          },
          action: () => {
            completePurchase();
            Logic.Common.showModal({
              show: false,
            });
          },
        });
      }
    };

    watch(selectedDeliveryInfo, () => {
      setProductAvailability();
      setGroupedProducts();
    });

    watch(checkoutSetup, () => {
      setGroupedProducts();
    });

    watch(ManyDeliveryLocations, () => {
      setDeliveryInfo();
    });

    watch(SingleShoplistCollection, () => {
      setCheckoutSetup();
    });

    onIonViewWillEnter(() => {
      setDeliveryInfo();
      setCheckoutSetup();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("ManyDeliveryLocations", ManyDeliveryLocations);
      Logic.Shoplist.watchProperty("SingleShoplistCollection", SingleShoplistCollection);
      Logic.Shoplist.watchProperty("ManyShippingRates", ManyShippingRates);
      Logic.Shoplist.watchProperty("SingleShoplistProduct", SingleShoplistProduct);

      setDeliveryInfo();
      setCheckoutSetup();
      Logic.Shoplist.GetDeliveryLocation(
        1,
        50,
        "CREATED_AT",
        "DESC",
        `{
        column: USER_ID
        operator: EQ
        value: ${Logic.Auth.AuthUser?.id}
      }`
      );
      scrollToTop();
    });

    return {
      Logic,
      selectedPurchaseConfig,
      showCalendarModal,
      purchaseSummary,
      allDeliveryInfo,
      selectedDeliveryInfo,
      formIsValid,
      checkoutSetup,
      groupedProducts,
      totalCost,
      purchaseIsLoading,
      ManyShippingRates,
      selectedCourier,
      loadingCouriers,
      totalReward,
      hasAtLeastOneProductWithDelivery,
      numberToAbbrev,
      applyDeliveryDiscountForUser,
      selectCourier,
      showDeliveryAddressModal,
      showReviewPayment,
      currentBusinessShippingRate,
    };
  },
});
</script>

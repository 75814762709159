import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col relative top-0 left-0 !h-full" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 absolute top-0 left-0 z-50 px-3 py-3 space-x-3 items-center" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-between"
}
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = { class: "px-3 py-1 bg-black bg-opacity-50 rounded-[5px] min-w-[90px] flex items-center justify-center" }
const _hoisted_6 = { class: "flex flex-row items-center justify-end space-x-2" }
const _hoisted_7 = { class: "w-full flex flex-row items-center justify-between space-x-2" }
const _hoisted_8 = {
  key: 0,
  class: "w-full rounded-[6px] border-[2px] border-white h-[50px] flex flex-row relative",
  ref: "wrapper"
}
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center rounded-[10px]",
  style: {"height":"calc(\n          100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 120px\n        )"}
}
const _hoisted_10 = { class: "!w-full h-full flex flex-col !rounded-[10px]" }
const _hoisted_11 = {
  class: "w-full flex flex-row justify-between items-end px-3 py-3 min-h-[100px] h-fit absolute bottom-0 left-0 rounded-b-[10px]",
  style: 'background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0,0,0, 0.2));'
}
const _hoisted_12 = {
  key: 0,
  class: "w-full flex flex-col"
}
const _hoisted_13 = {
  key: 1,
  class: "w-full flex flex-col space-y-2 items-center justify-center"
}
const _hoisted_14 = {
  key: 0,
  class: "w-full grid grid-cols-2 gap-2"
}
const _hoisted_15 = { class: "text-black !font-semibold !text-xs" }
const _hoisted_16 = {
  key: 1,
  class: "col-span-1 rounded-[6px] bg-white flex flex-col"
}
const _hoisted_17 = { class: "py-1 flex flex-row items-center justify-center" }
const _hoisted_18 = { class: "text-black !font-semibold !text-[11px]" }
const _hoisted_19 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_20 = { class: "text-black !font-semibold !text-xs" }
const _hoisted_21 = {
  key: 1,
  class: "col-span-1 rounded-[6px] bg-white flex flex-col"
}
const _hoisted_22 = { class: "py-1 flex flex-row items-center justify-center" }
const _hoisted_23 = { class: "text-black !font-semibold !text-[11px]" }
const _hoisted_24 = {
  key: 2,
  class: "w-full flex flex-col h-full items-center justify-center !z-[9999999999] top-0 left-0 space-y-3 rounded-[10px] absolute bg-white dark:bg-black border-[1px] border-grey-200 dark:!border-grey-700"
}
const _hoisted_25 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-5 w-5 text-primary-400 -mt-10`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_video_player = _resolveComponent("app-video-player")!
  const _component_app_image_cropper = _resolveComponent("app-image-cropper")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.type == 'video' && _ctx.videoSettings.paused)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_normal_text, { class: "!text-white !text-[12px]" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatTime(_ctx.videoClipSetup.start)) + " - " + _toDisplayString(_ctx.formatTime(_ctx.videoClipSetup.end)), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", {
                class: "w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.videoPlayerRef.toggleMuteUnmute()))
              }, [
                _createVNode(_component_app_icon, {
                  name: _ctx.videoSettings.muted ? 'mute-white' : 'unmute-white',
                  class: "!h-[14px]"
                }, null, 8, ["name"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.videoPlayerRef.togglePlayPause()))
              }, [
                _createVNode(_component_app_icon, {
                  name: _ctx.videoSettings.paused ? 'play-white' : 'pause-white',
                  class: "!h-[17px]"
                }, null, 8, ["name"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.type == 'video' && _ctx.videoSettings.paused)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "absolute top-0 bottom-0 w-[2px] bg-white z-10 transition-transform duration-300 ease-linear",
                style: _normalizeStyle({ transform: `translateX(${_ctx.progressLinePosition}px)` })
              }, null, 4),
              _createElementVNode("div", {
                ref: "seekable",
                class: "absolute h-full left-0 top-0 bg-transparent border-t-[2px] z-20 border-b-[2px] rounded-[5px] border-secondary-500 cursor-pointer",
                style: _normalizeStyle({
              left: `${_ctx.clipSetup.left}px`,
              width: `${_ctx.clipSetup.width}px`,
            }),
                onMousedown: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.startMove && _ctx.startMove(...args))),
                onTouchstart: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.startMove && _ctx.startMove(...args)))
              }, [
                _createElementVNode("div", {
                  class: "absolute left-0 w-[20px] h-full bg-secondary-main cursor-ew-resize",
                  onMousedown: _cache[2] || (_cache[2] = _withModifiers((event) => _ctx.startResize(event, 'left'), ["stop"])),
                  onTouchstart: _cache[3] || (_cache[3] = _withModifiers((event) => _ctx.startResize(event, 'left'), ["stop"]))
                }, null, 32),
                _createElementVNode("div", {
                  class: "absolute right-0 w-[20px] h-full bg-secondary-main cursor-ew-resize rounded-r-[2px]",
                  onMousedown: _cache[4] || (_cache[4] = _withModifiers((event) => _ctx.startResize(event, 'right'), ["stop"])),
                  onTouchstart: _cache[5] || (_cache[5] = _withModifiers((event) => _ctx.startResize(event, 'right'), ["stop"]))
                }, null, 32)
              ], 36),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoFrames, (item, index) => {
                return (_openBlock(), _createBlock(_component_app_image_loader, {
                  photoUrl: item,
                  key: index,
                  class: _normalizeClass(`h-full ${index == 0 ? 'rounded-l-[6px]' : ''} ${
              index == _ctx.videoFrames.length - 1 ? 'rounded-r-[6px]' : ''
            }`),
                  style: `width: ${100 / 6}%;`
                }, null, 8, ["photoUrl", "class"]))
              }), 128))
            ], 512))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.type == 'video')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_ctx.type == 'video' && _ctx.clippedVideoUrl)
              ? (_openBlock(), _createBlock(_component_app_video_player, {
                  key: 0,
                  videoUrl: _ctx.clippedVideoUrl,
                  "is-paused": _ctx.videoSettings.paused,
                  "onUpdate:isPaused": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.videoSettings.paused) = $event)),
                  "is-muted": _ctx.videoSettings.muted,
                  "onUpdate:isMuted": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.videoSettings.muted) = $event)),
                  "is-full-screen": false,
                  muted: false,
                  ref: "videoPlayerRef",
                  class: "!rounded-[10px]"
                }, null, 8, ["videoUrl", "is-paused", "is-muted"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.type == 'image')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showSettings ? (_ctx.showSettings = false) : (_ctx.showSettings = true))),
          class: "w-full flex flex-col bg-black items-center justify-center overflow-y-hidden cursor-pointer rounded-[10px]",
          style: {"height":"calc(\n          100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 120px\n        )"}
        }, [
          (!_ctx.imageCroppable)
            ? (_openBlock(), _createBlock(_component_app_image_loader, {
                key: 0,
                "custom-class": "w-full h-full rounded-[10px]",
                "photo-url": _ctx.file_url
              }, null, 8, ["photo-url"]))
            : (_openBlock(), _createBlock(_component_app_image_cropper, {
                key: 1,
                image_url: _ctx.file_url,
                modelValue: _ctx.newPromotion.basic.banner.crop_image_blob,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newPromotion.basic.banner.crop_image_blob) = $event))
              }, null, 8, ["image_url", "modelValue"]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.withCta)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_app_button, {
              class: "!bg-white w-full py-3 !rounded-[7px] !text-black !font-semibold",
              onClick: _withModifiers(($event: any) => (null), ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.newPromotion.basic.cta), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.withPolls)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_app_normal_text, { class: "w-full text-center font-semibold text-xl !text-white uppercase pb-1 px-3" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.newPromotion.basic.polls?.question), 1)
              ]),
              _: 1
            }),
            (
              _ctx.newPromotion.basic.polls?.answer_type === 'binary' ||
              _ctx.newPromotion.basic.polls.options.length == 2
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newPromotion.basic.polls?.options, (option, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (
                  _ctx.newPromotion.basic.polls.options.filter(
                    (eachitem) => eachitem.image_url
                  ).length == 0
                )
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "focus:outline-none col-span-1 py-3 bg-white rounded-[6px] flex justify-center items-center",
                            onClick: _withModifiers(($event: any) => (null), ["prevent"])
                          }, [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(option.value), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(_component_app_image_loader, {
                              photoUrl: option.image_url || '',
                              class: "h-[120px] rounded-t-[6px] w-full"
                            }, null, 8, ["photoUrl"]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("span", _hoisted_18, _toDisplayString(option.value), 1)
                            ])
                          ]))
                    ], 64))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (
              _ctx.newPromotion.basic.polls?.answer_type === 'options' &&
              _ctx.newPromotion.basic.polls.options.length > 2
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newPromotion.basic.polls?.options, (option, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (
                  _ctx.newPromotion.basic.polls?.option_type === 'plain' &&
                  _ctx.newPromotion.basic.polls.options.filter(
                    (eachitem) => eachitem.image_url
                  ).length == 0
                )
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "focus:outline-none col-span-1 py-3 bg-white rounded-[6px] flex justify-center items-center",
                            onClick: _withModifiers(($event: any) => (null), ["prevent"])
                          }, [
                            _createElementVNode("span", _hoisted_20, _toDisplayString(option.value), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                            _createVNode(_component_app_image_loader, {
                              photoUrl: option.image_url || '',
                              class: "h-[120px] rounded-t-[6px] w-full"
                            }, null, 8, ["photoUrl"]),
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("span", _hoisted_23, _toDisplayString(option.value), 1)
                            ])
                          ]))
                    ], 64))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "w-full flex flex-row items-center justify-center pt-2" }, [
              _createElementVNode("span", { class: "text-center text-gray-300 !text-[11px]" }, " Vote to see result ")
            ], -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.gettingReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          (_openBlock(), _createElementBlock("svg", _hoisted_25, _cache[13] || (_cache[13] = [
            _createElementVNode("circle", {
              class: "opacity-25",
              cx: "12",
              cy: "12",
              r: "10",
              stroke: "currentColor",
              "stroke-width": "4"
            }, null, -1),
            _createElementVNode("path", {
              class: "opacity-75",
              fill: "currentColor",
              d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            }, null, -1)
          ]))),
          _createVNode(_component_app_normal_text, { class: "!text-center" }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode(" Loading media editor... ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}